import { Checkbox } from "@/components/ui/checkbox";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import type { Activity, ActivityFlowConsents } from "@lib/activity";
import type { ConsentsBlockV2 } from "@lib/config/types";
import { isSyndicated } from "@lib/consents";
import type { Ticket } from "@lib/ticket";
import {
  alignStyle,
  type FontFamily,
  resolveFontFamily,
  textBlockWidth,
} from "@lib/utils";
import type { CheckedState } from "@radix-ui/react-checkbox";
import type { CSSProperties } from "react";
import { useFormContext } from "react-hook-form";
import type { FlowType } from "./blocks";

type ConsentsProps = ConsentsBlockV2 & {
  activity: Activity;
  ticket?: Ticket;
  flowConsents?: ActivityFlowConsents;
  flowType?: FlowType;
};

type ConsentsStyles = CSSProperties & {
  "--anchor-color": string;
  "--checkbox-color": string;
  "--checkbox-border-color": string;
  "--input-background": string;
  "--checkbox-border-width": string;
  "--checkbox-border-radius": string;
};

export function Consents({
  consents,
  activity,
  ticket,
  flowConsents,
  flowType,
}: ConsentsProps) {
  const fontFamily = consents.label.font as FontFamily;

  return (
    <div
      data-block-type="Consents"
      className="[&_a]:text-[var(--anchor-color)] [&_a]:underline"
      style={{
        ...alignStyle(consents),
        maxWidth: textBlockWidth(consents.width),
      }}
    >
      <div
        className="grid gap-8"
        style={
          {
            ...(consents.alignment === "right" && { justifyContent: "end" }),
            fontSize: consents.label.font_size,
            ...resolveFontFamily(fontFamily, consents.label.font_properties),
            color: consents.label.color,
            "--anchor-color": consents.label.hyperlink_color,
            "--checkbox-color": consents.checkbox.color,
            "--checkbox-border-color": consents.checkbox.border.color,
            "--checkbox-border-width": `${consents.checkbox.border.width}px`,
            "--input-background": consents.checkbox.background_color,
            "--checkbox-border-radius": `${consents.checkbox.border.radius}px`,
          } as ConsentsStyles
        }
      >
        {flowConsents &&
          flowConsents.length > 0 &&
          flowConsents
            .filter(
              (consent) =>
                !ticket?.consents?.includes(consent.id) ||
                consent.type !== "MARKETING_OPTIN",
            )
            .map((consent) => <Consent key={consent.id} consent={consent} />)}

        {/** Render the extra syndication consent checkbox if it's the correct feedback blueprint */}
        {isSyndicated(activity.feedbackBlueprint?.id) &&
        flowType === "review" ? (
          <SyndicatorConsent feedbackBlueprint={activity.feedbackBlueprint} />
        ) : null}
      </div>
    </div>
  );
}

type ConsentProps = {
  consent: ActivityFlowConsents[number];
};

function Consent({ consent }: ConsentProps) {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={`consent:${consent.id}`}
      render={({ field }) => (
        <FormItem key={field.name} className="grid gap-3">
          <div className="flex space-x-4">
            <FormControl>
              <Checkbox
                id={field.name}
                name={field.name}
                checked={field.value as CheckedState | undefined}
                onCheckedChange={field.onChange}
                aria-labelledby={`label-${consent.id}`}
              />
            </FormControl>
            <div className="grid gap-2 leading-tight peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              {consent.label && (
                <label
                  htmlFor={`consent:${consent.id}`}
                  id={`label-${consent.id}`}
                  className="flex min-h-[1.5em] items-center font-medium"
                  dangerouslySetInnerHTML={{
                    __html: consent.label ?? "",
                  }}
                />
              )}
              {consent.disclaimer && (
                <div
                  className="text-xs"
                  dangerouslySetInnerHTML={{
                    __html: consent.disclaimer ?? "",
                  }}
                />
              )}
            </div>
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

type SyndicatorConsentProps = {
  feedbackBlueprint: {
    id: string | null;
  } | null;
};

function SyndicatorConsent({ feedbackBlueprint }: SyndicatorConsentProps) {
  const { control } = useFormContext();
  const syndicationInfo = isSyndicated(feedbackBlueprint?.id);
  if (!syndicationInfo) return null;
  const [type, text] = Array.isArray(syndicationInfo)
    ? syndicationInfo
    : [null, null];
  return (
    <FormField
      control={control}
      name={`syndicator-consent:${type}`}
      render={({ field }) => (
        <FormItem key={field.name} className="grid gap-3">
          <div className="flex space-x-4">
            <FormControl>
              <Checkbox
                id={field.name}
                name={field.name}
                checked={field.value as CheckedState | undefined}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <div className="grid gap-2 leading-tight peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              <label
                htmlFor={`syndicator-consent:${type}`}
                className="min-h-[1.5em] font-medium"
                dangerouslySetInnerHTML={{
                  __html: text ?? "",
                }}
              />
            </div>
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
