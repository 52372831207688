import { Button } from "@/components/ui/button";
import type { SubmitButtonBlockV2 } from "@lib/config/types";
import {
  type FontFamily,
  alignStyle,
  boxShadow,
  resolveFontFamilyVariables,
} from "@lib/utils";
import { useFormState } from "react-hook-form";

type SubmitButtonProps = SubmitButtonBlockV2;

export function SubmitButton({ submit_button: submit }: SubmitButtonProps) {
  const { isSubmitting: pending } = useFormState();

  const fontFamily = submit.font as FontFamily;

  return (
    <div
      data-block-type="Submit Button"
      style={{
        ...resolveFontFamilyVariables(
          "--button",
          fontFamily,
          submit.font_properties,
        ),
      }}
    >
      <Button
        type="submit"
        className="px-8 py-0 w-min"
        aria-disabled={pending}
        disabled={pending}
        style={{
          ...alignStyle(submit),
          width: submit.width === "full" ? "100%" : "min-content",
          height: `${submit.height}px`,
          color: submit.color,
          backgroundColor: submit.background,
          borderRadius:
            submit.border.radius === 9999
              ? "9999em"
              : `${submit.border.radius}px`,
          border: `${submit.border.width}px solid ${submit.border.color}`,
          fontSize: submit.font_size,
          fontFamily: "var(--button-font-name)",
          fontWeight: "var(--button-font-weight)",
          letterSpacing: submit.letter_spacing,
          boxShadow: boxShadow(submit.shadow),
        }}
        dangerouslySetInnerHTML={{
          __html: submit.text ?? "",
        }}
      />
    </div>
  );
}
