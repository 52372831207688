import {
  type SyndicatorQuestions,
  type SyndicatorQuestionsV2Block,
} from "@lib/config/types";
import { FormField } from "./form/field";
import { type CSSProperties } from "react";
import {
  alignStyle,
  type FontFamily,
  resolveFontFamilyVariables,
  textBlockWidth,
} from "@lib/utils";
import { syndicatorQsFields } from "@lib/syndicator-questions";

type SyndicatorQuestionsProps = SyndicatorQuestionsV2Block;

type SyndicatorQuestionsStyles = CSSProperties & {
  "--label-font-name": string;
  "--label-font-weight": string;
  "--label-font-style": string;
  "--label-color": string;
  "--label-font-size": string;
  "--input-font-name": string;
  "--input-font-weight": string;
  "--input-font-style": string;
  "--input-color": string;
  "--input-background": string;
  "--input-border-color": string;
  "--placeholder-color": string;
  "--field-border-size": string;
  "--field-border-top-left-radius": string;
  "--field-border-top-right-radius": string;
  "--field-border-bottom-right-radius": string;
  "--field-border-bottom-left-radius": string;
};

export function SyndicatorQuestions({
  syndicator_questions,
}: SyndicatorQuestionsProps) {
  const labelFontFamily = syndicator_questions.label_style.font as FontFamily;
  const inputFontFamily = syndicator_questions.input_style.font as FontFamily;

  const transformedFields = syndicatorQsFields(syndicator_questions.fields);

  const isPill =
    syndicator_questions.input_style.border.radius === 9999.0 ||
    syndicator_questions.input_style.border.radius === 99999.0;

  return (
    <div
      className="grid gap-8"
      style={
        {
          maxWidth: textBlockWidth(syndicator_questions.width),
          ...alignStyle(syndicator_questions),
          ...resolveFontFamilyVariables(
            "--label",
            labelFontFamily,
            syndicator_questions.label_style.font_properties,
          ),
          ...resolveFontFamilyVariables(
            "--input",
            inputFontFamily,
            syndicator_questions.input_style.font_properties,
          ),
          "--label-color": syndicator_questions.label_style.color,
          "--input-color": syndicator_questions.input_style.color,
          "--input-background":
            syndicator_questions.input_style.background_color,
          "--input-border-color": syndicator_questions.input_style.border.color,
          "--placeholder-color":
            syndicator_questions.input_style.placeholder_color,
          "--field-border-size": `${syndicator_questions.input_style.border.width}px`,
          "--field-border-top-left-radius": `${isPill ? "9999em" : `min(${syndicator_questions.input_style.border.radius}%, 1.5625rem)`}`,
          "--field-border-top-right-radius": `${isPill ? "9999em" : `min(${syndicator_questions.input_style.border.radius}%, 1.5625rem)`}`,
          "--field-border-bottom-right-radius": `${isPill ? "9999em" : `min(${syndicator_questions.input_style.border.radius}%, 1.5625rem)`}`,
          "--field-border-bottom-left-radius": `${isPill ? "9999em" : `min(${syndicator_questions.input_style.border.radius}%, 1.5625rem)`}`,
        } as SyndicatorQuestionsStyles
      }
    >
      {transformedFields.map((field) => {
        return <FormField key={field.name} {...field} />;
      })}
    </div>
  );
}
