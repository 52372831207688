import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroupItem } from "@/components/ui/radio-group";
import type { CheckableOption } from "@lib/config/form";
import {
  type MultipleChoiceMultipleSelectionOption,
  type MultipleChoiceOtherOption,
} from "@lib/config/types";
import { useRef, useState, type ChangeEvent } from "react";

type CheckableOtherProps = {
  name: string;
  type: string;
  option: MultipleChoiceOtherOption;
  multipleAnswerSelection?: MultipleChoiceMultipleSelectionOption;
  groupValue?: string | null;
  setGroupValue?: (value: string | null) => void;
  fieldIsDisabled?: (
    option: CheckableOption,
    mutliChoiceConfig: MultipleChoiceMultipleSelectionOption,
    checkedOptions: string[],
  ) => boolean;
  checkedOptions?: string[];
  setCheckedOptions?: (value: string[]) => void;
};

function buildOtherOption(
  name: string,
  option: MultipleChoiceOtherOption,
  value: string,
) {
  if (option === "exclude") return null;
  return {
    id: option.include.internal_id,
    name: name,
    label: option.include.label,
    value,
  } as CheckableOption;
}

function isChecked(
  otherValue: string,
  groupValue: null | string,
  type: string,
) {
  if (type === "radio") {
    return otherValue === groupValue ? true : undefined;
  }
}

function textInputWrapperClasses(
  otherValue: string,
  type: string,
  groupValue: string | null | undefined,
  checkedOptions: Array<string> | undefined,
) {
  switch (type) {
    case "radio":
      return otherValue !== groupValue ? "hidden" : "block";
    case "checkbox":
      return checkedOptions?.includes(otherValue) ? "block" : "hidden";
    default:
      return "hidden";
  }
}

export function CheckableOther(props: CheckableOtherProps) {
  const [otherValue, setOtherValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  if (props.option === "exclude" || !props.option) return;

  const field = buildOtherOption(props.name, props.option, otherValue);
  if (!field) return;

  function handleTextInputChange(event: ChangeEvent<HTMLInputElement>) {
    if (props.type === "radio") {
      props.setGroupValue?.(event.target.value);
    } else if (props.type === "checkbox") {
      const newCheckedOptions =
        props.checkedOptions?.map((option) =>
          option === otherValue ? event.target.value : option,
        ) || [];
      props.setCheckedOptions?.(newCheckedOptions);
    }
    setOtherValue(event.target.value);
  }

  function otherFieldCheckableInput(
    type: string,
    value: string,
    field: CheckableOption,
  ) {
    switch (type) {
      case "radio":
        return (
          <RadioGroupItem
            {...field}
            value={value}
            checked={isChecked(
              otherValue,
              props.groupValue ?? null,
              props.type,
            )}
          />
        );
      case "checkbox":
        return (
          <Checkbox
            {...field}
            value={value}
            disabled={
              props.fieldIsDisabled?.(
                field,
                props.multipleAnswerSelection as MultipleChoiceMultipleSelectionOption,
                props.checkedOptions as Array<string>,
              ) || false
            }
          />
        );
      default:
        return;
    }
  }

  return (
    <>
      <div
        className="gap-[0.75em] px-[0.75em] py-[0.5em]"
        style={{
          color: "var(--input-color)",
          backgroundColor: "var(--input-background)",
          borderTopLeftRadius: "var(--field-border-top-left-radius)",
          borderTopRightRadius: "var(--field-border-top-right-radius)",
          borderBottomRightRadius: "var(--field-border-bottom-right-radius)",
          borderBottomLeftRadius: "var(--field-border-bottom-left-radius)",
          borderColor: "var(--input-border-color)",
          borderWidth: "var(--field-border-size)",
          boxShadow:
            props.groupValue === otherValue
              ? "0 0 0 2px var(--input-border-color, var(--black))"
              : props.checkedOptions?.includes(otherValue)
                ? "0 0 0 2px var(--input-border-color, var(--black))"
                : "",
          filter: props?.fieldIsDisabled?.(
            field,
            props.multipleAnswerSelection as MultipleChoiceMultipleSelectionOption,
            props.checkedOptions as Array<string>,
          )
            ? "grayscale(1)"
            : "grayscale(0)",
          opacity: props?.fieldIsDisabled?.(
            field,
            props.multipleAnswerSelection as MultipleChoiceMultipleSelectionOption,
            props.checkedOptions as Array<string>,
          )
            ? ".35"
            : "1",
        }}
      >
        <div className="grid grid-cols-[auto_1fr] items-center gap-[0.75em]">
          {otherFieldCheckableInput(props.type, otherValue, field)}
          <Label
            className="grid items-center"
            htmlFor={field.id}
            style={{
              fontFamily: "var(--label-font-name, var(--font-family))",
              fontStyle: "var(--label-font-style, var(--font-style))",
              fontWeight: "var(--label-font-weight, var(--font-weight))",
              color: "var(--label-color)",
              minBlockSize: "calc(var(--input-block-size) - 1em)",
            }}
            onClick={() => {
              inputRef.current?.focus({ focusVisible: true } as FocusOptions);
            }}
          >
            {field.label}
          </Label>
        </div>
        <div
          className={textInputWrapperClasses(
            otherValue,
            props.type,
            props.groupValue,
            props.checkedOptions,
          )}
        >
          <Input
            ref={inputRef}
            className="h-auto px-[0.5em] py-[0.25em]"
            onChange={handleTextInputChange}
            value={otherValue}
          />
        </div>
      </div>
    </>
  );
}
