import { cn } from "@lib/utils";
import { useFormContext } from "react-hook-form";

type FormErrorProps = {
  name: string;
  className?: string;
  id?: string;
};

export function FormError({ id, name, className }: FormErrorProps) {
  const {
    formState: { errors },
  } = useFormContext();
  const error = errors[name]?.message as string;

  if (!error) return null;

  return (
    <p
      {...(id && { id: id })}
      className={cn("mt-2 text-red-500", className)}
      aria-live="polite"
    >
      {error}
    </p>
  );
}
