import type { TitleBlock } from "@lib/config/types";
import {
  color,
  font,
  fontStyle,
  fontWeight,
  justify,
  textBlockWidth,
} from "@lib/utils";

type TitleProps = TitleBlock;

export function Title({ title }: TitleProps) {
  return (
    <div
      data-block-type="Title"
      className="flex"
      style={{
        justifyContent: justify(title),
        width: textBlockWidth(title.width),
      }}
    >
      <div
        style={{
          color: color(title.typography.colour),
          textAlign: title.alignment,
          fontFamily: font(title.typography.font),
          fontWeight: fontWeight(title.typography.font_properties),
          fontStyle: fontStyle(title.typography.font_properties),
          fontSize: title.typography.size,
          letterSpacing: title.typography.letter_spacing,
        }}
        dangerouslySetInnerHTML={{
          __html: title.text ?? "",
        }}
      />
    </div>
  );
}
