import type { TextBlockV2 } from "@lib/config/types";
import {
  alignStyle,
  type FontFamily,
  resolveFontFamilyVariables,
  textBlockWidth,
} from "@lib/utils";
import type { CSSProperties } from "react";

type TextProps = TextBlockV2;

type TextStyles = CSSProperties & {
  "--anchor-color": string;
};

export function Text({ text }: TextProps) {
  const fontFamily = text.font as FontFamily;
  return (
    <div
      data-block-type="Text"
      className="mx-auto [&_a]:text-[var(--anchor-color)]"
      style={
        {
          maxWidth: textBlockWidth(text.width),
          ...alignStyle(text),
          "--anchor-color": text.hyperlink_color,
          ...resolveFontFamilyVariables(
            "--text",
            fontFamily,
            text.font_properties,
          ),
        } as TextStyles
      }
    >
      <div
        className="font-[family-name:--text-font-name] font-[--text-font-weight] space-y-4"
        style={{
          color: text.color,
          textAlign: text.alignment,
          fontSize: text.font_size,
          letterSpacing: text.letter_spacing,
          lineHeight: `${text.line_height}px`,
          fontStyle: "var(--text-font-style)",
        }}
        dangerouslySetInnerHTML={{
          __html: text.copy ?? "",
        }}
      />
    </div>
  );
}
