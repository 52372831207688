import type { SeparatorBlock } from "@lib/config/types";
import { color, textBlockWidth } from "@lib/utils";

type SeparatorProps = SeparatorBlock;

export const Separator = ({ separator }: SeparatorProps) => {
  return (
    <div
      data-block-type="Separator"
      className="mx-auto"
      style={{
        width: textBlockWidth(separator.width),
        height: `${separator.height}px`,
        backgroundColor: color(separator.colour),
      }}
    />
  );
};
