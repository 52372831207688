import type { PointerBlock } from "@lib/config/types";
import {
  color,
  font,
  fontStyle,
  fontWeight,
  justify,
  textBlockWidth,
} from "@lib/utils";
import { type CSSProperties } from "react";

type PointerProps = PointerBlock;

export const Pointer = ({ pointer }: PointerProps) => {
  return (
    <div
      data-block-type="Pointer"
      className="flex"
      style={{
        justifyContent: justify(pointer),
        width: textBlockWidth(pointer.width),
      }}
    >
      <div className="flex flex-col items-center gap-4">
        <div
          style={{
            fontFamily: font(pointer.typography.font),
            color: color(pointer.typography.colour),
            fontSize: pointer.typography.size,
            fontStyle: fontStyle(pointer.typography.font_properties),
            fontWeight: fontWeight(pointer.typography.font_properties),
            letterSpacing: pointer.typography.letter_spacing,
            textAlign: pointer.alignment,
          }}
          dangerouslySetInnerHTML={{
            __html: pointer.text ?? "",
          }}
        />
        <Chevron
          className="w-6"
          style={{
            fill: color(pointer.pointer_colour),
          }}
        />
      </div>
    </div>
  );
};

function Chevron(props: { className?: string; style: CSSProperties }) {
  return (
    <svg
      width="34"
      height="14"
      viewBox="0 0 34 14"
      role="img"
      aria-label="Down arrow icon"
      {...props}
    >
      <path
        d="M529,1079 L529,1082.41197 L512.077,1092.93 L512.033584,1093 L511.999,1092.979 L511.966416,1093 L511.924,1092.932 L495,1082.41197 L495,1079 L512,1089.492 L529,1079 Z"
        transform="translate(-495 -1079)"
      />
    </svg>
  );
}
