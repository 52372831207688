import { isObject } from "@lib/utils";
import type {
  ReviewPageField,
  SQValueKeys,
  SyndicatorQuestions,
  SyndicatorQuestionsV2Block,
} from "@lib/config/types";
import type { FormFieldWithoutCheckableAndOther } from "@lib/config/form";

export function resolveInputType(fieldType: SQValueKeys) {
  switch (fieldType) {
    case "star_rating":
      return "star_rating";
    case "review":
      return "textarea";
    case "review_title":
      return "input";
    case "nickname":
      return "input";
  }
}

type ReviewPageReviewField = Omit<ReviewPageField["values"], "key"> & {
  input_type: {
    text_area: {
      min_characters: number;
    };
  };
};

export function omitProperty<T extends Record<string, unknown>>(
  obj: T,
  keyToOmit: keyof T,
): Omit<T, typeof keyToOmit> {
  const { [keyToOmit]: _, ...rest } = obj;
  return rest;
}

export function syndicatorQsFields(
  syndicator_questions_fields: SyndicatorQuestions,
) {
  {
    return syndicator_questions_fields.map((field) => {
      const [fieldType] = Object.keys(field) as [SQValueKeys];

      const fieldProps = field[fieldType as keyof typeof field];

      const restFieldProps = isObject(fieldProps)
        ? omitProperty(fieldProps, "key")
        : {};

      const minCharacters = (restFieldProps as ReviewPageReviewField)
        ?.input_type?.text_area?.min_characters;

      return {
        ...(isObject(restFieldProps) ? restFieldProps : {}),
        name: `syndicator-question:${fieldType}`,
        id: `syndicator-question:${fieldType}`,
        fieldType: resolveInputType(fieldType),
        ...(minCharacters !== undefined && { minCharacters: minCharacters }),
      } as FormFieldWithoutCheckableAndOther;
    });
  }
}

export function extractSyndicatorQuestions(
  syndicatorQuestions: SyndicatorQuestionsV2Block,
) {
  return syndicatorQuestions.syndicator_questions.fields ?? [];
}
