import type {
  MultipleChoice,
  MultipleChoiceOtherOption,
  MultipleChoiceMultipleSelectionOption,
  MultipleChoiceAnswer,
} from "@lib/config/types";
import type { FormField, CheckableOption } from "@lib/config/form";
import { FormField as FormFieldComponent } from "@/components/blocks/form/field";
import type { FlowType } from "../blocks";

type MultipleChoiceProps = MultipleChoice & {
  flowType?: FlowType;
};

function checkableFieldType(
  multipleChoiceOption: MultipleChoiceMultipleSelectionOption,
) {
  return multipleChoiceOption === "disable_multiple_choice_selection"
    ? "radio"
    : "checkbox";
}

function otherField(otherFieldOption: MultipleChoiceOtherOption) {
  return otherFieldOption === "exclude" ? null : otherFieldOption;
}

type OptionValue = "answer" | "internal_id";

function buildCheckableOption(
  option: MultipleChoiceAnswer,
  optionValue: OptionValue = "answer",
) {
  return {
    id: option.internal_id,
    name: option.internal_id,
    label: option.answer,
    value: option[optionValue],
  } as CheckableOption;
}

export function MultipleChoice(props: MultipleChoiceProps) {
  const fieldProps: FormField = {
    name: `custom-question:${props.question.internal_id}`,
    fieldType: checkableFieldType(props.enable_multiple_answer_selection),
    legend: props.question.question_title,
    prompt: props.question.prompt,
    type: checkableFieldType(props.enable_multiple_answer_selection),
    options: props.answers.map((answer) =>
      buildCheckableOption(
        answer,
        props.flowType === "review" ? "internal_id" : "answer",
      ),
    ),
    layout: props.desktop_layout,
    other: otherField(props.other_option),
    multipleAnswerSelection: props.enable_multiple_answer_selection,
  };

  return <FormFieldComponent {...fieldProps} />;
}
