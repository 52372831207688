import * as React from "react";

import { cn } from "@lib/utils";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, style, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 font-[family-name:--field-font-name] text-sm font-[--field-font-weight] ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        style={{
          color: "var(--input-color)",
          backgroundColor: "var(--input-background)",
          borderTopLeftRadius:
            "var(--field-border-top-left-radius, var(--input-radius))",
          borderTopRightRadius:
            "var(--field-border-top-right-radius, var(--input-radius))",
          borderBottomRightRadius:
            "var(--field-border-bottom-right-radius, var(--input-radius))",
          borderBottomLeftRadius:
            "var(--field-border-bottom-left-radius, var(--input-radius))",
          border: "var(--field-border-size) solid var(--input-border-color)",
          ...style,
        }}
        ref={ref}
        {...props}
      />
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
