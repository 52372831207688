import type { Activity } from "@lib/activity";
import { useCookies } from "react-cookie";

type MetaProps = {
  activity: Activity;
  code: string;
};

export function Meta({ activity, code }: MetaProps) {
  const [cookies] = useCookies([`sopost-purpose_${activity.id}`]);
  const cookie = cookies[`sopost-purpose_${activity.id}`] as
    | { value: string }
    | undefined;

  if (!cookie || cookie.value === "rejected") return null;

  return (
    <>
      <script
        type="text/partytown"
        dangerouslySetInnerHTML={{
          __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${code}');
          fbq('track', 'PageView');
          `,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=723822129264340&ev=PageView&noscript=1"
          alt=""
        />
      </noscript>
    </>
  );
}
