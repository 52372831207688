"use client";

import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@lib/utils";

const labelVariants = cva(
  "text-md font-medium leading-[normal] peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-[var(--label-color)] font-[var(--label-font-name)] weight-[var(--label-font-weight)]",
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(labelVariants(), className)}
    style={{ fontStyle: "var(--label-font-style)" }}
    {...props}
  />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
