import type {
  StarRating,
  FormFieldOption,
  LandingPageField,
  MultipleChoiceLayoutOption,
  MultipleChoiceMultipleSelectionOption,
  MultipleChoiceOtherOption,
  NameInputField,
  SelectField,
  TextInputField,
} from "./types";
import { type Ticket } from "../ticket";

type ConfigFormInputField = LandingPageField & {
  values: {
    input_type: {
      text_input: TextInputField;
    };
  };
};

type ConfigFormSelectField = LandingPageField & {
  values: {
    input_type: {
      select: SelectField;
    };
  };
};

type ConfigFormTextareaField = LandingPageField & {
  values: {
    input_type: {
      text_area: {
        max_characters: number;
      };
    };
  };
};

export type FormField =
  | (FormInputField & {
      fieldType: "input";
    })
  | (OtherInputField & {
      fieldType: "select";
    })
  | (FormTextareaField & {
      fieldType: "textarea";
    })
  | (FormStarRatingField & {
      fieldType: "star_rating";
    })
  | (CheckableInputField & {
      fieldType: "radio" | "checkbox";
      multipleAnswerSelection: MultipleChoiceMultipleSelectionOption;
    });

export type FormInputField = {
  id: string;
  name: string;
  type: string;
  label: string;
  placeholder?: string;
  prompt?: string;
  defaultValue?: string;
  min?: number;
  max?: number;
  readOnly?: boolean;
};

// Placeholder before implementing select and text area
export type OtherInputField = {
  id: string;
  name: string;
  label: string;
  placeholder: string;
};

export type FormTextareaField = {
  id: string;
  name: string;
  label: string;
  placeholder: string;
  maxCharacters: number;
  minCharacters?: number;
};

export type CheckableInputField = {
  name: string;
  legend: string;
  prompt?: string;
  type: string;
  options: CheckableOption[];
  layout: MultipleChoiceLayoutOption;
  other: null | MultipleChoiceOtherOption;
  multipleAnswerSelection?: MultipleChoiceMultipleSelectionOption;
};

export type CheckableOption = {
  id: string;
  name: string;
  label: string;
  value: string;
};

export type FormStarRatingField = StarRating & { name?: string };

type ExcludedCheckable = CheckableInputField & {
  fieldType: "radio" | "checkbox";
  multipleAnswerSelection: MultipleChoiceMultipleSelectionOption;
};

type ExcludedOther = OtherInputField & { fieldType: "select" };

export type FormFieldWithoutCheckableAndOther = Exclude<
  FormField,
  ExcludedCheckable | ExcludedOther
>;

export function fieldDTO(
  field: LandingPageField,
  ticket?: Ticket,
): FormField | FormField[] {
  const [fieldType] = Object.keys(field.values.input_type) as FormFieldOption[];

  switch (fieldType) {
    case "text_input":
      const input = field as ConfigFormInputField;
      if (
        "split_first_name_last_name" in field.values &&
        field.values.split_first_name_last_name
      )
        return [
          getNameInputProps(input.values as NameInputField, "first"),
          getNameInputProps(input.values as NameInputField, "last"),
        ];

      const type = input.values.input_type.text_input.html_input_type;

      const inputProps = {
        id: input.values.key,
        name: input.values.key,
        label: input.values.label,
        placeholder: input.values.placeholder,
        fieldType: "input" as const,
      };

      if (typeof type === "object" && "number" in type) {
        return {
          ...inputProps,
          type: "number",
          min: type.number.min,
          max: type.number.max,
        };
      }

      const ticketValue =
        input.type === "name"
          ? "fullName"
          : input.type === "phone"
            ? "telephone"
            : input.type === "email"
              ? "email"
              : input.type === "address_line_1"
                ? "line_1"
                : input.type === "postcode"
                  ? "postcode"
                  : null;
      const defaultValue =
        ticket && "address" in ticket && ticketValue
          ? ticket.address?.[ticketValue]
          : "";
      const readOnly =
        (ticket &&
          ticketValue &&
          ticket.address?.readOnly.includes(ticketValue)) ||
        false;

      return {
        ...inputProps,
        type: input.values.input_type.text_input.html_input_type as string,
        defaultValue,
        readOnly,
      };
    case "select":
      const select = field as ConfigFormSelectField;
      const ticketValueSelect =
        select.type === "town"
          ? "town"
          : select.type === "district"
            ? "district"
            : null;
      const defaultValueSelect =
        ticket && "address" in ticket && ticketValueSelect
          ? ticket.address?.[ticketValueSelect]
          : "";
      const readOnlySelect =
        (ticket &&
          ticketValueSelect &&
          ticket.address?.readOnly.includes(ticketValueSelect)) ||
        false;

      if (readOnlySelect) {
        return {
          id: select.values.key,
          name: select.values.key,
          label: select.values.label,
          placeholder: select.values.placeholder,
          type: "text",
          fieldType: "input" as const,
          defaultValue: defaultValueSelect,
          readOnly: readOnlySelect,
        };
      }

      if (select.values.input_type.select.select_type === "postcode_dropdown") {
        return {
          id: select.values.key,
          name: select.values.key,
          label: select.values.label,
          placeholder: select.values.placeholder,
          type: "text",
          fieldType: "input" as const,
        };
      }

      return {
        id: field.values.key,
        name: field.values.key,
        label: field.values.label,
        placeholder: field.values.placeholder,
        fieldType: "select" as const,
      };
    case "text_area":
      const textarea = field as ConfigFormTextareaField;
      return {
        id: textarea.values.key,
        name: textarea.values.key,
        label: textarea.values.label,
        placeholder: textarea.values.placeholder,
        fieldType: "textarea" as const,
        maxCharacters: textarea.values.input_type.text_area.max_characters,
      };
    default:
      throw new Error(`Unknown field type: ${fieldType as string}`);
  }
}

function getNameInputProps(values: NameInputField, section: "first" | "last") {
  const inputProps = {
    id: values[`key_${section}_name`],
    name: values[`key_${section}_name`],
    type: "text",
    label: values[`label_${section}_name`],
    placeholder: values[`placeholder_${section}_name`],
    fieldType: "input" as const,
  };

  return inputProps;
}
