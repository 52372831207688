import { FormField } from "@/components/blocks/form/field";
import { extractBlocks, type FormBlock } from "@lib/config/types";
import { fieldDTO } from "@lib/config/form";
import type { Ticket } from "@lib/ticket";
import {
  color,
  fieldBorderRadius,
  font,
  fontStyle,
  fontWeight,
  hsla,
  justify,
  textBlockWidth,
} from "@lib/utils";
import type { CSSProperties } from "react";

type FormProps = FormBlock & {
  ticket?: Ticket;
};

type FormStyles = CSSProperties & {
  "--input-color": string;
  "--input-background": string;
  "--input-border-color": string;
  "--label-color": string;
  "--territory-label-color": string;
  "--placeholder-color": string;
  "--field-border-size": string;
  "--field-border-top-left-radius": string;
  "--field-border-top-right-radius": string;
  "--field-border-bottom-right-radius": string;
  "--field-border-bottom-left-radius": string;
};

export function Form({ form, fields: formFields, ticket }: FormProps) {
  const fields = extractBlocks(formFields);
  const transformedFields = fields
    .map((field) => fieldDTO(field, ticket))
    .flat();

  return (
    <section
      data-block-type="Form"
      className="flex w-full flex-col gap-7"
      style={
        {
          justifyContent: justify(form),
          maxWidth: textBlockWidth(form.width),
          fontFamily: font(form.typography.font),
          fontStyle: fontStyle(form.typography.font_properties),
          fontWeight: fontWeight(form.typography.font_properties),
          "--input-color": color(form.colours.input_font_colour),
          "--input-background": hsla(form.colours.input_background_colour),
          "--input-border-color": color(form.colours.input_border_colour),
          "--label-color": color(form.colours.label_colour),
          "--territory-label-color": color(form.colours.territory_label_colour),
          "--placeholder-color": color(form.colours.placeholder_colour),
          "--field-border-size": `${form.field_border_size}px`,
          "--field-border-top-left-radius": fieldBorderRadius(
            form.field_corner_radius,
            "tl",
          ),
          "--field-border-top-right-radius": fieldBorderRadius(
            form.field_corner_radius,
            "tr",
          ),
          "--field-border-bottom-right-radius": fieldBorderRadius(
            form.field_corner_radius,
            "br",
          ),
          "--field-border-bottom-left-radius": fieldBorderRadius(
            form.field_corner_radius,
            "bl",
          ),
        } as FormStyles
      }
    >
      {transformedFields.map((field) => (
        <FormField key={field.name} {...field} />
      ))}
    </section>
  );
}
