"use client";

import { Card } from "@/components/ui/card";
import type { Activity } from "@lib/activity";
import { resolvePage, type LandingPageChoice } from "@lib/landing";
import { usePreviewListeners, type ConfigPreview } from "@lib/listeners";
import { useState } from "react";
import { Blocks } from "../blocks/blocks";
import { HighlightContainer } from "../preview/highlight";
import { resolveReviewPage } from "@lib/review";
import {
  type Block,
  type GenericReview,
  type GenericLandingPage,
} from "@lib/config/types";

type ErrorPreviewProps = {
  activity: Activity;
  page: LandingPageChoice;
  type: string;
};

export function ErrorPreview(props: ErrorPreviewProps) {
  const [state, setState] = useState<ConfigPreview | null>(null);

  usePreviewListeners({
    onUpdate: (data) => {
      setState(data);
    },
  });

  if (!state) return null;

  const blocks: Block[] =
    props.type === "claim"
      ? resolvePage(state.config as GenericLandingPage, props.page)
      : resolveReviewPage(
          state.config as GenericReview,
          props.page as string,
        ) || [];

  return (
    <main className="flex flex-col items-center">
      <Card className="mt-40">
        <HighlightContainer>
          {Blocks({ blocks, ...props, ...state })}
        </HighlightContainer>
      </Card>
    </main>
  );
}
