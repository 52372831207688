"use client";

import { Button } from "@/components/ui/button";
import type { CallToActionBlockV2 } from "@lib/config/types";
import {
  type FontFamily,
  alignStyle,
  boxShadow,
  resolveFontFamilyVariables,
} from "@lib/utils";

type CallToActionProps = CallToActionBlockV2;

export function CallToAction({ call_to_action: cta }: CallToActionProps) {
  const fontFamily = cta.font as FontFamily;

  return (
    <div
      data-block-type="Call to action"
      style={{
        ...resolveFontFamilyVariables(
          "--button",
          fontFamily,
          cta.font_properties,
          cta.font_weight,
        ),
      }}
    >
      <Button
        asChild
        className="px-8"
        style={{
          ...alignStyle(cta),
          width: cta.width === "full" ? "100%" : "min-content",
          height: cta.height,
          color: cta.color,
          backgroundColor: cta.background_color,
          borderRadius:
            cta.border.radius === 9999 ? "9999em" : `${cta.border.radius}px`,
          border: `${cta.border.width}px solid ${cta.border.color}`,
          fontFamily: "var(--button-font-name)",
          fontWeight: "var(--button-font-weight)",
          letterSpacing: cta.letter_spacing,
          boxShadow: boxShadow(cta.shadow),
        }}
      >
        <a href={cta.link.url} className="no-underline">
          {cta.link.text}
        </a>
      </Button>
    </div>
  );
}
