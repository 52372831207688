import { useTranslations } from "@/components/context/translations";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import type { FormTextareaField } from "@lib/config/form";
import { cn } from "@lib/utils";
import { useFormContext } from "react-hook-form";

type FormInputProps = FormTextareaField;

export function FormTextarea({
  maxCharacters,
  minCharacters,
  ...props
}: FormInputProps) {
  const { control } = useFormContext();
  const t = useTranslations();

  return (
    <FormField
      control={control}
      name={props.name}
      render={({ field }) => {
        const value = (field.value as string) || "";
        const exceedsMax = value.length > maxCharacters;
        const belowMin = minCharacters && value.length < minCharacters;

        return (
          <FormItem>
            <FormLabel
              style={{
                fontSize: "var(--label-font-size)",
                fontFamily: "var(--label-font-name, var(--font-family))",
                fontStyle: "var(--label-font-style, var(--font-style))",
                fontWeight: "var(--label-font-weight, var(--font-weight))",
                color: "var(--label-color)",
              }}
              dangerouslySetInnerHTML={{
                __html: props.label ?? "",
              }}
              htmlFor={props.id}
            />
            <FormControl>
              <Textarea
                {...field}
                {...props}
                {...(minCharacters && { minLength: minCharacters })}
                className="px-[1em] py-[0.5em] shadow-none"
                rows={5}
                style={{
                  minBlockSize: "var(--input-block-size)",
                  fontFamily: "var(--input-font-name, var(--font-family))",
                  fontStyle: "var(--input-font-style, var(--font-style))",
                  fontWeight: "var(--input-font-weight, var(--font-weight))",
                  color: "var(--input-color)",
                }}
              />
            </FormControl>
            <FormDescription
              className={cn("text-sm", exceedsMax && "!text-destructive")}
              style={{
                fontFamily: "var(--label-font-name, var(--font-family))",
                fontStyle: "var(--label-font-style, var(--font-style))",
                fontWeight: "var(--label-font-weight, var(--font-weight))",
                color: "var(--label-color)",
              }}
            >
              {maxCharacters
                ? exceedsMax
                  ? `${value.length - maxCharacters} ${t("characters too many")}`
                  : `${maxCharacters - value.length} ${t("characters remaining")}`
                : null}

              {minCharacters && belowMin
                ? `${minCharacters - value.length} ${t("more characters required")}`
                : null}
            </FormDescription>
            <FormMessage />
          </FormItem>
        );
      }}
    ></FormField>
  );
}
