import type { Activity, ActivityFlowConsents } from "@lib/activity";
import {
  applyStageBranding,
  resolvePage,
  type LandingPageChoice,
} from "@lib/landing";
import { usePreviewListeners, type ConfigPreview } from "@lib/listeners";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Blocks } from "../blocks/blocks";
import { PreviewFonts } from "../preview/fonts";
import { HighlightContainer } from "../preview/highlight";
import { resolveReviewPage } from "@lib/review";
import {
  type GenericLandingPage,
  type GenericReview,
  type Block,
} from "@lib/config/types";

type PreviewProps = {
  activity: Activity;
  page: LandingPageChoice | number;
  type: string;
  flowConsents: ActivityFlowConsents;
};

export function Preview(props: PreviewProps) {
  const [state, setState] = useState<ConfigPreview | null>(null);
  const methods = useForm();
  const onSubmit = (_data: unknown) => null;

  usePreviewListeners({
    onUpdate: (data) => {
      setState(data);
    },
  });
  if (!state) return null;

  const blocks: Block[] =
    props.type === "review"
      ? resolveReviewPage(
          state.config as GenericReview,
          props.page as string,
          props.activity,
        ) || []
      : resolvePage(
          state.config as GenericLandingPage,
          props.page as LandingPageChoice,
        );

  return (
    <main
      className="min-h-screen"
      style={applyStageBranding(state.config.branding)}
    >
      <PreviewFonts {...state} />
      <FormProvider {...methods}>
        <form
          className="min-h-screen grid justify-items-center items-start content-start"
          onSubmit={(e) => void methods.handleSubmit(onSubmit)(e)}
        >
          <HighlightContainer>
            {Blocks({
              blocks,
              ...props,
              ...state,
              ...(props.type === "review" && { flowType: "review" }),
            })}
          </HighlightContainer>
        </form>
      </FormProvider>
    </main>
  );
}
