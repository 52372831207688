import type { Activity } from "@lib/activity";
import { useCookies } from "react-cookie";

type SnapProps = {
  activity: Activity;
  code: string;
};

export function Snap({ activity, code }: SnapProps) {
  const [cookies] = useCookies([`sopost-purpose_${activity.id}`]);
  const cookie = cookies[`sopost-purpose_${activity.id}`] as
    | { value: string }
    | undefined;

  if (!cookie || cookie.value === "rejected") return null;

  return (
    <script
      type="text/partytown"
      dangerouslySetInnerHTML={{
        __html: `
        (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
        {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
        a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
        r.src=n;var u=t.getElementsByTagName(s)[0];
        u.parentNode.insertBefore(r,u);})(window,document,
        'https://sc-static.net/scevent.min.js');

        snaptr('init', '${code}', {
        'user_email': '__INSERT_USER_EMAIL__'
        });

        snaptr('track', 'PAGE_VIEW');
    `,
      }}
    />
  );
}
