"use client";

import { createContext, useContext } from "react";

// The Style context is required for components that use a Portal such as the Combobox or Select component.
// This is because we use scoped css variables in most places but when you use a portal
// it gets placed as a child of the body tag so is no longer scoped to the parent component.

const StyleContext = createContext<React.CSSProperties | undefined>(undefined);

type StyleProviderProps = {
  style: React.CSSProperties;
  children: React.ReactNode;
};

export function StyleProvider({ style, children }: StyleProviderProps) {
  return (
    <StyleContext.Provider value={style}>{children}</StyleContext.Provider>
  );
}

export function useStyle() {
  const style = useContext(StyleContext);
  if (!style) {
    throw new Error("useStyle must be used within a StyleProvider");
  }
  return style;
}
