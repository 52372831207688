import type { Question } from "@lib/config/types";
import { DateInput } from "./date-input";
import { MultipleChoice } from "./multiple-choice";
import { ShortAnswer } from "./short-answer";
import { StarRating } from "./star-rating";
import type { FlowType } from "../blocks";

type QuestionProps = Question & {
  flowType?: FlowType;
};

export function Question(props: QuestionProps) {
  return questionFactory(props, props.flowType);
}

function questionFactory(question: Question, flowType?: FlowType) {
  switch (question.type) {
    case "short_answer":
      return (
        <ShortAnswer
          key={`${question.type} - ${question.values.internal_id}`}
          {...question.values}
        />
      );
    case "multiple_choice":
      return (
        <MultipleChoice
          key={`${question.type} - ${question.values.question.internal_id}`}
          flowType={flowType}
          {...question.values}
        />
      );
    case "star_rating":
      return (
        <StarRating
          key={`${question.type} - ${question.values.internal_id}`}
          {...question.values}
        />
      );
    case "date_input":
      return (
        <DateInput
          key={`${question.type} - ${question.values.internal_id}`}
          {...question.values}
        />
      );
    default:
      throw new Error("Unknown question type");
  }
}
