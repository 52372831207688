import type { TextBlock } from "@lib/config/types";
import {
  color,
  font,
  fontStyle,
  fontWeight,
  hsla,
  justify,
  textBlockWidth,
} from "@lib/utils";
import type { CSSProperties } from "react";

type TextProps = TextBlock;

type TextStyles = CSSProperties & {
  "--anchor-color": string;
};

export function Text({ text }: TextProps) {
  return (
    <div
      data-block-type="Text"
      className="flex [&_a]:text-[var(--anchor-color)] [&_p]:mb-4"
      style={
        {
          justifyContent: justify(text),
          width: textBlockWidth(text.width),
          "--anchor-color":
            text.typography.hyperlink_colour === "global_hyperlink_colour"
              ? "var(--anchor-colour)"
              : hsla(text.typography.hyperlink_colour.colour),
        } as TextStyles
      }
    >
      <div
        style={{
          color: color(text.typography.colour),
          textAlign: text.alignment,
          fontFamily: font(text.typography.font),
          fontWeight: fontWeight(text.typography.font_properties),
          fontStyle: fontStyle(text.typography.font_properties),
          fontSize: text.typography.size,
          letterSpacing: text.typography.letter_spacing,
          lineHeight: `${text.typography.line_height}px`,
        }}
        dangerouslySetInnerHTML={{
          __html: text.copy ?? "",
        }}
      />
    </div>
  );
}
