export const abuDhabiAreas = [
  {
    value: "Abu Dhabi Golf Club",
    translation: {
      en: "Abu Dhabi Golf Club",
      ar: "نادي أبوظبي للجولف",
    },
  },
  {
    value: "Abu Dhabi International Airport",
    translation: {
      en: "Abu Dhabi International Airport",
      ar: "مطار أبو ظبي الدولي",
    },
  },
  {
    value: "Abu Dhabi Mall",
    translation: {
      en: "Abu Dhabi Mall",
      ar: "مول أبو ظبي",
    },
  },
  {
    value: "Abu Dhabi University",
    translation: {
      en: "Abu Dhabi University",
      ar: "جامعة أبو ظبي",
    },
  },
  {
    value: "Airport Termial 2",
    translation: {
      en: "Airport Termial 2",
      ar: "صالة المطار 2",
    },
  },
  {
    value: "Airport Termial 3",
    translation: {
      en: "Airport Termial 3",
      ar: "صالة المطار 3",
    },
  },
  {
    value: "Ajban Farms",
    translation: {
      en: "Ajban Farms",
      ar: "مزارع العجبان",
    },
  },
  {
    value: "Al Adlah",
    translation: {
      en: "Al Adlah",
      ar: "الدالة",
    },
  },
  {
    value: "Al Ajban",
    translation: {
      en: "Al Ajban",
      ar: "العجبان",
    },
  },
  {
    value: "Al Ameriya",
    translation: {
      en: "Al Ameriya",
      ar: "العامرية",
    },
  },
  {
    value: "Al Asheesh",
    translation: {
      en: "Al Asheesh",
      ar: "العشيش",
    },
  },
  {
    value: "Al Bahiya",
    translation: {
      en: "Al Bahiya",
      ar: "الباهية",
    },
  },
  {
    value: "Al Bandar",
    translation: {
      en: "Al Bandar",
      ar: "البندر",
    },
  },
  {
    value: "Al Bateen",
    translation: {
      en: "Al Bateen",
      ar: "البطين",
    },
  },
  {
    value: "Al Bateen Beach",
    translation: {
      en: "Al Bateen Beach",
      ar: "شاطئ البطين",
    },
  },
  {
    value: "Al Bihouth",
    translation: {
      en: "Al Bihouth",
      ar: "البحوث",
    },
  },
  {
    value: "Al Danah",
    translation: {
      en: "Al Danah",
      ar: "الدانة",
    },
  },
  {
    value: "Al Dhafra Air Base",
    translation: {
      en: "Al Dhafra Air Base",
      ar: "قاعدة الظفرة الجوية",
    },
  },
  {
    value: "Al Dhafrah",
    translation: {
      en: "Al Dhafrah",
      ar: "الظفرة",
    },
  },
  {
    value: "Al Falah",
    translation: {
      en: "Al Falah",
      ar: "الفلاح",
    },
  },
  {
    value: "Al Fayah",
    translation: {
      en: "Al Fayah",
      ar: "الفيحاء",
    },
  },
  {
    value: "Al Forsan Village",
    translation: {
      en: "Al Forsan Village",
      ar: "قرية الفرسان",
    },
  },
  {
    value: "Al Ghazal Golf Club",
    translation: {
      en: "Al Ghazal Golf Club",
      ar: "نادي الغزال للجولف",
    },
  },
  {
    value: "Al Ghuwaifat",
    translation: {
      en: "Al Ghuwaifat",
      ar: "الغويفات",
    },
  },
  {
    value: "Al Gurm West",
    translation: {
      en: "Al Gurm West",
      ar: "القرم الغربي",
    },
  },
  {
    value: "Al Haffar",
    translation: {
      en: "Al Haffar",
      ar: "الحفار",
    },
  },
  {
    value: "Al Hudairiyat Island",
    translation: {
      en: "Al Hudairiyat Island",
      ar: "جزيرة الحديريات",
    },
  },
  {
    value: "Al Jubail Island",
    translation: {
      en: "Al Jubail Island",
      ar: "جزيرة الجبيل",
    },
  },
  {
    value: "Al Karamah",
    translation: {
      en: "Al Karamah",
      ar: "الكرامة",
    },
  },
  {
    value: "Al Khalidiyah",
    translation: {
      en: "Al Khalidiyah",
      ar: "الخالدية",
    },
  },
  {
    value: "Al Khubeirah",
    translation: {
      en: "Al Khubeirah",
      ar: "الخبيرة",
    },
  },
  {
    value: "Al Lissaily",
    translation: {
      en: "Al Lissaily",
      ar: "الليسيلي",
    },
  },
  {
    value: "Al Lulu Island",
    translation: {
      en: "Al Lulu Island",
      ar: "جزيرة اللولو",
    },
  },
  {
    value: "Al Mafraq Workers City",
    translation: {
      en: "Al Mafraq Workers City",
      ar: "المدينة العمالية في المفرق",
    },
  },
  {
    value: "Al Manhal",
    translation: {
      en: "Al Manhal",
      ar: "المنهل",
    },
  },
  {
    value: "Al Maqta",
    translation: {
      en: "Al Maqta",
      ar: "المقطع",
    },
  },
  {
    value: "Al Markaziyah West",
    translation: {
      en: "Al Markaziyah West",
      ar: "المركزية غرب",
    },
  },
  {
    value: "Al Me'rad",
    translation: {
      en: "Al Me'rad",
      ar: "المعراض",
    },
  },
  {
    value: "Al Mina",
    translation: {
      en: "Al Mina",
      ar: "الميناء",
    },
  },
  {
    value: "Al Muntazah",
    translation: {
      en: "Al Muntazah",
      ar: "المنتزه",
    },
  },
  {
    value: "Al Mushrif",
    translation: {
      en: "Al Mushrif",
      ar: "المشرف",
    },
  },
  {
    value: "Al Mushrif Mall",
    translation: {
      en: "Al Mushrif Mall",
      ar: "مول المشرف",
    },
  },
  {
    value: "Al Muzoon",
    translation: {
      en: "Al Muzoon",
      ar: "المزون",
    },
  },
  {
    value: "Al Nahda East",
    translation: {
      en: "Al Nahda East",
      ar: "النهضة شرق",
    },
  },
  {
    value: "Al Nahdah",
    translation: {
      en: "Al Nahdah",
      ar: "النهضة",
    },
  },
  {
    value: "Al Nahyan",
    translation: {
      en: "Al Nahyan",
      ar: "آل نهيان",
    },
  },
  {
    value: "Al Qurm",
    translation: {
      en: "Al Qurm",
      ar: "القرم",
    },
  },
  {
    value: "Al Raha Beach",
    translation: {
      en: "Al Raha Beach",
      ar: "شاطئ الراحة",
    },
  },
  {
    value: "Al Raha Creek",
    translation: {
      en: "Al Raha Creek",
      ar: "خور الراحة",
    },
  },
  {
    value: "Al Rahbah",
    translation: {
      en: "Al Rahbah",
      ar: "الرحبة",
    },
  },
  {
    value: "Al Ras Al Akhdar",
    translation: {
      en: "Al Ras Al Akhdar",
      ar: "الرأس الأخضر",
    },
  },
  {
    value: "Al Rayyana",
    translation: {
      en: "Al Rayyana",
      ar: "الريانة",
    },
  },
  {
    value: "Al Razeen",
    translation: {
      en: "Al Razeen",
      ar: "الرزين",
    },
  },
  {
    value: "Al Reef",
    translation: {
      en: "Al Reef",
      ar: "الريف",
    },
  },
  {
    value: "Al Reem Island",
    translation: {
      en: "Al Reem Island",
      ar: "جزيرة الريم",
    },
  },
  {
    value: "Al Rowdah",
    translation: {
      en: "Al Rowdah",
      ar: "الروضة",
    },
  },
  {
    value: "Al Rumaila",
    translation: {
      en: "Al Rumaila",
      ar: "الرميلة",
    },
  },
  {
    value: "Al Ruwais",
    translation: {
      en: "Al Ruwais",
      ar: "الرويس",
    },
  },
  {
    value: "Al Sa'adah",
    translation: {
      en: "Al Sa'adah",
      ar: "السعادة",
    },
  },
  {
    value: "Al Sad",
    translation: {
      en: "Al Sad",
      ar: "السعد",
    },
  },
  {
    value: "Al Samha",
    translation: {
      en: "Al Samha",
      ar: "السمحة",
    },
  },
  {
    value: "Al Seef",
    translation: {
      en: "Al Seef",
      ar: "السيف",
    },
  },
  {
    value: "Al Shahama",
    translation: {
      en: "Al Shahama",
      ar: "الشهامة",
    },
  },
  {
    value: "Al Shaleela",
    translation: {
      en: "Al Shaleela",
      ar: "الشليلة",
    },
  },
  {
    value: "Al Shamkha East",
    translation: {
      en: "Al Shamkha East",
      ar: "الشامخة شرق",
    },
  },
  {
    value: "Al Shamkhah",
    translation: {
      en: "Al Shamkhah",
      ar: "الشامخة",
    },
  },
  {
    value: "Al Sharia",
    translation: {
      en: "Al Sharia",
      ar: "الشريعة",
    },
  },
  {
    value: "Al Shawamekh",
    translation: {
      en: "Al Shawamekh",
      ar: "الشوامخ",
    },
  },
  {
    value: "Al Shiwayb",
    translation: {
      en: "Al Shiwayb",
      ar: "الشويب",
    },
  },
  {
    value: "Al Taweelah",
    translation: {
      en: "Al Taweelah",
      ar: "الطويلة",
    },
  },
  {
    value: "Al Wahda Mall",
    translation: {
      en: "Al Wahda Mall",
      ar: "مول الوحدة",
    },
  },
  {
    value: "Al Wahdah",
    translation: {
      en: "Al Wahdah",
      ar: "الوحدة",
    },
  },
  {
    value: "Al Wathba Cycle Track",
    translation: {
      en: "Al Wathba Cycle Track",
      ar: "مسار الوثبة للدراجات",
    },
  },
  {
    value: "Al Wathba North",
    translation: {
      en: "Al Wathba North",
      ar: "الوثبة شمال",
    },
  },
  {
    value: "Al Wathba Wetland",
    translation: {
      en: "Al Wathba Wetland",
      ar: "بحيرة الوثبة",
    },
  },
  {
    value: "Al Wathbah",
    translation: {
      en: "Al Wathbah",
      ar: "الوثبة",
    },
  },
  {
    value: "Al Za'faran St",
    translation: {
      en: "Al Za'faran St",
      ar: "شارع الزعفران",
    },
  },
  {
    value: "Al Zaab",
    translation: {
      en: "Al Zaab",
      ar: "الزعاب",
    },
  },
  {
    value: "Al Zahiyah",
    translation: {
      en: "Al Zahiyah",
      ar: "الزاهية",
    },
  },
  {
    value: "Al Zeina",
    translation: {
      en: "Al Zeina",
      ar: "الزينة",
    },
  },
  {
    value: "Al-mihawi",
    translation: {
      en: "Al-mihawi",
      ar: "المهاوي",
    },
  },
  {
    value: "Asab",
    translation: {
      en: "Asab",
      ar: "عصب",
    },
  },
  {
    value: "Bain Al Jesrain",
    translation: {
      en: "Bain Al Jesrain",
      ar: "بين الجسرين",
    },
  },
  {
    value: "Baniyas",
    translation: {
      en: "Baniyas",
      ar: "بني ياس",
    },
  },
  {
    value: "Baniyas East",
    translation: {
      en: "Baniyas East",
      ar: "بني ياس شرق",
    },
  },
  {
    value: "Baniyas West",
    translation: {
      en: "Baniyas West",
      ar: "بني ياس غرب",
    },
  },
  {
    value: "Bateen Mall",
    translation: {
      en: "Bateen Mall",
      ar: "مول البطين",
    },
  },
  {
    value: "Bawabat Abu Dhabi",
    translation: {
      en: "Bawabat Abu Dhabi",
      ar: "بوابة أبوظبي",
    },
  },
  {
    value: "Baynunah Tower",
    translation: {
      en: "Baynunah Tower",
      ar: "برج بينونة",
    },
  },
  {
    value: "Beda Zayed",
    translation: {
      en: "Beda Zayed",
      ar: "بيدا زايد",
    },
  },
  {
    value: "Bu Hasa",
    translation: {
      en: "Bu Hasa",
      ar: "بوحصا",
    },
  },
  {
    value: "Capital Centre",
    translation: {
      en: "Capital Centre",
      ar: "مركز العاصمة",
    },
  },
  {
    value: "Delma Mall",
    translation: {
      en: "Delma Mall",
      ar: "مول دلما",
    },
  },
  {
    value: "Dhaharat Al-teeb",
    translation: {
      en: "Dhaharat Al-teeb",
      ar: "ظهرة الطيب",
    },
  },
  {
    value: "Embassies District",
    translation: {
      en: "Embassies District",
      ar: "حي السفارات",
    },
  },
  {
    value: "Etihad Rail",
    translation: {
      en: "Etihad Rail",
      ar: "الاتحاد للقطارات",
    },
  },
  {
    value: "Ghiyathi",
    translation: {
      en: "Ghiyathi",
      ar: "غياثي",
    },
  },
  {
    value: "Hadbat Al Za'faranah",
    translation: {
      en: "Hadbat Al Za'faranah",
      ar: "هضبة الزعفرانة",
    },
  },
  {
    value: "Hamdan Street - Al Hosn",
    translation: {
      en: "Hamdan Street - Al Hosn",
      ar: "شارع حمدان - الحصن",
    },
  },
  {
    value: "Hameem",
    translation: {
      en: "Hameem",
      ar: "حميم",
    },
  },
  {
    value: "ICAD 1",
    translation: {
      en: "ICAD 1",
      ar: "مدينة أبوظبي الصناعية الأولى",
    },
  },
  {
    value: "ICAD 2",
    translation: {
      en: "ICAD 2",
      ar: "مدينة أبوظبي الصناعية الثانية",
    },
  },
  {
    value: "Jarn Yafour",
    translation: {
      en: "Jarn Yafour",
      ar: "جرن يافور",
    },
  },
  {
    value: "Jazeerat Al Maryah",
    translation: {
      en: "Jazeerat Al Maryah",
      ar: "جزيرة المارية",
    },
  },
  {
    value: "Jazeerat Al Shelaylah",
    translation: {
      en: "Jazeerat Al Shelaylah",
      ar: "جزيرة الشليلة",
    },
  },
  {
    value: "Jebel Dhanna",
    translation: {
      en: "Jebel Dhanna",
      ar: "جبل الظنة",
    },
  },
  {
    value: "Khalidiyah Mall",
    translation: {
      en: "Khalidiyah Mall",
      ar: "مول الخالدية",
    },
  },
  {
    value: "Khalifa City",
    translation: {
      en: "Khalifa City",
      ar: "مدينة خليفة",
    },
  },
  {
    value: "Khalifa City B",
    translation: {
      en: "Khalifa City B",
      ar: "مدينة خليفة ب",
    },
  },
  {
    value: "Khalifa City C",
    translation: {
      en: "Khalifa City C",
      ar: "مدينة خليفة ج",
    },
  },
  {
    value: "Khalifa Park 1",
    translation: {
      en: "Khalifa Park 1",
      ar: "حديقة خليفة 1",
    },
  },
  {
    value: "Khalifa University - San Campus",
    translation: {
      en: "Khalifa University - San Campus",
      ar: "جامعة خليفة - الحرم الجامعي في ساس",
    },
  },
  {
    value: "Khor Al Maqta'a",
    translation: {
      en: "Khor Al Maqta'a",
      ar: "خور المقطع",
    },
  },
  {
    value: "Madinat Zayed Shopping Centre",
    translation: {
      en: "Madinat Zayed Shopping Centre",
      ar: "مركز تسوق مدينة زايد",
    },
  },
  {
    value: "Mafraq",
    translation: {
      en: "Mafraq",
      ar: "المفرق",
    },
  },
  {
    value: "Mafraq Industrial Area",
    translation: {
      en: "Mafraq Industrial Area",
      ar: "المنطقة الصناعية بالمفرق",
    },
  },
  {
    value: "Mangrove National Park",
    translation: {
      en: "Mangrove National Park",
      ar: "حديقة المانغروف الوطنية",
    },
  },
  {
    value: "Mangrove Village",
    translation: {
      en: "Mangrove Village",
      ar: "قرية المانجروف",
    },
  },
  {
    value: "Marina Mall",
    translation: {
      en: "Marina Mall",
      ar: "مول مارينا",
    },
  },
  {
    value: "Masdar City",
    translation: {
      en: "Masdar City",
      ar: "مدينة مصدر",
    },
  },
  {
    value: "Mazayed Village",
    translation: {
      en: "Mazayed Village",
      ar: "قرية مزيد",
    },
  },
  {
    value: "Mazyad Mall",
    translation: {
      en: "Mazyad Mall",
      ar: "مول مزيد",
    },
  },
  {
    value: "Mina Zayed",
    translation: {
      en: "Mina Zayed",
      ar: "مينا زايد",
    },
  },
  {
    value: "Mohamed Bin Zayed City",
    translation: {
      en: "Mohamed Bin Zayed City",
      ar: "مدينة محمد بن زايد",
    },
  },
  {
    value: "Musaffah",
    translation: {
      en: "Musaffah",
      ar: "مصفح",
    },
  },
  {
    value: "Mussafah Community",
    translation: {
      en: "Mussafah Community",
      ar: "المصفح السكنية",
    },
  },
  {
    value: "Mussafah Industrial",
    translation: {
      en: "Mussafah Industrial",
      ar: "المصفح الصناعية",
    },
  },
  {
    value: "Nahil",
    translation: {
      en: "Nahil",
      ar: "ناهل",
    },
  },
  {
    value: "Nareel Island",
    translation: {
      en: "Nareel Island",
      ar: "جزيرة ناريل",
    },
  },
  {
    value: "New Al Nahdha",
    translation: {
      en: "New Al Nahdha",
      ar: "النهضة الجديدة",
    },
  },
  {
    value: "Qareen Al Aish",
    translation: {
      en: "Qareen Al Aish",
      ar: "قرين العيش",
    },
  },
  {
    value: "Qasr Al Bahr",
    translation: {
      en: "Qasr Al Bahr",
      ar: "قصر البحر",
    },
  },
  {
    value: "Rawdat Al-reef",
    translation: {
      en: "Rawdat Al-reef",
      ar: "روضة الريف",
    },
  },
  {
    value: "Saadiyat Island",
    translation: {
      en: "Saadiyat Island",
      ar: "جزيرة السعديات",
    },
  },
  {
    value: "Samaliyah Island",
    translation: {
      en: "Samaliyah Island",
      ar: "جزيرة السمالية",
    },
  },
  {
    value: "Sas Al Nakhl Island",
    translation: {
      en: "Sas Al Nakhl Island",
      ar: "جزيرة ساس النخل",
    },
  },
  {
    value: "Sas Al Nakhl Village",
    translation: {
      en: "Sas Al Nakhl Village",
      ar: "قرية ساس النخل",
    },
  },
  {
    value: "SE45",
    translation: {
      en: "SE45",
      ar: "منطقة SE45",
    },
  },
  {
    value: "Sector 15",
    translation: {
      en: "Sector 15",
      ar: "القطاع 15",
    },
  },
  {
    value: "Shahamah",
    translation: {
      en: "Shahamah",
      ar: "الشهامة",
    },
  },
  {
    value: "Shakhbout City",
    translation: {
      en: "Shakhbout City",
      ar: "مدينة شخبوط",
    },
  },
  {
    value: "Sheikh Khalifa Medical City",
    translation: {
      en: "Sheikh Khalifa Medical City",
      ar: "مدينة الشيخ خليفة الطبية",
    },
  },
  {
    value: "Sheikh Zayed Cricket Stadium",
    translation: {
      en: "Sheikh Zayed Cricket Stadium",
      ar: "استاد الشيخ زايد للكريكيت",
    },
  },
  {
    value: "Sheikh Zayed Grand Mosque Center",
    translation: {
      en: "Sheikh Zayed Grand Mosque Center",
      ar: "مركز جامع الشيخ زايد الكبير",
    },
  },
  {
    value: "Sweihan",
    translation: {
      en: "Sweihan",
      ar: "سويحان",
    },
  },
  {
    value: "The Gate District",
    translation: {
      en: "The Gate District",
      ar: "منطقة البوابة",
    },
  },
  {
    value: "Umm Yifenah Island",
    translation: {
      en: "Umm Yifenah Island",
      ar: "جزيرة أم يفنة",
    },
  },
  {
    value: "W56",
    translation: {
      en: "W56",
      ar: "منطقة W56",
    },
  },
  {
    value: "Watani Residential",
    translation: {
      en: "Watani Residential",
      ar: "مشروع وطني السكني",
    },
  },
  {
    value: "Yas Island",
    translation: {
      en: "Yas Island",
      ar: "جزيرة ياس",
    },
  },
  {
    value: "Yas Marina Circuit",
    translation: {
      en: "Yas Marina Circuit",
      ar: "حلبة سباق مرسى ياس",
    },
  },
  {
    value: "Zayed City",
    translation: {
      en: "Zayed City",
      ar: "مدينة زايد",
    },
  },
  {
    value: "Zayed Military City",
    translation: {
      en: "Zayed Military City",
      ar: "مدينة زايد العسكرية",
    },
  },
  {
    value: "Zayed Military Hospital",
    translation: {
      en: "Zayed Military Hospital",
      ar: "مستشفى زايد العسكري",
    },
  },
  {
    value: "Zayed Sports City",
    translation: {
      en: "Zayed Sports City",
      ar: "مدينة زايد الرياضية",
    },
  },
  {
    value: "Zayed Univesity",
    translation: {
      en: "Zayed Univesity",
      ar: "جامعة زايد",
    },
  },
  {
    value: "Zeraa Island",
    translation: {
      en: "Zeraa Island",
      ar: "جزيرة زِرعة",
    },
  },
  {
    value: "Zone 1",
    translation: {
      en: "Zone 1",
      ar: "المنطقة 1",
    },
  },
];

export const ajmanAreas = [
  {
    value: "Ajman Free Zone",
    translation: {
      en: "Ajman Free Zone",
      ar: "منطقة عجمان الحرة",
    },
  },
  {
    value: "Ajman Industrial 2",
    translation: {
      en: "Ajman Industrial 2",
      ar: "عجمان الصناعية 2",
    },
  },
  {
    value: "Al Alia",
    translation: {
      en: "Al Alia",
      ar: "العالية",
    },
  },
  {
    value: "Al Amerah",
    translation: {
      en: "Al Amerah",
      ar: "العامرة",
    },
  },
  {
    value: "Al Bustan - Ajman",
    translation: {
      en: "Al Bustan - Ajman",
      ar: "البستان - عجمان",
    },
  },
  {
    value: "Al Hamidiya 1",
    translation: {
      en: "Al Hamidiya 1",
      ar: "الحميدية 1",
    },
  },
  {
    value: "Al Hamidiya 2",
    translation: {
      en: "Al Hamidiya 2",
      ar: "الحميدية 2",
    },
  },
  {
    value: "Al Hamriyah Free Zone",
    translation: {
      en: "Al Hamriyah Free Zone",
      ar: "منطقة الحمرية الحرة",
    },
  },
  {
    value: "Al Helio 1",
    translation: {
      en: "Al Helio 1",
      ar: "الحليو 1",
    },
  },
  {
    value: "Al Helio 2",
    translation: {
      en: "Al Helio 2",
      ar: "الحليو 2",
    },
  },
  {
    value: "Al Jerf 1",
    translation: {
      en: "Al Jerf 1",
      ar: "الجرف 1",
    },
  },
  {
    value: "Al Jerf 2",
    translation: {
      en: "Al Jerf 2",
      ar: "الجرف 2",
    },
  },
  {
    value: "Al Jerf Industrial 1",
    translation: {
      en: "Al Jerf Industrial 1",
      ar: "الجرف الصناعية 1",
    },
  },
  {
    value: "Al Jerf Industrial 2",
    translation: {
      en: "Al Jerf Industrial 2",
      ar: "الجرف الصناعية 2",
    },
  },
  {
    value: "Al Manamah",
    translation: {
      en: "Al Manamah",
      ar: "المنامة",
    },
  },
  {
    value: "Al Muaihat 1",
    translation: {
      en: "Al Muaihat 1",
      ar: "المويهات 1",
    },
  },
  {
    value: "Al Muntazi 1",
    translation: {
      en: "Al Muntazi 1",
      ar: "المنتزي 1",
    },
  },
  {
    value: "Al Muntazi 2",
    translation: {
      en: "Al Muntazi 2",
      ar: "المنتزي 2",
    },
  },
  {
    value: "Al Muwaihat 2",
    translation: {
      en: "Al Muwaihat 2",
      ar: "المويهات 2",
    },
  },
  {
    value: "Al Muwaihat 3",
    translation: {
      en: "Al Muwaihat 3",
      ar: "المويهات 3",
    },
  },
  {
    value: "Al Nakhil 1",
    translation: {
      en: "Al Nakhil 1",
      ar: "النخيل 1",
    },
  },
  {
    value: "Al Nuaimia",
    translation: {
      en: "Al Nuaimia",
      ar: "النعيمية",
    },
  },
  {
    value: "Al Nuaimia 1",
    translation: {
      en: "Al Nuaimia 1",
      ar: "النعيمية 1",
    },
  },
  {
    value: "Al Owan",
    translation: {
      en: "Al Owan",
      ar: "العوان",
    },
  },
  {
    value: "Al Raqaib 1",
    translation: {
      en: "Al Raqaib 1",
      ar: "الرقايب 1",
    },
  },
  {
    value: "Al Raqaib 2",
    translation: {
      en: "Al Raqaib 2",
      ar: "الرقايب 2",
    },
  },
  {
    value: "Al Rashidiya 2",
    translation: {
      en: "Al Rashidiya 2",
      ar: "الراشدية 2",
    },
  },
  {
    value: "Al Rashidiya 3",
    translation: {
      en: "Al Rashidiya 3",
      ar: "الراشدية 3",
    },
  },
  {
    value: "Al Rawda 1",
    translation: {
      en: "Al Rawda 1",
      ar: "الروضة 1",
    },
  },
  {
    value: "Al Rawda 2",
    translation: {
      en: "Al Rawda 2",
      ar: "الروضة 2",
    },
  },
  {
    value: "Al Rawdha 3",
    translation: {
      en: "Al Rawdha 3",
      ar: "الروضة 3",
    },
  },
  {
    value: "Al Rumailah",
    translation: {
      en: "Al Rumailah",
      ar: "الرميلة",
    },
  },
  {
    value: "Al Talla 1",
    translation: {
      en: "Al Talla 1",
      ar: "التلة 1",
    },
  },
  {
    value: "Al Tallah 2",
    translation: {
      en: "Al Tallah 2",
      ar: "التلة 2",
    },
  },
  {
    value: "Al Zahya",
    translation: {
      en: "Al Zahya",
      ar: "الزاهية",
    },
  },
  {
    value: "Free Zone",
    translation: {
      en: "Free Zone",
      ar: "المنطقة الحرة",
    },
  },
  {
    value: "Hamidiya",
    translation: {
      en: "Hamidiya",
      ar: "الحميدية",
    },
  },
  {
    value: "Industrial Area",
    translation: {
      en: "Industrial Area",
      ar: "المنطقة الصناعية",
    },
  },
  {
    value: "Industrial Area 1",
    translation: {
      en: "Industrial Area 1",
      ar: "المنطقة الصناعية 1",
    },
  },
  {
    value: "Meshairef",
    translation: {
      en: "Meshairef",
      ar: "مشيرف",
    },
  },
];

export const alAinAreas = [
  {
    value: "Abu Samrah",
    translation: {
      en: "Abu Samrah",
      ar: "أبو سمرة",
    },
  },
  {
    value: "Al Dhahir",
    translation: {
      en: "Al Dhahir",
      ar: "الظاهر",
    },
  },
  {
    value: "Al Markhaniyyah",
    translation: {
      en: "Al Markhaniyyah",
      ar: "المرخانية",
    },
  },
  {
    value: "Al Salamat",
    translation: {
      en: "Al Salamat",
      ar: "السلامات",
    },
  },
  {
    value: "Rimah",
    translation: {
      en: "Rimah",
      ar: "ريمة",
    },
  },
  {
    value: "Ain Al Fayda",
    translation: {
      en: "Ain Al Fayda",
      ar: "عين الفايضة",
    },
  },
  {
    value: "Al Aflaj",
    translation: {
      en: "Al Aflaj",
      ar: "الأفلاج",
    },
  },
  {
    value: "Al Ain Hospital",
    translation: {
      en: "Al Ain Hospital",
      ar: "مستشفى العين",
    },
  },
  {
    value: "Al Ain Industrial Area",
    translation: {
      en: "Al Ain Industrial Area",
      ar: "منطقة العين الصناعية",
    },
  },
  {
    value: "Al Ain International Airport",
    translation: {
      en: "Al Ain International Airport",
      ar: "مطار العين الدولي",
    },
  },
  {
    value: "Al Ain Oasis",
    translation: {
      en: "Al Ain Oasis",
      ar: "واحة العين",
    },
  },
  {
    value: "Al Andalus Compound",
    translation: {
      en: "Al Andalus Compound",
      ar: "مجمع الأندلس",
    },
  },
  {
    value: "Al Bateen",
    translation: {
      en: "Al Bateen",
      ar: "البطين",
    },
  },
  {
    value: "Al Dahmaa",
    translation: {
      en: "Al Dahmaa",
      ar: "الدهماء",
    },
  },
  {
    value: "Al Dhahir",
    translation: {
      en: "Al Dhahir",
      ar: "الظاهر",
    },
  },
  {
    value: "Al Dhahrah",
    translation: {
      en: "Al Dhahrah",
      ar: "الظهرة",
    },
  },
  {
    value: "Al Faqa",
    translation: {
      en: "Al Faqa",
      ar: "الفقع",
    },
  },
  {
    value: "Al Fou'ah",
    translation: {
      en: "Al Fou'ah",
      ar: "الفوعة",
    },
  },
  {
    value: "Al Hayer",
    translation: {
      en: "Al Hayer",
      ar: "الهير",
    },
  },
  {
    value: "Al Hili",
    translation: {
      en: "Al Hili",
      ar: "الهيلي",
    },
  },
  {
    value: "Al Iqabiyyah",
    translation: {
      en: "Al Iqabiyyah",
      ar: "العقبية",
    },
  },
  {
    value: "Al Jahili",
    translation: {
      en: "Al Jahili",
      ar: "الجاهلي",
    },
  },
  {
    value: "Al Jimi",
    translation: {
      en: "Al Jimi",
      ar: "الجيمي",
    },
  },
  {
    value: "Al Khaznah",
    translation: {
      en: "Al Khaznah",
      ar: "الخزنة",
    },
  },
  {
    value: "Al Khibeesi",
    translation: {
      en: "Al Khibeesi",
      ar: "الخبيصي",
    },
  },
  {
    value: "Al Khirayr",
    translation: {
      en: "Al Khirayr",
      ar: "الخرير",
    },
  },
  {
    value: "Al Maqam",
    translation: {
      en: "Al Maqam",
      ar: "المقام",
    },
  },
  {
    value: "Al Markhaniya",
    translation: {
      en: "Al Markhaniya",
      ar: "المرخانية",
    },
  },
  {
    value: "Al Mas'oudi",
    translation: {
      en: "Al Mas'oudi",
      ar: "المسعودي",
    },
  },
  {
    value: "Al Mnaizfah",
    translation: {
      en: "Al Mnaizfah",
      ar: "المصفاة",
    },
  },
  {
    value: "Al Mnaizlah",
    translation: {
      en: "Al Mnaizlah",
      ar: "المنيزلة",
    },
  },
  {
    value: "Al Mu'tarid",
    translation: {
      en: "Al Mu'tarid",
      ar: "المعترض",
    },
  },
  {
    value: "Al Murabaa",
    translation: {
      en: "Al Murabaa",
      ar: "المربع",
    },
  },
  {
    value: "Al Mutaw'ah",
    translation: {
      en: "Al Mutaw'ah",
      ar: "المطاوعة",
    },
  },
  {
    value: "Al Muwaiji",
    translation: {
      en: "Al Muwaiji",
      ar: "المويجعي",
    },
  },
  {
    value: "Al Neyadat",
    translation: {
      en: "Al Neyadat",
      ar: "النيادات",
    },
  },
  {
    value: "Al Owainah",
    translation: {
      en: "Al Owainah",
      ar: "العوينة",
    },
  },
  {
    value: "Al Qattara",
    translation: {
      en: "Al Qattara",
      ar: "القطارة",
    },
  },
  {
    value: "Al Qisais",
    translation: {
      en: "Al Qisais",
      ar: "اﻟﻘﺼﻴﺺ",
    },
  },
  {
    value: "Al Qou'a",
    translation: {
      en: "Al Qou'a",
      ar: "القرعاء",
    },
  },
  {
    value: "Al Rawdah",
    translation: {
      en: "Al Rawdah",
      ar: "الروضة",
    },
  },
  {
    value: "Al Rawdah Al Sharqiyah",
    translation: {
      en: "Al Rawdah Al Sharqiyah",
      ar: "الروضة الشرقية",
    },
  },
  {
    value: "Al Ruwaikah",
    translation: {
      en: "Al Ruwaikah",
      ar: "الرويكة",
    },
  },
  {
    value: "Al Ruwaydat",
    translation: {
      en: "Al Ruwaydat",
      ar: "الرويضات",
    },
  },
  {
    value: "Al Salamat",
    translation: {
      en: "Al Salamat",
      ar: "السلامات",
    },
  },
  {
    value: "Al Sarouj",
    translation: {
      en: "Al Sarouj",
      ar: "الصاروج",
    },
  },
  {
    value: "Al Shabhat Plaza",
    translation: {
      en: "Al Shabhat Plaza",
      ar: "الشبات بلازا",
    },
  },
  {
    value: "Al Shuaibah",
    translation: {
      en: "Al Shuaibah",
      ar: "الشعيبة",
    },
  },
  {
    value: "Al Tiwayyah",
    translation: {
      en: "Al Tiwayyah",
      ar: "الطوية",
    },
  },
  {
    value: "Al Yahar",
    translation: {
      en: "Al Yahar",
      ar: "اليحر",
    },
  },
  {
    value: "Al Yahar North",
    translation: {
      en: "Al Yahar North",
      ar: "اليحر الشمالي",
    },
  },
  {
    value: "Al Yahar South",
    translation: {
      en: "Al Yahar South",
      ar: "اليحر الجنوبي",
    },
  },
  {
    value: "Ameriya",
    translation: {
      en: "Ameriya",
      ar: "العامرية",
    },
  },
  {
    value: "Asharij",
    translation: {
      en: "Asharij",
      ar: "عشارج",
    },
  },
  {
    value: "Bad' Bint Sa'oud",
    translation: {
      en: "Bad' Bint Sa'oud",
      ar: "بدع بنت سعود",
    },
  },
  {
    value: "Bida Bint Saud Masjid",
    translation: {
      en: "Bida Bint Saud Masjid",
      ar: "مسجد بدع بنت سعود",
    },
  },
  {
    value: "Bu Kirayyah",
    translation: {
      en: "Bu Kirayyah",
      ar: "بو كرية",
    },
  },
  {
    value: "Central District",
    translation: {
      en: "Central District",
      ar: "وسط المدينة",
    },
  },
  {
    value: "Civic Center",
    translation: {
      en: "Civic Center",
      ar: "المركز المجتمعي",
    },
  },
  {
    value: "Falaj Hazzaa",
    translation: {
      en: "Falaj Hazzaa",
      ar: "فلج هزاع",
    },
  },
  {
    value: "Ghireebah",
    translation: {
      en: "Ghireebah",
      ar: "غريبة",
    },
  },
  {
    value: "Hai Khalid",
    translation: {
      en: "Hai Khalid",
      ar: "حي خالد",
    },
  },
  {
    value: "Industrial Area",
    translation: {
      en: "Industrial Area",
      ar: "المنطقة الصناعية",
    },
  },
  {
    value: "Jabel Hafeet",
    translation: {
      en: "Jabel Hafeet",
      ar: "جبل حفيت",
    },
  },
  {
    value: "Khatm Al Shiklah",
    translation: {
      en: "Khatm Al Shiklah",
      ar: "خطم الشكلة",
    },
  },
  {
    value: "Majlood - Al Muwaiji",
    translation: {
      en: "Majlood - Al Muwaiji",
      ar: "مجلود - المويجعي",
    },
  },
  {
    value: "Malaqit",
    translation: {
      en: "Malaqit",
      ar: "ملاقط",
    },
  },
  {
    value: "Masakin",
    translation: {
      en: "Masakin",
      ar: "مساكن",
    },
  },
  {
    value: "Mazyad",
    translation: {
      en: "Mazyad",
      ar: "مزيد",
    },
  },
  {
    value: "Mutaredh Oasis",
    translation: {
      en: "Mutaredh Oasis",
      ar: "واحة المعترض",
    },
  },
  {
    value: "Neima Mall",
    translation: {
      en: "Neima Mall",
      ar: "مول نعمة",
    },
  },
  {
    value: "Remah",
    translation: {
      en: "Remah",
      ar: "رماح",
    },
  },
  {
    value: "Shareat Al Muwaiji",
    translation: {
      en: "Shareat Al Muwaiji",
      ar: "شريعة المويجعي",
    },
  },
  {
    value: "Sweihan",
    translation: {
      en: "Sweihan",
      ar: "سويحان",
    },
  },
  {
    value: "Tawam",
    translation: {
      en: "Tawam",
      ar: "توام",
    },
  },
  {
    value: "Um Ghafah",
    translation: {
      en: "Um Ghafah",
      ar: "أم غافة",
    },
  },
  {
    value: "Zakhir",
    translation: {
      en: "Zakhir",
      ar: "زاخر",
    },
  },
  {
    value: "Zayed Mosque Town",
    translation: {
      en: "Zayed Mosque Town",
      ar: "مدينة جامع زايد",
    },
  },
];

export const dubaiAreas = [
  {
    value: "Ghantout",
    translation: {
      en: "Ghantout",
      ar: "غنتوت",
    },
  },
  {
    value: "Abu Hail",
    translation: {
      en: "Abu Hail",
      ar: "أبو هيل",
    },
  },
  {
    value: "Academic City",
    translation: {
      en: "Academic City",
      ar: "المدينة الأكاديمية",
    },
  },
  {
    value: "Airport Terminal 1",
    translation: {
      en: "Airport Terminal 1",
      ar: "صالة المطار 1",
    },
  },
  {
    value: "Al Aweer",
    translation: {
      en: "Al Aweer",
      ar: "العوير",
    },
  },
  {
    value: "Al Bada'a",
    translation: {
      en: "Al Bada'a",
      ar: "البدع",
    },
  },
  {
    value: "Al Baraha",
    translation: {
      en: "Al Baraha",
      ar: "البراحة",
    },
  },
  {
    value: "Al Barari",
    translation: {
      en: "Al Barari",
      ar: "البراري",
    },
  },
  {
    value: "Al Barsha",
    translation: {
      en: "Al Barsha",
      ar: "البرشاء",
    },
  },
  {
    value: "Al Barsha 1",
    translation: {
      en: "Al Barsha 1",
      ar: "البرشاء 1",
    },
  },
  {
    value: "Al Barsha 2",
    translation: {
      en: "Al Barsha 2",
      ar: "البرشاء 2",
    },
  },
  {
    value: "Al Barsha 3",
    translation: {
      en: "Al Barsha 3",
      ar: "البرشاء 3",
    },
  },
  {
    value: "Al Barsha South 2",
    translation: {
      en: "Al Barsha South 2",
      ar: "البرشاء جنوب 2",
    },
  },
  {
    value: "Al Barsha South 4",
    translation: {
      en: "Al Barsha South 4",
      ar: "البرشاء جنوب 4",
    },
  },
  {
    value: "Al Barsha South 5",
    translation: {
      en: "Al Barsha South 5",
      ar: "البرشاء جنوب 5",
    },
  },
  {
    value: "Al Barsha South1",
    translation: {
      en: "Al Barsha South1",
      ar: "البرشاء جنوب 1",
    },
  },
  {
    value: "Al Furjan",
    translation: {
      en: "Al Furjan",
      ar: "الفرجان",
    },
  },
  {
    value: "Al Hamriya Port",
    translation: {
      en: "Al Hamriya Port",
      ar: "ميناء الحمرية",
    },
  },
  {
    value: "Al Hebiah",
    translation: {
      en: "Al Hebiah",
      ar: "الحبيه",
    },
  },
  {
    value: "Al Hebiah Fourth",
    translation: {
      en: "Al Hebiah Fourth",
      ar: "الحبيه الرابعة",
    },
  },
  {
    value: "Al Jaddaf",
    translation: {
      en: "Al Jaddaf",
      ar: "الجداف",
    },
  },
  {
    value: "Al Jafiliya",
    translation: {
      en: "Al Jafiliya",
      ar: "الجافلية",
    },
  },
  {
    value: "Al Karama",
    translation: {
      en: "Al Karama",
      ar: "الكرامة",
    },
  },
  {
    value: "Al Khaibaisi",
    translation: {
      en: "Al Khaibaisi",
      ar: "الخبيصي",
    },
  },
  {
    value: "Al Khawaneej 1",
    translation: {
      en: "Al Khawaneej 1",
      ar: "الخوانيج 1",
    },
  },
  {
    value: "Al Khawaneej 2",
    translation: {
      en: "Al Khawaneej 2",
      ar: "الخوانيج 2",
    },
  },
  {
    value: "Al Kifaf",
    translation: {
      en: "Al Kifaf",
      ar: "الكفاف",
    },
  },
  {
    value: "Al Lisaili",
    translation: {
      en: "Al Lisaili",
      ar: "الليسيلي",
    },
  },
  {
    value: "Al Maktoum International Airport",
    translation: {
      en: "Al Maktoum International Airport",
      ar: "مطار آل مكتوم الدولي",
    },
  },
  {
    value: "Al Manara",
    translation: {
      en: "Al Manara",
      ar: "المنارة",
    },
  },
  {
    value: "Al Mankhool",
    translation: {
      en: "Al Mankhool",
      ar: "المنخول",
    },
  },
  {
    value: "Al Mizhar 1",
    translation: {
      en: "Al Mizhar 1",
      ar: "المزهر 1",
    },
  },
  {
    value: "Al Mizhar 2",
    translation: {
      en: "Al Mizhar 2",
      ar: "المزهر 2",
    },
  },
  {
    value: "Al Murar",
    translation: {
      en: "Al Murar",
      ar: "المرر",
    },
  },
  {
    value: "Al Muteena",
    translation: {
      en: "Al Muteena",
      ar: "المطينة",
    },
  },
  {
    value: "Al Nahda 1",
    translation: {
      en: "Al Nahda 1",
      ar: "النهضة 1",
    },
  },
  {
    value: "Al Nahda 2 - Dubai",
    translation: {
      en: "Al Nahda 2 - Dubai",
      ar: "النهضة 2 - دبي",
    },
  },
  {
    value: "Al Quasis 2",
    translation: {
      en: "Al Quasis 2",
      ar: "القصيص 2",
    },
  },
  {
    value: "Al Quasis Ind. 2",
    translation: {
      en: "Al Quasis Ind. 2",
      ar: "القصيص الصناعية 2",
    },
  },
  {
    value: "Al Quasis Ind. 3",
    translation: {
      en: "Al Quasis Ind. 3",
      ar: "القصيص الصناعية 3",
    },
  },
  {
    value: "Al Quasis Ind. 4",
    translation: {
      en: "Al Quasis Ind. 4",
      ar: "القصيص الصناعية 4",
    },
  },
  {
    value: "Al Quasis Ind. 5",
    translation: {
      en: "Al Quasis Ind. 5",
      ar: "القصيص الصناعية 5",
    },
  },
  {
    value: "Al Quoz",
    translation: {
      en: "Al Quoz",
      ar: "القوز",
    },
  },
  {
    value: "Al Quoz 1",
    translation: {
      en: "Al Quoz 1",
      ar: "القوز 1",
    },
  },
  {
    value: "Al Quoz 2",
    translation: {
      en: "Al Quoz 2",
      ar: "القوز 2",
    },
  },
  {
    value: "Al Quoz 3",
    translation: {
      en: "Al Quoz 3",
      ar: "القوز 3",
    },
  },
  {
    value: "Al Quoz 4",
    translation: {
      en: "Al Quoz 4",
      ar: "القوز 4",
    },
  },
  {
    value: "Al Quoz Industrial",
    translation: {
      en: "Al Quoz Industrial",
      ar: "القوز الصناعية",
    },
  },
  {
    value: "Al Quoz Industrial Area 2",
    translation: {
      en: "Al Quoz Industrial Area 2",
      ar: "منطقة القوز الصناعية 2",
    },
  },
  {
    value: "Al Quoz Industrial Area 3",
    translation: {
      en: "Al Quoz Industrial Area 3",
      ar: "منطقة القوز الصناعية 3",
    },
  },
  {
    value: "Al Quoz Industrial Area 4",
    translation: {
      en: "Al Quoz Industrial Area 4",
      ar: "منطقة القوز الصناعية 4",
    },
  },
  {
    value: "Al Qurda, Camel Race",
    translation: {
      ar: "Al Qurda, Camel Race",
      en: "Al Qurda, Camel Race",
    },
  },
  {
    value: "Al Qusais 1",
    translation: {
      en: "Al Qusais 1",
      ar: "القصيص 1",
    },
  },
  {
    value: "Al Qusais 2",
    translation: {
      en: "Al Qusais 2",
      ar: "القصيص 2",
    },
  },
  {
    value: "Al Qusais 3",
    translation: {
      en: "Al Qusais 3",
      ar: "القصيص 3",
    },
  },
  {
    value: "Al Qusais Industrial Area",
    translation: {
      en: "Al Qusais Industrial Area",
      ar: "القصيص المنطقة الصناعية",
    },
  },
  {
    value: "Al Qusais Industrial Area 1",
    translation: {
      en: "Al Qusais Industrial Area 1",
      ar: "القصيص المنطقة الصناعية 1",
    },
  },
  {
    value: "Al Raffa",
    translation: {
      en: "Al Raffa",
      ar: "الرفاعة",
    },
  },
  {
    value: "Al Ras",
    translation: {
      en: "Al Ras",
      ar: "الرأس",
    },
  },
  {
    value: "Al Rashidiya - Dubai",
    translation: {
      en: "Al Rashidiya - Dubai",
      ar: "الراشدية - دبي",
    },
  },
  {
    value: "Al Rigga",
    translation: {
      en: "Al Rigga",
      ar: "الرقة",
    },
  },
  {
    value: "Al Sabkha - Dubai",
    translation: {
      en: "Al Sabkha - Dubai",
      ar: "السبخة - دبي",
    },
  },
  {
    value: "Al Safa 1",
    translation: {
      en: "Al Safa 1",
      ar: "الصفا 1",
    },
  },
  {
    value: "Al Safa 2",
    translation: {
      en: "Al Safa 2",
      ar: "الصفا 2",
    },
  },
  {
    value: "Al Satwa",
    translation: {
      en: "Al Satwa",
      ar: "السطوة",
    },
  },
  {
    value: "Al Sufouh 1",
    translation: {
      en: "Al Sufouh 1",
      ar: "الصفوح 1",
    },
  },
  {
    value: "Al Thanyah",
    translation: {
      en: "Al Thanyah",
      ar: "الثنيه",
    },
  },
  {
    value: "Al Thanyah Mall",
    translation: {
      en: "Al Thanyah Mall",
      ar: "مول الثنيه",
    },
  },
  {
    value: "Al Twar 1",
    translation: {
      en: "Al Twar 1",
      ar: "الطوار 1",
    },
  },
  {
    value: "Al Twar 2",
    translation: {
      en: "Al Twar 2",
      ar: "الطوار 2",
    },
  },
  {
    value: "Al Twar 3",
    translation: {
      en: "Al Twar 3",
      ar: "الطوار 3",
    },
  },
  {
    value: "Al Waha",
    translation: {
      en: "Al Waha",
      ar: "الواحة",
    },
  },
  {
    value: "Al Waheda",
    translation: {
      en: "Al Waheda",
      ar: "الوحدة",
    },
  },
  {
    value: "Al Warqa",
    translation: {
      en: "Al Warqa",
      ar: "الورقاء",
    },
  },
  {
    value: "Al Warqa 1",
    translation: {
      en: "Al Warqa 1",
      ar: "الورقاء 1",
    },
  },
  {
    value: "Al Warqa 2",
    translation: {
      en: "Al Warqa 2",
      ar: "الورقاء 2",
    },
  },
  {
    value: "Al Warqa 3",
    translation: {
      en: "Al Warqa 3",
      ar: "الورقاء 3",
    },
  },
  {
    value: "Al Warqa 4",
    translation: {
      en: "Al Warqa 4",
      ar: "الورقاء 4",
    },
  },
  {
    value: "Al Wasl",
    translation: {
      en: "Al Wasl",
      ar: "الوصل",
    },
  },
  {
    value: "Arabian Ranches",
    translation: {
      en: "Arabian Ranches",
      ar: "المرابع العربية",
    },
  },
  {
    value: "Bab Al Shams",
    translation: {
      en: "Bab Al Shams",
      ar: "باب الشمس",
    },
  },
  {
    value: "Barsha Heights",
    translation: {
      en: "Barsha Heights",
      ar: "البرشاء هايتس",
    },
  },
  {
    value: "Bur Dubai",
    translation: {
      en: "Bur Dubai",
      ar: "بر دبي",
    },
  },
  {
    value: "Burj Khalifa",
    translation: {
      en: "Burj Khalifa",
      ar: "برج خليفة",
    },
  },
  {
    value: "Business Bay",
    translation: {
      en: "Business Bay",
      ar: "الخليج التجاري",
    },
  },
  {
    value: "City Centre Me'aisem",
    translation: {
      en: "City Centre Me'aisem",
      ar: "سيتي سنتر معيصم",
    },
  },
  {
    value: "Deira",
    translation: {
      en: "Deira",
      ar: "ديرة",
    },
  },
  {
    value: "Deira City Centre",
    translation: {
      en: "Deira City Centre",
      ar: "سيتي سنتر ديرة",
    },
  },
  {
    value: "DIFC - Trade Centre",
    translation: {
      en: "DIFC - Trade Centre",
      ar: "مركز دبي المالي العالمي - المركز التجاري",
    },
  },
  {
    value: "Discovery Garden",
    translation: {
      en: "Discovery Garden",
      ar: "ديسكفري جاردنز",
    },
  },
  {
    value: "Downtown Dubai",
    translation: {
      en: "Downtown Dubai",
      ar: "وسط مدينة دبي",
    },
  },
  {
    value: "Downtown Jabal Ali",
    translation: {
      en: "Downtown Jabal Ali",
      ar: "وسط مدينة جبل علي",
    },
  },
  {
    value: "Dragon Mart",
    translation: {
      en: "Dragon Mart",
      ar: "سوق التنين",
    },
  },
  {
    value: "Dubai Design Distric",
    translation: {
      en: "Dubai Design Distric",
      ar: "منطقة دبي للتصميم",
    },
  },
  {
    value: "Dubai Festival City",
    translation: {
      en: "Dubai Festival City",
      ar: "دبي فستيفال سيتي",
    },
  },
  {
    value: "Dubai International Airport",
    translation: {
      en: "Dubai International Airport",
      ar: "مطار دبي الدولي",
    },
  },
  {
    value: "Dubai Internet City",
    translation: {
      en: "Dubai Internet City",
      ar: "مدينة دبي للإنترنت",
    },
  },
  {
    value: "Dubai Investments Park",
    translation: {
      en: "Dubai Investments Park",
      ar: "مجمع دبي للاستثمار",
    },
  },
  {
    value: "Dubai Mall",
    translation: {
      en: "Dubai Mall",
      ar: "مول دبي",
    },
  },
  {
    value: "Dubai Marina",
    translation: {
      en: "Dubai Marina",
      ar: "دبي مارينا",
    },
  },
  {
    value: "Dubai Maritime City",
    translation: {
      en: "Dubai Maritime City",
      ar: "مدينة دبي مارينا",
    },
  },
  {
    value: "Dubai Media City",
    translation: {
      en: "Dubai Media City",
      ar: "مدينة دبي للإعلام",
    },
  },
  {
    value: "Dubai Outlet Mall",
    translation: {
      en: "Dubai Outlet Mall",
      ar: "دبي أوتلت مول",
    },
  },
  {
    value: "Dubai Production City",
    translation: {
      en: "Dubai Production City",
      ar: "مدينة دبي للإنتاج الإعلامي",
    },
  },
  {
    value: "Dubai Silicon Oasis",
    translation: {
      en: "Dubai Silicon Oasis",
      ar: "دبي واحة السيليكون",
    },
  },
  {
    value: "Dubai World Central",
    translation: {
      en: "Dubai World Central",
      ar: "مركز دبي العالمي",
    },
  },
  {
    value: "Dubai World Trade Centre",
    translation: {
      en: "Dubai World Trade Centre",
      ar: "مركز دبي التجاري العالمي",
    },
  },
  {
    value: "Emaar Business Park",
    translation: {
      en: "Emaar Business Park",
      ar: "مجمع إعمار للأعمال",
    },
  },
  {
    value: "Emirates Hills",
    translation: {
      en: "Emirates Hills",
      ar: "تلال الإمارات",
    },
  },
  {
    value: "Festival City",
    translation: {
      en: "Festival City",
      ar: "فيستيفال سيتي",
    },
  },
  {
    value: "Garhoud",
    translation: {
      en: "Garhoud",
      ar: "القرهود",
    },
  },
  {
    value: "Global Village",
    translation: {
      en: "Global Village",
      ar: "القرية العالمية",
    },
  },
  {
    value: "Golf City",
    translation: {
      en: "Golf City",
      ar: "مدينة الجولف",
    },
  },
  {
    value: "Greens",
    translation: {
      en: "Greens",
      ar: "جرينز",
    },
  },
  {
    value: "Hamriya",
    translation: {
      en: "Hamriya",
      ar: "الحمرية",
    },
  },
  {
    value: "Hatta",
    translation: {
      en: "Hatta",
      ar: "حتا",
    },
  },
  {
    value: "Hor Al Anz",
    translation: {
      en: "Hor Al Anz",
      ar: "هور العنز",
    },
  },
  {
    value: "Hor Al Anz East",
    translation: {
      en: "Hor Al Anz East",
      ar: "هور العنز شرق",
    },
  },
  {
    value: "Hudaiba",
    translation: {
      en: "Hudaiba",
      ar: "الحضيبة",
    },
  },
  {
    value: "Ibn Battuta Mall",
    translation: {
      en: "Ibn Battuta Mall",
      ar: "مول ابن بطوطة",
    },
  },
  {
    value: "International City",
    translation: {
      en: "International City",
      ar: "المدينة الدولية",
    },
  },
  {
    value: "International City 2",
    translation: {
      en: "International City 2",
      ar: "المدينة الدولية 2",
    },
  },
  {
    value: "Jebel Ali Free Zone",
    translation: {
      en: "Jebel Ali Free Zone",
      ar: "المنطقة الحرة بجبل علي",
    },
  },
  {
    value: "Jebel Ali Free Zone - Jabal Ali 3",
    translation: {
      en: "Jebel Ali Free Zone - Jabal Ali 3",
      ar: "المنطقة الحرة بجبل علي - جبل علي 3",
    },
  },
  {
    value: "Jebel Ali Free Zone - Jebel Ali 2",
    translation: {
      en: "Jebel Ali Free Zone - Jebel Ali 2",
      ar: "المنطقة الحرة بجبل علي - جبل علي 2",
    },
  },
  {
    value: "Jebel Ali Industrial Area",
    translation: {
      en: "Jebel Ali Industrial Area",
      ar: "منطقة جبل علي الصناعية",
    },
  },
  {
    value: "Jumeira Lakes",
    translation: {
      en: "Jumeira Lakes",
      ar: "بحيرات جميرا",
    },
  },
  {
    value: "Jumeirah",
    translation: {
      en: "Jumeirah",
      ar: "جميرا",
    },
  },
  {
    value: "Jumeirah 1",
    translation: {
      en: "Jumeirah 1",
      ar: "جميرا 1",
    },
  },
  {
    value: "Jumeirah 2",
    translation: {
      en: "Jumeirah 2",
      ar: "جميرا 2",
    },
  },
  {
    value: "Jumeirah 3",
    translation: {
      en: "Jumeirah 3",
      ar: "جميرا 3",
    },
  },
  {
    value: "Jumeirah Lake Towers",
    translation: {
      en: "Jumeirah Lake Towers",
      ar: "أبراج بحيرة الجميرا",
    },
  },
  {
    value: "Jumeirah Village",
    translation: {
      en: "Jumeirah Village",
      ar: "قرية جميرا",
    },
  },
  {
    value: "Knowledge Village",
    translation: {
      en: "Knowledge Village",
      ar: "قرية المعرفة",
    },
  },
  {
    value: "Lahbab",
    translation: {
      en: "Lahbab",
      ar: "لهباب",
    },
  },
  {
    value: "Layan",
    translation: {
      en: "Layan",
      ar: "ليان",
    },
  },
  {
    value: "Living Legends",
    translation: {
      en: "Living Legends",
      ar: "ليفينج ليجنز",
    },
  },
  {
    value: "Majan",
    translation: {
      en: "Majan",
      ar: "مجان",
    },
  },
  {
    value: "Mall Of The Emirates",
    translation: {
      en: "Mall Of The Emirates",
      ar: "مول الإمارات",
    },
  },
  {
    value: "Marina Mall",
    translation: {
      en: "Marina Mall",
      ar: "مول مارينا",
    },
  },
  {
    value: "Meadows",
    translation: {
      en: "Meadows",
      ar: "ميدوز",
    },
  },
  {
    value: "Millenium States Villa",
    translation: {
      en: "Millenium States Villa",
      ar: "فيلات ميلينيوم إستيتس",
    },
  },
  {
    value: "Mirdif",
    translation: {
      en: "Mirdif",
      ar: "مردف",
    },
  },
  {
    value: "Motor City",
    translation: {
      en: "Motor City",
      ar: "موتور سيتي",
    },
  },
  {
    value: "Mudon",
    translation: {
      en: "Mudon",
      ar: "مدن",
    },
  },
  {
    value: "Muhaisanah 1",
    translation: {
      en: "Muhaisanah 1",
      ar: "محيصنة 1",
    },
  },
  {
    value: "Muhaisanah 2",
    translation: {
      en: "Muhaisanah 2",
      ar: "محيصنة 2",
    },
  },
  {
    value: "Muhaisanah 3",
    translation: {
      en: "Muhaisanah 3",
      ar: "محيصنة 3",
    },
  },
  {
    value: "Muhaisanah 4",
    translation: {
      en: "Muhaisanah 4",
      ar: "محيصنة 4",
    },
  },
  {
    value: "Nad Al Sheba",
    translation: {
      en: "Nad Al Sheba",
      ar: "ند الشبا",
    },
  },
  {
    value: "Nad Al Sheba 1",
    translation: {
      en: "Nad Al Sheba 1",
      ar: "ند الشبا 1",
    },
  },
  {
    value: "Nad Al Sheba 2",
    translation: {
      en: "Nad Al Sheba 2",
      ar: "ند الشبا 2",
    },
  },
  {
    value: "Nad Al Sheba 3",
    translation: {
      en: "Nad Al Sheba 3",
      ar: "ند الشبا 3",
    },
  },
  {
    value: "Nad Al Sheba 4",
    translation: {
      en: "Nad Al Sheba 4",
      ar: "ند الشبا 4",
    },
  },
  {
    value: "Nadd Al Hamar",
    translation: {
      en: "Nadd Al Hamar",
      ar: "ند الحمر",
    },
  },
  {
    value: "Nadd Hessa, Silicon Oasis",
    translation: {
      ar: "Nadd Hessa, Silicon Oasis",
      en: "Nadd Hessa, Silicon Oasis",
    },
  },
  {
    value: "Naif",
    translation: {
      en: "Naif",
      ar: "نايف",
    },
  },
  {
    value: "Oud Al Muteena 1",
    translation: {
      en: "Oud Al Muteena 1",
      ar: "عود المطينة 1",
    },
  },
  {
    value: "Oud Al Muteena 2",
    translation: {
      en: "Oud Al Muteena 2",
      ar: "عود المطينة 2",
    },
  },
  {
    value: "Oud Metha",
    translation: {
      en: "Oud Metha",
      ar: "عود ميثاء",
    },
  },
  {
    value: "Palm Jumeirah",
    translation: {
      en: "Palm Jumeirah",
      ar: "نخلة الجميرا",
    },
  },
  {
    value: "Ras Al Khor",
    translation: {
      en: "Ras Al Khor",
      ar: "رأس الخور",
    },
  },
  {
    value: "Ras Al Khor Ind. 1",
    translation: {
      en: "Ras Al Khor Ind. 1",
      ar: "رأس الخور الصناعية 1",
    },
  },
  {
    value: "Ras Al Khor Ind. 3",
    translation: {
      en: "Ras Al Khor Ind. 3",
      ar: "رأس الخور الصناعية 3",
    },
  },
  {
    value: "Remraam",
    translation: {
      en: "Remraam",
      ar: "رمرام",
    },
  },
  {
    value: "Riggat Al Buteen",
    translation: {
      en: "Riggat Al Buteen",
      ar: "رقة البطين",
    },
  },
  {
    value: "Sports City",
    translation: {
      en: "Sports City",
      ar: "المدينة الرياضية",
    },
  },
  {
    value: "Springs",
    translation: {
      en: "Springs",
      ar: "سبرينجز",
    },
  },
  {
    value: "Springs 4",
    translation: {
      en: "Springs 4",
      ar: "سبرينجز 4",
    },
  },
  {
    value: "Sustainable City",
    translation: {
      en: "Sustainable City",
      ar: "المدينة المستدامة",
    },
  },
  {
    value: "The Lakes",
    translation: {
      en: "The Lakes",
      ar: "البحيرات",
    },
  },
  {
    value: "Trade Centre",
    translation: {
      en: "Trade Centre",
      ar: "مركز التجارة",
    },
  },
  {
    value: "Umm Al Sheif",
    translation: {
      en: "Umm Al Sheif",
      ar: "أم الشيف",
    },
  },
  {
    value: "Umm Hurair 1",
    translation: {
      en: "Umm Hurair 1",
      ar: "أم هرير 1",
    },
  },
  {
    value: "Umm Hurair 2",
    translation: {
      en: "Umm Hurair 2",
      ar: "أم هرير 2",
    },
  },
  {
    value: "Umm Ramoul",
    translation: {
      en: "Umm Ramoul",
      ar: "أم الرمول",
    },
  },
  {
    value: "Umm Suqeim 1",
    translation: {
      en: "Umm Suqeim 1",
      ar: "أم سقيم 1",
    },
  },
  {
    value: "Umm Suqeim 2",
    translation: {
      en: "Umm Suqeim 2",
      ar: "أم سقيم 2",
    },
  },
  {
    value: "Umm Suqeim 3",
    translation: {
      en: "Umm Suqeim 3",
      ar: "أم سقيم 3",
    },
  },
  {
    value: "Wadi Al Amardi",
    translation: {
      en: "Wadi Al Amardi",
      ar: "وادي العمردي",
    },
  },
  {
    value: "Wadi Al Safa",
    translation: {
      en: "Wadi Al Safa",
      ar: "وادي الصفا",
    },
  },
  {
    value: "Warsan 2",
    translation: {
      en: "Warsan 2",
      ar: "ورسان 2",
    },
  },
  {
    value: "Warsan 3",
    translation: {
      en: "Warsan 3",
      ar: "ورسان 3",
    },
  },
  {
    value: "Za'abeel 1",
    translation: {
      en: "Za'abeel 1",
      ar: "زعبيل 1",
    },
  },
  {
    value: "Za'abeel 2",
    translation: {
      en: "Za'abeel 2",
      ar: "زعبيل 2",
    },
  },
];

export const fujairahAreas = [
  {
    value: "Abadilah",
    translation: {
      en: "Abadilah",
      ar: "العبادلة",
    },
  },
  {
    value: "Al Aqah",
    translation: {
      en: "Al Aqah",
      ar: "العقة",
    },
  },
  {
    value: "Al Bidya",
    translation: {
      en: "Al Bidya",
      ar: "البدية",
    },
  },
  {
    value: "Al Bithnah",
    translation: {
      en: "Al Bithnah",
      ar: "البثنة",
    },
  },
  {
    value: "Al Halah",
    translation: {
      en: "Al Halah",
      ar: "الحلاه",
    },
  },
  {
    value: "Al Hayl",
    translation: {
      en: "Al Hayl",
      ar: "الحيل",
    },
  },
  {
    value: "Al Ittihad",
    translation: {
      en: "Al Ittihad",
      ar: "الاتحاد",
    },
  },
  {
    value: "Al Owaid",
    translation: {
      en: "Al Owaid",
      ar: "العويد",
    },
  },
  {
    value: "Al Qurayya",
    translation: {
      en: "Al Qurayya",
      ar: "القرية",
    },
  },
  {
    value: "Anajaimat - Dibba Rd",
    translation: {
      en: "Anajaimat - Dibba Rd",
      ar: "النجيمات - طريق دبا",
    },
  },
  {
    value: "City Centre Fujairah",
    translation: {
      en: "City Centre Fujairah",
      ar: "سيتي سنتر الفجيرة",
    },
  },
  {
    value: "Dahir",
    translation: {
      en: "Dahir",
      ar: "ضاهر",
    },
  },
  {
    value: "Dhadna",
    translation: {
      en: "Dhadna",
      ar: "ضدنا",
    },
  },
  {
    value: "Dibba",
    translation: {
      en: "Dibba",
      ar: "دبا",
    },
  },
  {
    value: "Dibba Al Fujairah",
    translation: {
      en: "Dibba Al Fujairah",
      ar: "دبا الفجيرة",
    },
  },
  {
    value: "Dibba Al Hisn",
    translation: {
      en: "Dibba Al Hisn",
      ar: "دبا الحصن",
    },
  },
  {
    value: "Dibba Beach",
    translation: {
      en: "Dibba Beach",
      ar: "شاطئ دبا",
    },
  },
  {
    value: "Fujairah City Outer",
    translation: {
      en: "Fujairah City Outer",
      ar: "ضواحي مدينة الفجيرة",
    },
  },
  {
    value: "Fujairah Free Zone Authority",
    translation: {
      en: "Fujairah Free Zone Authority",
      ar: "هيئة المنطقة الحرة بالفجيرة",
    },
  },
  {
    value: "Fujairah Hospital",
    translation: {
      en: "Fujairah Hospital",
      ar: "مستشفى الفجيرة",
    },
  },
  {
    value: "Fujairah International Airport",
    translation: {
      en: "Fujairah International Airport",
      ar: "مطار الفجيرة الدولي",
    },
  },
  {
    value: "Fujairah Municipality",
    translation: {
      en: "Fujairah Municipality",
      ar: "بلدية الفجيرة",
    },
  },
  {
    value: "Fujairah Port",
    translation: {
      en: "Fujairah Port",
      ar: "ميناء الفجيرة",
    },
  },
  {
    value: "Ghub",
    translation: {
      en: "Ghub",
      ar: "الغوب",
    },
  },
  {
    value: "Hatin",
    translation: {
      en: "Hatin",
      ar: "حطين",
    },
  },
  {
    value: "Industrial Area",
    translation: {
      en: "Industrial Area",
      ar: "المنطقة الصناعية",
    },
  },
  {
    value: "Jabal Safad",
    translation: {
      en: "Jabal Safad",
      ar: "جبل صفد",
    },
  },
  {
    value: "Jabal Umm Al-farfar",
    translation: {
      en: "Jabal Umm Al-farfar",
      ar: "جبل أم الفرفار",
    },
  },
  {
    value: "Kalba",
    translation: {
      en: "Kalba",
      ar: "كلباء",
    },
  },
  {
    value: "M-14",
    translation: {
      en: "M-14",
      ar: "منطقة M-14",
    },
  },
  {
    value: "Madhab",
    translation: {
      en: "Madhab",
      ar: "مذهب",
    },
  },
  {
    value: "Merashid",
    translation: {
      en: "Merashid",
      ar: "مرشيد",
    },
  },
  {
    value: "Mirbah",
    translation: {
      en: "Mirbah",
      ar: "مرباح",
    },
  },
  {
    value: "Murbah Beach",
    translation: {
      en: "Murbah Beach",
      ar: "شاطئ مرباح",
    },
  },
  {
    value: "Qidfa",
    translation: {
      en: "Qidfa",
      ar: "قدفع",
    },
  },
  {
    value: "Sakamkam",
    translation: {
      en: "Sakamkam",
      ar: "سكمكم",
    },
  },
  {
    value: "Sharm",
    translation: {
      en: "Sharm",
      ar: "شرم",
    },
  },
  {
    value: "Shawka",
    translation: {
      en: "Shawka",
      ar: "شوكة",
    },
  },
  {
    value: "Suwayfah",
    translation: {
      en: "Suwayfah",
      ar: "الصويفة",
    },
  },
  {
    value: "Wadi Al Wurayah",
    translation: {
      en: "Wadi Al Wurayah",
      ar: "وادي الوريعة",
    },
  },
  {
    value: "Wadi Helo",
    translation: {
      en: "Wadi Helo",
      ar: "وادي الحلو",
    },
  },
  {
    value: "Zubara",
    translation: {
      en: "Zubara",
      ar: "الزبارة",
    },
  },
];

export const rasAlKhaimahAreas = [
  {
    value: "Adhen Village",
    translation: {
      en: "Adhen Village",
      ar: "قرية عدن",
    },
  },
  {
    value: "Al Darbi Janiyah",
    translation: {
      en: "Al Darbi Janiyah",
      ar: "الدربي جانية",
    },
  },
  {
    value: "Al Dhait",
    translation: {
      en: "Al Dhait",
      ar: "الظيت",
    },
  },
  {
    value: "Al Dhait North",
    translation: {
      en: "Al Dhait North",
      ar: "الظيت الشمالي",
    },
  },
  {
    value: "Al Dhait South",
    translation: {
      en: "Al Dhait South",
      ar: "الظيت الجنوبي",
    },
  },
  {
    value: "Al Fahalain",
    translation: {
      en: "Al Fahalain",
      ar: "الفحلين",
    },
  },
  {
    value: "Al Ghail",
    translation: {
      en: "Al Ghail",
      ar: "الغيل",
    },
  },
  {
    value: "Al Ghubb",
    translation: {
      en: "Al Ghubb",
      ar: "الغب",
    },
  },
  {
    value: "Al Hamra Village",
    translation: {
      en: "Al Hamra Village",
      ar: "قرية الحمرا",
    },
  },
  {
    value: "Al Hudaibah",
    translation: {
      en: "Al Hudaibah",
      ar: "الحضيبة",
    },
  },
  {
    value: "Al Jazirah Al Hamra",
    translation: {
      en: "Al Jazirah Al Hamra",
      ar: "الجزيرة الحمراء",
    },
  },
  {
    value: "Al Jeer",
    translation: {
      en: "Al Jeer",
      ar: "الجير",
    },
  },
  {
    value: "Al Juwais",
    translation: {
      en: "Al Juwais",
      ar: "الجويس",
    },
  },
  {
    value: "Al Kharran",
    translation: {
      en: "Al Kharran",
      ar: "الخران",
    },
  },
  {
    value: "Al Mairid",
    translation: {
      en: "Al Mairid",
      ar: "المعيريض",
    },
  },
  {
    value: "Al Mamourah",
    translation: {
      en: "Al Mamourah",
      ar: "المعمورة",
    },
  },
  {
    value: "Al Nadiyah",
    translation: {
      en: "Al Nadiyah",
      ar: "الندية",
    },
  },
  {
    value: "Al Nakheel",
    translation: {
      en: "Al Nakheel",
      ar: "النخيل",
    },
  },
  {
    value: "Al Nudood",
    translation: {
      en: "Al Nudood",
      ar: "الندود",
    },
  },
  {
    value: "Al Qurm",
    translation: {
      en: "Al Qurm",
      ar: "القرم",
    },
  },
  {
    value: "Al Qusaidat",
    translation: {
      en: "Al Qusaidat",
      ar: "القسيدات",
    },
  },
  {
    value: "Al Rams",
    translation: {
      en: "Al Rams",
      ar: "الرمس",
    },
  },
  {
    value: "Al Riffa",
    translation: {
      en: "Al Riffa",
      ar: "الرفاع",
    },
  },
  {
    value: "Al Sall",
    translation: {
      en: "Al Sall",
      ar: "السل",
    },
  },
  {
    value: "Al Seer",
    translation: {
      en: "Al Seer",
      ar: "الصير",
    },
  },
  {
    value: "Al Sharisha, Al Mataf",
    translation: {
      ar: "Al Sharisha, Al Mataf",
      en: "Al Sharisha, Al Mataf",
    },
  },
  {
    value: "Al Soor",
    translation: {
      en: "Al Soor",
      ar: "السور",
    },
  },
  {
    value: "Al Uraibi, Al Manara",
    translation: {
      ar: "Al Uraibi, Al Manara",
      en: "Al Uraibi, Al Manara",
    },
  },
  {
    value: "Al-turfa",
    translation: {
      en: "Al-turfa",
      ar: "الترفة",
    },
  },
  {
    value: "Awafi",
    translation: {
      en: "Awafi",
      ar: "عوافي",
    },
  },
  {
    value: "Baqal",
    translation: {
      en: "Baqal",
      ar: "بقال",
    },
  },
  {
    value: "Bin Majid Beach Hotel",
    translation: {
      en: "Bin Majid Beach Hotel",
      ar: "فندق بن ماجد بيتش",
    },
  },
  {
    value: "Dafan Al Khor",
    translation: {
      en: "Dafan Al Khor",
      ar: "دفن الخور",
    },
  },
  {
    value: "Dahan",
    translation: {
      en: "Dahan",
      ar: "دهان",
    },
  },
  {
    value: "Ghalilah",
    translation: {
      en: "Ghalilah",
      ar: "غليلة",
    },
  },
  {
    value: "Hamra - Rak",
    translation: {
      en: "Hamra - Rak",
      ar: "الحمرا - راك",
    },
  },
  {
    value: "Hamraniyah",
    translation: {
      en: "Hamraniyah",
      ar: "الحمرانية",
    },
  },
  {
    value: "Jebel Jais Mountain",
    translation: {
      en: "Jebel Jais Mountain",
      ar: "جبل جيس",
    },
  },
  {
    value: "Julphar",
    translation: {
      en: "Julphar",
      ar: "جلفار",
    },
  },
  {
    value: "Khatt",
    translation: {
      en: "Khatt",
      ar: "خت",
    },
  },
  {
    value: "Khorkhuwair",
    translation: {
      en: "Khorkhuwair",
      ar: "خور خوير",
    },
  },
  {
    value: "Khuzam",
    translation: {
      en: "Khuzam",
      ar: "خزام",
    },
  },
  {
    value: "Liwa Badr",
    translation: {
      en: "Liwa Badr",
      ar: "لواء بدر",
    },
  },
  {
    value: "Marjan Island",
    translation: {
      en: "Marjan Island",
      ar: "جزيرة مرجان",
    },
  },
  {
    value: "Masafi",
    translation: {
      en: "Masafi",
      ar: "مسافي",
    },
  },
  {
    value: "Mina Al Arab",
    translation: {
      en: "Mina Al Arab",
      ar: "ميناء العرب",
    },
  },
  {
    value: "Ras Al Khaima Old City",
    translation: {
      en: "Ras Al Khaima Old City",
      ar: "مدينة رأس الخيمة القديمة",
    },
  },
  {
    value: "Ras Al Khaimah Economic Zone",
    translation: {
      en: "Ras Al Khaimah Economic Zone",
      ar: "منطقة رأس الخيمة الاقتصادية",
    },
  },
  {
    value: "Ras Al Khaimah International Airport",
    translation: {
      en: "Ras Al Khaimah International Airport",
      ar: "مطار رأس الخيمة الدولي",
    },
  },
  {
    value: "Ras Al Selaab",
    translation: {
      en: "Ras Al Selaab",
      ar: "رأس السلاب",
    },
  },
  {
    value: "Seih Al Burairat",
    translation: {
      en: "Seih Al Burairat",
      ar: "سيح البريرات",
    },
  },
  {
    value: "Seih Al Ghubb",
    translation: {
      en: "Seih Al Ghubb",
      ar: "سيح الغب",
    },
  },
  {
    value: "Seih Al Hudaibah",
    translation: {
      en: "Seih Al Hudaibah",
      ar: "سيح الحضيبة",
    },
  },
  {
    value: "Seih Shihyar",
    translation: {
      en: "Seih Shihyar",
      ar: "سيح شهيار",
    },
  },
  {
    value: "Sha'am",
    translation: {
      en: "Sha'am",
      ar: "شعم",
    },
  },
  {
    value: "Shamal Haqueel",
    translation: {
      en: "Shamal Haqueel",
      ar: "شمال حقيل",
    },
  },
  {
    value: "Shawka",
    translation: {
      en: "Shawka",
      ar: "شوكة",
    },
  },
  {
    value: "Sidroh",
    translation: {
      en: "Sidroh",
      ar: "سدروه",
    },
  },
  {
    value: "Sidroh 2",
    translation: {
      en: "Sidroh 2",
      ar: "سدروه 2",
    },
  },
  {
    value: "Siji",
    translation: {
      en: "Siji",
      ar: "سيجي",
    },
  },
  {
    value: "Suhaim",
    translation: {
      en: "Suhaim",
      ar: "سهيم",
    },
  },
  {
    value: "Wadi Bih Area",
    translation: {
      en: "Wadi Bih Area",
      ar: "منطقة وادي بيه",
    },
  },
];

export const sharjahAreas = [
  {
    value: "Abu Shagara",
    translation: {
      en: "Abu Shagara",
      ar: "بوشغارة",
    },
  },
  {
    value: "Agricultural Falah",
    translation: {
      en: "Agricultural Falah",
      ar: "الفلاح الزراعية",
    },
  },
  {
    value: "Al Abar",
    translation: {
      en: "Al Abar",
      ar: "العبار",
    },
  },
  {
    value: "Al Atain",
    translation: {
      en: "Al Atain",
      ar: "العطين",
    },
  },
  {
    value: "Al Azra",
    translation: {
      en: "Al Azra",
      ar: "العزرة",
    },
  },
  {
    value: "Al Batayeh",
    translation: {
      en: "Al Batayeh",
      ar: "البطائح",
    },
  },
  {
    value: "Al Bu Daniq",
    translation: {
      en: "Al Bu Daniq",
      ar: "بودانق",
    },
  },
  {
    value: "Al Dhaid",
    translation: {
      en: "Al Dhaid",
      ar: "الذيد",
    },
  },
  {
    value: "Al Falah Suburb",
    translation: {
      en: "Al Falah Suburb",
      ar: "ضاحية الفلاح",
    },
  },
  {
    value: "Al Falaj",
    translation: {
      en: "Al Falaj",
      ar: "الفلج",
    },
  },
  {
    value: "Al Fayha",
    translation: {
      en: "Al Fayha",
      ar: "الفيحاء",
    },
  },
  {
    value: "Al Fisht",
    translation: {
      en: "Al Fisht",
      ar: "الفشت",
    },
  },
  {
    value: "Al Ghafia",
    translation: {
      en: "Al Ghafia",
      ar: "الغافية",
    },
  },
  {
    value: "Al Gharb",
    translation: {
      en: "Al Gharb",
      ar: "الغرب",
    },
  },
  {
    value: "Al Ghubaiba",
    translation: {
      en: "Al Ghubaiba",
      ar: "الغبيبة",
    },
  },
  {
    value: "Al Ghuwair",
    translation: {
      en: "Al Ghuwair",
      ar: "الغوير",
    },
  },
  {
    value: "Al Goaz",
    translation: {
      en: "Al Goaz",
      ar: "القوز",
    },
  },
  {
    value: "Al Hamriyah",
    translation: {
      en: "Al Hamriyah",
      ar: "الحمرية",
    },
  },
  {
    value: "Al Hazannah",
    translation: {
      en: "Al Hazannah",
      ar: "الحزانة",
    },
  },
  {
    value: "Al Heerah Suburb",
    translation: {
      en: "Al Heerah Suburb",
      ar: "ضاحية الحيرة",
    },
  },
  {
    value: "Al Houmah",
    translation: {
      en: "Al Houmah",
      ar: "الحومة",
    },
  },
  {
    value: "Al Jazzat",
    translation: {
      en: "Al Jazzat",
      ar: "الجزات",
    },
  },
  {
    value: "Al Jubail",
    translation: {
      en: "Al Jubail",
      ar: "الجبيل",
    },
  },
  {
    value: "Al Jurainah 1",
    translation: {
      en: "Al Jurainah 1",
      ar: "الجرينة 1",
    },
  },
  {
    value: "Al Jurainah 2",
    translation: {
      en: "Al Jurainah 2",
      ar: "الجرينة 2",
    },
  },
  {
    value: "Al Jurainah 3",
    translation: {
      en: "Al Jurainah 3",
      ar: "الجرينة 3",
    },
  },
  {
    value: "Al Jurainah 4",
    translation: {
      en: "Al Jurainah 4",
      ar: "الجرينة 4",
    },
  },
  {
    value: "Al Khaledia Suburb",
    translation: {
      en: "Al Khaledia Suburb",
      ar: "ضاحية الخالدية",
    },
  },
  {
    value: "Al Khan",
    translation: {
      en: "Al Khan",
      ar: "الخان",
    },
  },
  {
    value: "Al Khezamia",
    translation: {
      en: "Al Khezamia",
      ar: "الخزامية",
    },
  },
  {
    value: "Al Layyeh Suburb",
    translation: {
      en: "Al Layyeh Suburb",
      ar: "ضاحية اللية",
    },
  },
  {
    value: "Al Madam",
    translation: {
      en: "Al Madam",
      ar: "المدام",
    },
  },
  {
    value: "Al Majarrah",
    translation: {
      en: "Al Majarrah",
      ar: "المجرة",
    },
  },
  {
    value: "Al Majaz 1",
    translation: {
      en: "Al Majaz 1",
      ar: "المجاز 1",
    },
  },
  {
    value: "Al Majaz 2",
    translation: {
      en: "Al Majaz 2",
      ar: "المجاز 2",
    },
  },
  {
    value: "Al Majaz 3",
    translation: {
      en: "Al Majaz 3",
      ar: "المجاز 3",
    },
  },
  {
    value: "Al Mamzar",
    translation: {
      en: "Al Mamzar",
      ar: "الممزر",
    },
  },
  {
    value: "Al Manakh",
    translation: {
      en: "Al Manakh",
      ar: "المناخ",
    },
  },
  {
    value: "Al Mareija",
    translation: {
      en: "Al Mareija",
      ar: "المريجة",
    },
  },
  {
    value: "Al Meena Street",
    translation: {
      en: "Al Meena Street",
      ar: "شارع الميناء",
    },
  },
  {
    value: "Al Mirgab",
    translation: {
      en: "Al Mirgab",
      ar: "المرقاب",
    },
  },
  {
    value: "Al Musalla",
    translation: {
      en: "Al Musalla",
      ar: "المصلى",
    },
  },
  {
    value: "Al Nahda",
    translation: {
      en: "Al Nahda",
      ar: "النهضة",
    },
  },
  {
    value: "Al Nasserya",
    translation: {
      en: "Al Nasserya",
      ar: "الناصرية",
    },
  },
  {
    value: "Al Nekhailat",
    translation: {
      en: "Al Nekhailat",
      ar: "النخيلات",
    },
  },
  {
    value: "Al Noof 1",
    translation: {
      en: "Al Noof 1",
      ar: "النوف 1",
    },
  },
  {
    value: "Al Noof 2",
    translation: {
      en: "Al Noof 2",
      ar: "النوف 2",
    },
  },
  {
    value: "Al Noof 3",
    translation: {
      en: "Al Noof 3",
      ar: "النوف 3",
    },
  },
  {
    value: "Al Noof 4",
    translation: {
      en: "Al Noof 4",
      ar: "النوف 4",
    },
  },
  {
    value: "Al Nuaimia 1",
    translation: {
      en: "Al Nuaimia 1",
      ar: "النعيمية 1",
    },
  },
  {
    value: "Al Nud",
    translation: {
      en: "Al Nud",
      ar: "الند",
    },
  },
  {
    value: "Al Qadisia",
    translation: {
      en: "Al Qadisia",
      ar: "القادسية",
    },
  },
  {
    value: "Al Qasimia",
    translation: {
      en: "Al Qasimia",
      ar: "القاسمية",
    },
  },
  {
    value: "Al Qulaya'ah",
    translation: {
      en: "Al Qulaya'ah",
      ar: "القليعة",
    },
  },
  {
    value: "Al Rahmaniya",
    translation: {
      en: "Al Rahmaniya",
      ar: "الرحمانية",
    },
  },
  {
    value: "Al Rahmaniya 1",
    translation: {
      en: "Al Rahmaniya 1",
      ar: "الرحمانية 1",
    },
  },
  {
    value: "Al Rahmaniya 2",
    translation: {
      en: "Al Rahmaniya 2",
      ar: "الرحمانية 2",
    },
  },
  {
    value: "Al Rahmaniya 3",
    translation: {
      en: "Al Rahmaniya 3",
      ar: "الرحمانية 3",
    },
  },
  {
    value: "Al Rahmaniya 4",
    translation: {
      en: "Al Rahmaniya 4",
      ar: "الرحمانية 4",
    },
  },
  {
    value: "Al Rahmaniya 5",
    translation: {
      en: "Al Rahmaniya 5",
      ar: "الرحمانية 5",
    },
  },
  {
    value: "Al Rahmaniya 6",
    translation: {
      en: "Al Rahmaniya 6",
      ar: "الرحمانية 6",
    },
  },
  {
    value: "Al Rahmaniya 7",
    translation: {
      en: "Al Rahmaniya 7",
      ar: "الرحمانية 7",
    },
  },
  {
    value: "Al Rahmaniya 8",
    translation: {
      en: "Al Rahmaniya 8",
      ar: "الرحمانية 8",
    },
  },
  {
    value: "Al Ramaqiya",
    translation: {
      en: "Al Ramaqiya",
      ar: "الرماقية",
    },
  },
  {
    value: "Al Ramla - East",
    translation: {
      en: "Al Ramla - East",
      ar: "الرملة - شرق",
    },
  },
  {
    value: "Al Ramla - West",
    translation: {
      en: "Al Ramla - West",
      ar: "الرملة – غرب",
    },
  },
  {
    value: "Al Ramtha",
    translation: {
      en: "Al Ramtha",
      ar: "الرمثا",
    },
  },
  {
    value: "Al Rifa'a",
    translation: {
      en: "Al Rifa'a",
      ar: "الرفاعة",
    },
  },
  {
    value: "Al Rifa'ah",
    translation: {
      en: "Al Rifa'ah",
      ar: "الرفاعة",
    },
  },
  {
    value: "Al Riqa Suburb",
    translation: {
      en: "Al Riqa Suburb",
      ar: "ضاحية الرقة",
    },
  },
  {
    value: "Al Sabkha",
    translation: {
      en: "Al Sabkha",
      ar: "السبخة",
    },
  },
  {
    value: "Al Seef - Sharjah",
    translation: {
      en: "Al Seef - Sharjah",
      ar: "السيف - الشارقة",
    },
  },
  {
    value: "Al Shahba",
    translation: {
      en: "Al Shahba",
      ar: "الشهباء",
    },
  },
  {
    value: "Al Sharq",
    translation: {
      en: "Al Sharq",
      ar: "الشرق",
    },
  },
  {
    value: "Al Shuwaihean",
    translation: {
      en: "Al Shuwaihean",
      ar: "الشويهين",
    },
  },
  {
    value: "Al Soor",
    translation: {
      en: "Al Soor",
      ar: "السور",
    },
  },
  {
    value: "Al Suyoh Suburb",
    translation: {
      en: "Al Suyoh Suburb",
      ar: "ضاحية السيوح",
    },
  },
  {
    value: "Al Sweihat",
    translation: {
      en: "Al Sweihat",
      ar: "السويحات",
    },
  },
  {
    value: "Al Taawun",
    translation: {
      en: "Al Taawun",
      ar: "التعاون",
    },
  },
  {
    value: "Al Tala'a",
    translation: {
      en: "Al Tala'a",
      ar: "الطلاع",
    },
  },
  {
    value: "Al Wasit Area",
    translation: {
      en: "Al Wasit Area",
      ar: "منطقة الواسط",
    },
  },
  {
    value: "Al Yarmook",
    translation: {
      en: "Al Yarmook",
      ar: "اليرموك",
    },
  },
  {
    value: "Al Yash",
    translation: {
      en: "Al Yash",
      ar: "الياش",
    },
  },
  {
    value: "Al Zubair Orchards",
    translation: {
      en: "Al Zubair Orchards",
      ar: "بساتين الزبير",
    },
  },
  {
    value: "American University of Sharjah",
    translation: {
      en: "American University of Sharjah",
      ar: "الجامعة الأمريكية بالشارقة",
    },
  },
  {
    value: "Barashi",
    translation: {
      en: "Barashi",
      ar: "البراشي",
    },
  },
  {
    value: "Bu Tina",
    translation: {
      en: "Bu Tina",
      ar: "بوطينة",
    },
  },
  {
    value: "Buheira Corniche",
    translation: {
      en: "Buheira Corniche",
      ar: "كورنيش البحيرة",
    },
  },
  {
    value: "City Center -shj",
    translation: {
      en: "City Center -shj",
      ar: "سيتي سنتر - الشارقة",
    },
  },
  {
    value: "Cricket Stadium Area",
    translation: {
      en: "Cricket Stadium Area",
      ar: "منطقة ملعب الكريكيت",
    },
  },
  {
    value: "Darari",
    translation: {
      en: "Darari",
      ar: "دراري",
    },
  },
  {
    value: "Dasman",
    translation: {
      en: "Dasman",
      ar: "دسمان",
    },
  },
  {
    value: "Flag Island",
    translation: {
      en: "Flag Island",
      ar: "جزيرة العلم",
    },
  },
  {
    value: "Gold Souq-new",
    translation: {
      en: "Gold Souq-new",
      ar: "سوق الذهب-الجديد",
    },
  },
  {
    value: "Gold Souq-old",
    translation: {
      en: "Gold Souq-old",
      ar: "سوق الذهب القديم",
    },
  },
  {
    value: "Halwan Suburb",
    translation: {
      en: "Halwan Suburb",
      ar: "ضاحية حلوان",
    },
  },
  {
    value: "Hamriya Free Zone",
    translation: {
      en: "Hamriya Free Zone",
      ar: "المنطقة الحرة بالحمرية",
    },
  },
  {
    value: "Hayawa",
    translation: {
      en: "Hayawa",
      ar: "حياوة",
    },
  },
  {
    value: "Industrial Area 1",
    translation: {
      en: "Industrial Area 1",
      ar: "المنطقة الصناعية 1",
    },
  },
  {
    value: "Industrial Area 11",
    translation: {
      en: "Industrial Area 11",
      ar: "المنطقة الصناعية 11",
    },
  },
  {
    value: "Industrial Area 13",
    translation: {
      en: "Industrial Area 13",
      ar: "المنطقة الصناعية 13",
    },
  },
  {
    value: "Industrial Area 15",
    translation: {
      en: "Industrial Area 15",
      ar: "المنطقة الصناعية 15",
    },
  },
  {
    value: "Industrial Area 2",
    translation: {
      en: "Industrial Area 2",
      ar: "المنطقة الصناعية 2",
    },
  },
  {
    value: "Industrial Area 4",
    translation: {
      en: "Industrial Area 4",
      ar: "المنطقة الصناعية 4",
    },
  },
  {
    value: "Industrial Area 5",
    translation: {
      en: "Industrial Area 5",
      ar: "المنطقة الصناعية 5",
    },
  },
  {
    value: "Industrial Area 6",
    translation: {
      en: "Industrial Area 6",
      ar: "المنطقة الصناعية 6",
    },
  },
  {
    value: "Industrial Area 8",
    translation: {
      en: "Industrial Area 8",
      ar: "المنطقة الصناعية 8",
    },
  },
  {
    value: "Industrial Area 9",
    translation: {
      en: "Industrial Area 9",
      ar: "المنطقة الصناعية 9",
    },
  },
  {
    value: "Khalid Port",
    translation: {
      en: "Khalid Port",
      ar: "ميناء خالد",
    },
  },
  {
    value: "Khor Fakkan",
    translation: {
      en: "Khor Fakkan",
      ar: "خورفكان",
    },
  },
  {
    value: "Luluyah Beach",
    translation: {
      en: "Luluyah Beach",
      ar: "شاطئ لولويا",
    },
  },
  {
    value: "Majaz",
    translation: {
      en: "Majaz",
      ar: "مجاز",
    },
  },
  {
    value: "Maleha",
    translation: {
      en: "Maleha",
      ar: "مليحة",
    },
  },
  {
    value: "Mansoura",
    translation: {
      en: "Mansoura",
      ar: "المنصورة",
    },
  },
  {
    value: "Maysaloon",
    translation: {
      en: "Maysaloon",
      ar: "ميسلون",
    },
  },
  {
    value: "Muwafjah",
    translation: {
      en: "Muwafjah",
      ar: "موافجة",
    },
  },
  {
    value: "Muwaileh Commercial",
    translation: {
      en: "Muwaileh Commercial",
      ar: "تجارية مويلح",
    },
  },
  {
    value: "Muwailih",
    translation: {
      en: "Muwailih",
      ar: "مويلح",
    },
  },
  {
    value: "Muzairah",
    translation: {
      en: "Muzairah",
      ar: "مزيرعة",
    },
  },
  {
    value: "Qarayen",
    translation: {
      en: "Qarayen",
      ar: "قراين",
    },
  },
  {
    value: "Qarayen 1",
    translation: {
      en: "Qarayen 1",
      ar: "قراين 1",
    },
  },
  {
    value: "Qarayen 2",
    translation: {
      en: "Qarayen 2",
      ar: "قراين 2",
    },
  },
  {
    value: "Qarayen 3",
    translation: {
      en: "Qarayen 3",
      ar: "قراين 3",
    },
  },
  {
    value: "Qarayen 4",
    translation: {
      en: "Qarayen 4",
      ar: "قراين 4",
    },
  },
  {
    value: "Qarayen 5",
    translation: {
      en: "Qarayen 5",
      ar: "قراين 5",
    },
  },
  {
    value: "Qasmiya Hospital",
    translation: {
      en: "Qasmiya Hospital",
      ar: "مستشفى القاسمية",
    },
  },
  {
    value: "Rolla",
    translation: {
      en: "Rolla",
      ar: "رولا",
    },
  },
  {
    value: "Sahara Centre",
    translation: {
      en: "Sahara Centre",
      ar: "مركز صحارى",
    },
  },
  {
    value: "Samnan",
    translation: {
      en: "Samnan",
      ar: "سمنان",
    },
  },
  {
    value: "Sharjah Airport Freezone",
    translation: {
      en: "Sharjah Airport Freezone",
      ar: "المنطقة الحرة بمطار الشارقة",
    },
  },
  {
    value: "Sharjah Clock Tower",
    translation: {
      en: "Sharjah Clock Tower",
      ar: "برج الساعة بالشارقة",
    },
  },
  {
    value: "Sharjah Industrial",
    translation: {
      en: "Sharjah Industrial",
      ar: "الشارقة الصناعية",
    },
  },
  {
    value: "Sharjah Industrial Area 10",
    translation: {
      en: "Sharjah Industrial Area 10",
      ar: "المنطقة الصناعية بالشارقة 10",
    },
  },
  {
    value: "Sharjah Industrial Area 12",
    translation: {
      en: "Sharjah Industrial Area 12",
      ar: "المنطقة الصناعية بالشارقة 12",
    },
  },
  {
    value: "Sharjah Industrial Area 3",
    translation: {
      en: "Sharjah Industrial Area 3",
      ar: "المنطقة الصناعية بالشارقة 3",
    },
  },
  {
    value: "Sharjah Industrial Area 7",
    translation: {
      en: "Sharjah Industrial Area 7",
      ar: "المنطقة الصناعية بالشارقة 7",
    },
  },
  {
    value: "Sharjah International Airport",
    translation: {
      en: "Sharjah International Airport",
      ar: "مطار الشارقة الدولي",
    },
  },
  {
    value: "Sharjah Sports Club",
    translation: {
      en: "Sharjah Sports Club",
      ar: "نادي الشارقة الرياضي",
    },
  },
  {
    value: "Sharqan",
    translation: {
      en: "Sharqan",
      ar: "شرقان",
    },
  },
  {
    value: "Souq Al Jubail",
    translation: {
      en: "Souq Al Jubail",
      ar: "سوق الجبيل",
    },
  },
  {
    value: "The Sharjah Equestrian & Racing Club",
    translation: {
      en: "The Sharjah Equestrian & Racing Club",
      ar: "نادي الشارقة للفروسية والسباق",
    },
  },
  {
    value: "Um Altaraffa",
    translation: {
      en: "Um Altaraffa",
      ar: "أم الطرافة",
    },
  },
  {
    value: "Um Fannain",
    translation: {
      en: "Um Fannain",
      ar: "أم فنين",
    },
  },
  {
    value: "University City",
    translation: {
      en: "University City",
      ar: "المدينة الجامعية",
    },
  },
  {
    value: "University Of Sharjah",
    translation: {
      en: "University Of Sharjah",
      ar: "جامعة الشارقة",
    },
  },
];

export const ummAlQuwainAreas = [
  {
    value: "Al Aahad",
    translation: {
      en: "Al Aahad",
      ar: "الآحد",
    },
  },
  {
    value: "Al Abrab",
    translation: {
      en: "Al Abrab",
      ar: "الأبراب",
    },
  },
  {
    value: "Al Arabi Sports And Cultural Club",
    translation: {
      en: "Al Arabi Sports And Cultural Club",
      ar: "النادي العربي الرياضي والثقافي",
    },
  },
  {
    value: "Al Dar Al Baida - A",
    translation: {
      en: "Al Dar Al Baida - A",
      ar: "الدار البيضاء - أ",
    },
  },
  {
    value: "Al Dar Al Baida - B",
    translation: {
      en: "Al Dar Al Baida - B",
      ar: "الدار البيضاء - ب",
    },
  },
  {
    value: "Al Haditha",
    translation: {
      en: "Al Haditha",
      ar: "الحديثة",
    },
  },
  {
    value: "Al Hawiyah",
    translation: {
      en: "Al Hawiyah",
      ar: "الحوية",
    },
  },
  {
    value: "Al Humrah",
    translation: {
      en: "Al Humrah",
      ar: "الحمرة",
    },
  },
  {
    value: "Al Humrah - A",
    translation: {
      en: "Al Humrah - A",
      ar: "الحمرة - أ",
    },
  },
  {
    value: "Al Humrah - B",
    translation: {
      en: "Al Humrah - B",
      ar: "الحمرة - ب",
    },
  },
  {
    value: "Al Humrah - C",
    translation: {
      en: "Al Humrah - C",
      ar: "الحمرة - ج",
    },
  },
  {
    value: "Al Humrah - D",
    translation: {
      en: "Al Humrah - D",
      ar: "الحمرة - د",
    },
  },
  {
    value: "Al Khor",
    translation: {
      en: "Al Khor",
      ar: "الخور",
    },
  },
  {
    value: "Al Limghadar",
    translation: {
      en: "Al Limghadar",
      ar: "لمغدر",
    },
  },
  {
    value: "Al Maidan",
    translation: {
      en: "Al Maidan",
      ar: "الميدان",
    },
  },
  {
    value: "Al Raas",
    translation: {
      en: "Al Raas",
      ar: "الراس",
    },
  },
  {
    value: "Al Raas - A",
    translation: {
      en: "Al Raas - A",
      ar: "الراس - أ",
    },
  },
  {
    value: "Al Raas - B",
    translation: {
      en: "Al Raas - B",
      ar: "الراس - ب",
    },
  },
  {
    value: "Al Raas - C",
    translation: {
      en: "Al Raas - C",
      ar: "الراس - ج",
    },
  },
  {
    value: "Al Raas - D",
    translation: {
      en: "Al Raas - D",
      ar: "الراس - د",
    },
  },
  {
    value: "Al Rafaah",
    translation: {
      en: "Al Rafaah",
      ar: "الراعفة",
    },
  },
  {
    value: "Al Ramlah - B",
    translation: {
      en: "Al Ramlah - B",
      ar: "الرملة - ب",
    },
  },
  {
    value: "Al Ramlah - C",
    translation: {
      en: "Al Ramlah - C",
      ar: "الرملة - ج",
    },
  },
  {
    value: "Al Ramlah -a",
    translation: {
      en: "Al Ramlah -a",
      ar: "الرملة - أ",
    },
  },
  {
    value: "Al Raudah",
    translation: {
      en: "Al Raudah",
      ar: "الروضة",
    },
  },
  {
    value: "Al Riqqah",
    translation: {
      en: "Al Riqqah",
      ar: "الرقة",
    },
  },
  {
    value: "Al Salamah - B",
    translation: {
      en: "Al Salamah - B",
      ar: "السلمة - ب",
    },
  },
  {
    value: "Al Salamah - C",
    translation: {
      en: "Al Salamah - C",
      ar: "السلمة - ج",
    },
  },
  {
    value: "Al Salamah A",
    translation: {
      en: "Al Salamah A",
      ar: "السلمة - أ",
    },
  },
  {
    value: "Defence Camp",
    translation: {
      en: "Defence Camp",
      ar: "مخيم الدفاع",
    },
  },
  {
    value: "Falaj Al Mualla",
    translation: {
      en: "Falaj Al Mualla",
      ar: "فلج المعلا",
    },
  },
  {
    value: "Green Belt",
    translation: {
      en: "Green Belt",
      ar: "الحزام الأخضر",
    },
  },
  {
    value: "Masjid Al Mazroui",
    translation: {
      en: "Masjid Al Mazroui",
      ar: "مسجد المزروعي",
    },
  },
  {
    value: "Old Town Area",
    translation: {
      en: "Old Town Area",
      ar: "منطقة المدينة القديمة",
    },
  },
  {
    value: "Sheikh Khalifa General Hospital",
    translation: {
      en: "Sheikh Khalifa General Hospital",
      ar: "مستشفى الشيخ خليفة العام",
    },
  },
  {
    value: "Uaq New Industrial Area",
    translation: {
      en: "Uaq New Industrial Area",
      ar: "المنطقة الصناعية الجديدة بأم القيوين",
    },
  },
  {
    value: "Umm Al Quwain Airpor",
    translation: {
      en: "Umm Al Quwain Airpor",
      ar: "مطار أم القيوين",
    },
  },
  {
    value: "Umm Al Quwain Hospital",
    translation: {
      en: "Umm Al Quwain Hospital",
      ar: "مستشفى أم القيوين",
    },
  },
  {
    value: "Umm Al Quwain Industrial City Authority",
    translation: {
      en: "Umm Al Quwain Industrial City Authority",
      ar: "هيئة مدينة أم القيوين الصناعية",
    },
  },
  {
    value: "Umm Al Quwain Marina",
    translation: {
      en: "Umm Al Quwain Marina",
      ar: "مارينا أم القيوين",
    },
  },
];

export const areEmirates = [
  {
    value: "Abu Dhabi",
    translation: {
      ar: "أبو ظبي",
      en: "Abu Dhabi",
    },
  },
  {
    value: "Ajman",
    translation: {
      ar: "عجمان",
      en: "Ajman",
    },
  },
  {
    value: "Al Ain",
    translation: {
      ar: "العين",
      en: "Al Ain",
    },
  },
  {
    value: "Dubai",
    translation: {
      ar: "دبي",
      en: "Dubai",
    },
  },
  {
    value: "Fujairah",
    translation: {
      ar: "الفجيرة",
      en: "Fujairah",
    },
  },
  {
    value: "Ras Al Khaimah",
    translation: {
      ar: "رأس الخيمة",
      en: "Ras Al Khaimah",
    },
  },
  {
    value: "Sharjah",
    translation: {
      ar: "الشارقة",
      en: "Sharjah",
    },
  },
  {
    value: "Umm Al Quwain",
    translation: {
      ar: "أم القيوين",
      en: "Umm Al Quwain",
    },
  },
] as const satisfies Location[];

export type Emirate = (typeof areEmirates)[number]["value"];

type Translation = {
  ar: string;
  en: string;
};

type Location = {
  value: string;
  translation: Translation;
};

export const areCities = {
  "Abu Dhabi": abuDhabiAreas,
  Ajman: ajmanAreas,
  "Al Ain": alAinAreas,
  Dubai: dubaiAreas,
  Fujairah: fujairahAreas,
  "Ras Al Khaimah": rasAlKhaimahAreas,
  Sharjah: sharjahAreas,
  "Umm Al Quwain": ummAlQuwainAreas,
} as const satisfies Record<Emirate, Location[]>;

export const normaliseLocations = (
  locations: Location[],
  locale: keyof Translation,
) => {
  return locations.map((location) => ({
    value: location.value,
    label: location.translation[locale],
  }));
};
