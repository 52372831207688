import { type FieldValues, FormProvider, useForm } from "react-hook-form";
import { api } from "@lib/client";
import type { Ticket } from "@lib/ticket";
import { useState } from "react";
import { VerificationModal } from "./verification-modal";
import { type Values } from "@lib/utils";
import { useMutation } from "@tanstack/react-query";
import { ajaxHeadersWithCSRFToken } from "@lib/utils";

type LandingFormProps = {
  encodedActivityId: string;
  ticket: Ticket | undefined;
  children: React.ReactNode;
};

export function LandingForm({
  encodedActivityId,
  ticket,
  children,
}: LandingFormProps) {
  const methods = useForm();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formState, setFormState] = useState<FieldValues | null>(null);

  const { mutateAsync } = useMutation({
    mutationFn: async (formData: FieldValues) => {
      const res = await api.validate.$post(
        {
          json: {
            encodedActivityId,
            ticket,
            formData: formData as Values,
          },
        },
        {
          headers: ajaxHeadersWithCSRFToken(),
        },
      );

      return res.json();
    },
    onSuccess: (data) => {
      if (!data.success && "errors" in data) {
        Object.entries(data.errors).forEach(([key, value]) => {
          methods.setError(key, { message: value });
        });
        return;
      }

      if ("redirect" in data) {
        window.location.href = data.redirect;
        return;
      }

      if (data.success && "values" in data) {
        setFormState(data.values);
        setDialogOpen(true);
      }
    },
  });

  async function onSubmit(formData: FieldValues) {
    await mutateAsync(formData);
  }

  return (
    <main suppressHydrationWarning>
      <FormProvider {...methods}>
        <form onSubmit={(e) => void methods.handleSubmit(onSubmit)(e)}>
          {children}
        </form>
      </FormProvider>
      <VerificationModal
        encodedActivityId={encodedActivityId}
        isDialogOpen={Boolean(formState) && dialogOpen}
        onDialogOpenChange={(open) => setDialogOpen(open)}
        formValues={formState}
        ticket={ticket}
      />
    </main>
  );
}
