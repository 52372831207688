"use client";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import type { StarRating as StarRatingProps } from "@lib/config/types";
import { hsla } from "@lib/utils";
import { Star } from "lucide-react";
import React, { type CSSProperties } from "react";
import { useFormContext } from "react-hook-form";

type StarStyles = CSSProperties & {
  "--active-star-color": string;
  "--inactive-star-color": string;
};

export function StarRating(props: StarRatingProps) {
  const { control } = useFormContext();
  const name = `custom-question:${props.internal_id}`;

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem
          style={
            {
              "--active-star-color": hsla(props.active_star_color),
              "--inactive-star-color": hsla(props.inactive_star_color),
            } as StarStyles
          }
        >
          <FormLabel
            htmlFor={field.name}
            className="mb-[0.875rem] block"
            style={{
              fontSize: "var(--label-font-size)",
              fontFamily: "var(--label-font-name, var(--font-family))",
              fontStyle: "var(--label-font-style, var(--font-style))",
              fontWeight: "var(--label-font-weight, var(--font-weight))",
              color: "var(--label-color)",
            }}
            dangerouslySetInnerHTML={{
              __html: props.question ?? "",
            }}
          />

          <div
            className="block [&:not(:empty)]:my-[1.5em]"
            dangerouslySetInnerHTML={{
              __html: props.prompt ?? "",
            }}
            style={{
              fontSize: "var(--prompt-font-size)",
              fontFamily: "var(--prompt-font-name, var(--font-family))",
              fontStyle: "var(--prompt-font-style, var(--font-style))",
              fontWeight: "var(--prompt-font-weight, var(--font-weight))",
              color: "var(--prompt-font-color)",
            }}
          />
          <FormControl>
            <div
              role="radiogroup"
              className="stars flex text-[--inactive-star-color]"
            >
              {Array.from({ length: props.star_rating_number }, (_, i) => {
                const star = i + 1;
                return (
                  <React.Fragment key={star}>
                    <input
                      id={`${name}-${star}`}
                      value={star}
                      type="radio"
                      className="star-input sr-only"
                      checked={String(star) === field.value}
                      onChange={() => field.onChange(String(star))}
                    />
                    <label
                      htmlFor={`${name}-${star}`}
                      className="star-label cursor-pointer transition-all hover:scale-125 pr-3"
                    >
                      <Star
                        size={starSize(props.star_size)}
                        fill="currentColor"
                      />
                    </label>
                  </React.Fragment>
                );
              })}
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function starSize(size: StarRatingProps["star_size"]) {
  switch (size) {
    case "small":
      return 20;
    case "medium":
      return 26;
    case "large":
      return 32;
    default:
      return 26;
  }
}
