import {
  type GenericReview,
  type DoubleOptinPage,
  type GenericLandingPage,
} from "./types";
import { findAllByKey } from "./utils";

type FontProperties = {
  font_weight: string;
  font_style: string;
  font_path: string;
  font_name: string;
};

type FontFile = (typeof allowedFormats)[number];

type FontFormat = ReturnType<typeof fontFormat>;

type Font = FontProperties & {
  format?: FontFormat;
};

type FontValue = { custom_font: string } | { uploaded_font: string };

type CombinedFontValue = Font & FontValue;

const allowedFormats = ["otf", "ttf", "woff", "woff2"] as const;

const googleFonts = [
  "Cookie",
  "Crimson Text",
  "Dancing Script",
  "EB Garamond",
  "Lato",
  "Lora",
  "Merriweather",
  "Montserrat",
  "Nunito",
  "Open Sans",
  "Oswald",
  "Pacifico",
  "Playfair Display",
  "Poppins",
  "Raleway",
  "Roboto",
  "Source Serif Pro",
  "Spectral",
] as const;

export function extractNonWebSafeFonts(
  config: GenericLandingPage | DoubleOptinPage | GenericReview,
) {
  const seen = new Set();
  const fontType = findAllByKey<FontValue>(config, ["font"]);

  const fontProperties = findAllByKey<FontProperties>(config, [
    "font_properties",
  ]);

  const combinedFonts: CombinedFontValue[] = fontType.map((font, index) => ({
    ...font,
    ...fontProperties[index],
  }));

  return combinedFonts.reduce((acc, font) => {
    let name: string | undefined;
    let format: FontFile | undefined;
    let uniqueIdentifier: string | undefined;

    if ("uploaded_font" in font) {
      name = font.uploaded_font;
      format = font.font_path.split(".").at(-1) as FontFile;
      uniqueIdentifier = font.font_path;

      if (!allowedFormats.includes(format)) {
        return acc;
      }
    } else if ("custom_font" in font) {
      name = font.custom_font;
      uniqueIdentifier = name;

      if (!googleFonts.includes(name as (typeof googleFonts)[number]))
        return acc;
    }

    if (uniqueIdentifier && !seen.has(uniqueIdentifier)) {
      seen.add(uniqueIdentifier);
      acc.push({ ...font, ...(format ? { format: fontFormat(format) } : {}) });
    }

    return acc;
  }, [] as CombinedFontValue[]);
}

export function extractUploadedFonts(
  config: GenericLandingPage | DoubleOptinPage | GenericReview,
) {
  const seen = new Set();

  const fontProperties = findAllByKey<FontProperties>(config, [
    "font_properties",
  ]);

  return fontProperties.reduce((acc, font) => {
    if (!font.font_path || seen.has(font.font_path)) return acc;
    const format = font.font_path.split(".").at(-1) as FontFile;
    if (!allowedFormats.includes(format)) return acc;

    seen.add(font.font_path);
    return acc.concat({
      ...font,
      format: fontFormat(format),
    });
  }, [] as Font[]);
}

function fontFormat(format: FontFile) {
  if (format === "otf") return "opentype";
  if (format === "ttf") return "truetype";
  return format;
}

export function getFontFace(font: Font) {
  const { font_weight, font_style, font_path, font_name, format } = font;
  // The font-face declartion has to stay on one line based on the usage of injecting it into the DOM
  // which doesn't like new line characters
  return `@font-face { font-family: '${font_name}'; font-style: ${font_style}; font-weight: ${font_weight}; src: url('${font_path}') format('${format}'); }`;
}

export function getGoogleFontFace(font: CombinedFontValue, path: string) {
  if ("custom_font" in font) {
    const fontName = font.custom_font.replace(/\s/g, "");

    //Pacifico is the only font with just 400 weight, all others have 400,700
    if (fontName === "Pacifico") {
      return `<link as="font" crossorigin="anonymous" rel="preload" href="${path}/assets/fonts/${fontName}/${fontName.toLowerCase()}-400.woff2"/> <style>@font-face { font-family: '${font.custom_font}'; font-style: normal; font-weight: 400; src: url('${path}/assets/fonts/${fontName}/${fontName.toLowerCase()}-400.woff2') format('woff2'); font-display: swap; }</style>`;
    }

    return ` <link as="font" crossorigin="anonymous" rel="preload" href="${path}/assets/fonts/${fontName}/${fontName.toLowerCase()}-400.woff2"/> <link as="font" crossorigin="anonymous" rel="preload" href="${path}/assets/fonts/${fontName}/${fontName.toLowerCase()}-700.woff2" /> <style>@font-face { font-family: '${font.custom_font}'; font-style: normal; font-weight: 400; src: url('${path}/assets/fonts/${fontName}/${fontName.toLowerCase()}-400.woff2') format('woff2'); font-display: swap; } @font-face { font-family: '${font.custom_font}'; font-style: normal; font-weight: 700; src: url('${path}/assets/fonts/${fontName}/${fontName.toLowerCase()}-700.woff2') format('woff2');  font-display: swap; }</style>`;
  } else {
    return "";
  }
}
