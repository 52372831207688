import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import type { OtherInputField } from "@lib/config/form";
import { areCities, type Emirate, normaliseLocations } from "@lib/uae";
import { cn } from "@lib/utils";
import { useFormContext } from "react-hook-form";

type AreaProps = OtherInputField & {
  language: string;
};

export function Area({ language, label, placeholder }: AreaProps) {
  const { control, watch } = useFormContext();
  const district = watch("district") as Emirate;

  if (!district) return null;

  const cities = normaliseLocations(
    areCities[district],
    language as "en" | "ar",
  );

  return (
    <FormField
      control={control}
      name="town"
      render={({ field }) => (
        <FormItem>
          <FormLabel
            id="area-label"
            className="mb-[0.875rem] block"
            style={{
              fontSize: "var(--label-font-size)",
              fontFamily: "var(--label-font-name, var(--font-family))",
              fontStyle: "var(--label-font-style, var(--font-style))",
              fontWeight: "var(--label-font-weight, var(--font-weight))",
              color: "var(--label-color)",
            }}
          >
            {label}
          </FormLabel>
          <Select onValueChange={field.onChange}>
            <FormControl>
              <SelectTrigger
                aria-labelledby="area-label"
                className={cn(!field.value && "text-[--placeholder-color]")}
              >
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {cities.map(({ value, label }) => (
                <SelectItem key={value} value={value}>
                  {label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
