import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import type { Activity } from "@lib/activity";
import type { ProductSelectionBlock } from "@lib/config/types";
import type { ProductType } from "@lib/products";
import {
  cn,
  color,
  font,
  fontStyle,
  fontWeight,
  textBlockWidth,
} from "@lib/utils";
import {
  Root as RadioGroup,
  Item as RadioItem,
} from "@radix-ui/react-radio-group";
import type { CSSProperties } from "react";
import { useFormContext } from "react-hook-form";

type ProductSelectProps = ProductSelectionBlock & {
  activity: Activity;
};

type ProductStyles = CSSProperties & {
  "--thumb-size": string;
  "--min-thumb-size": string;
  "--max-thumb-size": string;
  "--selected-border-color": string;
  "--border-width": string;
};

export function ProductSelect(props: ProductSelectProps) {
  const { control } = useFormContext();
  const { product_selection: productSelect, activity } = props;

  if (
    activity.products.type === "single" ||
    activity.products.type === "bundle"
  )
    return null;

  const { layout } = productSelect;

  return (
    <div
      data-block-type="Product Select"
      style={
        {
          width: textBlockWidth(productSelect.width),
          "--thumb-size": `var(--thumb-${productSelect.thumbnail_size})`,
          "--min-thumb-size": "var(--thumb-small)",
          "--max-thumb-size": `var(--thumb-${productSelect.thumbnail_size})`,
          "--selected-border-color": color(
            productSelect.selected_border_colour,
          ),
          "--border-width": `${productSelect.border_size}px`,
        } as ProductStyles
      }
    >
      <FormField
        control={control}
        name="order_allocation_ids"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <RadioGroup
                name={field.name}
                style={{
                  color: color(productSelect.typography.colour),
                  fontFamily: font(productSelect.typography.font),
                  fontSize: productSelect.typography.size,
                  fontStyle: fontStyle(
                    productSelect.typography.font_properties,
                  ),
                  fontWeight: fontWeight(
                    productSelect.typography.font_properties,
                  ),
                }}
                className={cn(
                  "w-full",
                  layout === "grid" &&
                    "grid grid-cols-product-grid items-center gap-4",
                  layout === "list" && "flex flex-col gap-4",
                )}
              >
                <MultiProduct
                  products={activity.products as Products}
                  {...props}
                />
              </RadioGroup>
            </FormControl>
            <FormMessage className="mt-2 text-center" />
          </FormItem>
        )}
      />
    </div>
  );
}

type Products = ProductType<"multi"> | ProductType<"multiBundle">;

type MultiProductProps = ProductSelectionBlock & {
  products: Products;
};

function MultiProduct({
  product_selection: productSelection,
  ...props
}: MultiProductProps) {
  const { layout, out_of_stock_message } = productSelection;
  const products = props.products.product.products;

  return products.map((product) => {
    return (
      <FormItem key={product.allocationIds.join(",")}>
        <FormControl>
          <RadioItem
            id={product.allocationIds.join(",")}
            value={product.allocationIds.join(",")}
            style={{
              borderWidth: "var(--border-width)",
            }}
            className={cn(
              "flex flex-col h-full items-center gap-2 rounded-sm border-transparent p-4 ring-offset-background transition-colors focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-[var(--selected-border-color)] hover:data-[state=unchecked]:!border-slate-300",
              layout === "list" &&
                "grid grid-cols-product-list gap-4 text-left",
            )}
            disabled={!product.hasStock}
          >
            <img src={product.image} alt={product.name} />
            <div className="font-[family-name:--product-font-name] font-[--product-font-weight]">
              <p>{product.name}</p>
              {!product.hasStock && (
                <span className="text-xs">{out_of_stock_message}</span>
              )}
            </div>
          </RadioItem>
        </FormControl>
      </FormItem>
    );
  });
}
