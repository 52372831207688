import { Button } from "@/components/ui/button";
import type { ShopNowButtonBlockV2 } from "@lib/config/types";
import {
  type FontFamily,
  alignStyle,
  boxShadow,
  resolveFontFamilyVariables,
} from "@lib/utils";

type ShopNowButtonProps = ShopNowButtonBlockV2;

export function ShopNowButton({ shop_now_button: button }: ShopNowButtonProps) {
  const fontFamily = button.font as FontFamily;
  return (
    <div
      data-block-type="Submit Button"
      style={{
        ...resolveFontFamilyVariables(
          "--button",
          fontFamily,
          button.font_properties,
          button.font_weight,
        ),
      }}
    >
      <Button
        asChild
        className="px-8 py-0 w-min"
        style={{
          ...alignStyle(button),
          width: button.width === "full" ? "100%" : "min-content",
          height: `${button.height}px`,
          color: button.color,
          backgroundColor: button.background_color,
          borderRadius:
            button.border.radius === 9999
              ? "9999em"
              : `${button.border.radius}px`,
          border: `${button.border.width}px solid ${button.border.color}`,
          fontSize: button.font_size,
          fontFamily: "var(--button-font-name)",
          fontWeight: "var(--button-font-weight)",
          letterSpacing: button.letter_spacing,
          boxShadow: boxShadow(button.shadow),
        }}
      >
        <a href={button.url} className="no-underline">
          {button.text}
        </a>
      </Button>
    </div>
  );
}
