import { LandingForm } from "@/components/forms/landing/form";
import { Landing } from "@/components/pages/landing";
import { type Activity, type ActivityFlowConsents } from "@lib/activity";
import { type GenericLandingPage } from "@lib/config/types";
import type { Config } from "@lib/config/utils";
import type { Ticket } from "@lib/ticket";

type LandingProps = {
  activity: Activity;
  config: Config;
  ticket: Ticket | undefined;
  encodedActivityId: string;
  flowConsents?: ActivityFlowConsents;
};

export default function LandingPage({
  activity,
  config,
  ticket,
  encodedActivityId,
  flowConsents,
}: LandingProps) {
  return (
    <LandingForm encodedActivityId={encodedActivityId} ticket={ticket}>
      <Landing
        activity={activity}
        config={config as GenericLandingPage}
        ticket={ticket}
        flowConsents={flowConsents}
        page="landing"
      />
    </LandingForm>
  );
}
