import { ReviewForm } from "@/components/forms/review/form";
import { Review } from "@/components/pages/review";
import type { Activity, ActivityFlowConsents } from "@lib/activity";
import type { Config } from "@lib/config/utils";
import type { Ticket } from "@lib/ticket";
import { useEffect, useState } from "react";
import type { GenericReview } from "@lib/config/types";
import { type OrderDetails, reviewStepArray } from "@lib/review";
import { applyStageBranding } from "@lib/utils";
import { logger } from "@lib/logger";

type ReviewProps = {
  activity: Activity;
  config: Config;
  ticket: Ticket | undefined;
  encodedActivityId: string;
  orderDetails?: OrderDetails;
  flowConsents?: ActivityFlowConsents;
  currentStep?: string;
};

export default function ReviewPage({
  activity,
  config,
  ticket,
  encodedActivityId,
  orderDetails,
  flowConsents,
  currentStep,
}: ReviewProps) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/review/step/${ticket?.order_id}`)
      .then((response) => response.json())
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        logger.error(error, "Error fetching review page index");
        setLoading(false);
      });
  }, [ticket?.order_id]);

  const stepsArray = reviewStepArray((config as GenericReview).pages);

  if (loading) {
    return (
      <div
        className="h-full"
        style={applyStageBranding((config as GenericReview).branding)}
      />
    );
  }

  return (
    <ReviewForm
      encodedActivityId={encodedActivityId}
      ticket={ticket}
      steps={stepsArray}
      currentStep={currentStep ?? "0"}
      blueprint={activity.feedbackBlueprint}
      orderDetails={orderDetails}
    >
      <Review
        activity={activity}
        config={config as GenericReview}
        ticket={ticket}
        flowConsents={flowConsents}
        page={currentStep ?? "0"}
      />
    </ReviewForm>
  );
}
