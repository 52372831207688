import type { ShortAnswer } from "@lib/config/types";
import type { FormField } from "@lib/config/form";
import { FormField as FormFieldComponent } from "@/components/blocks/form/field";

type ShortAnswerProps = ShortAnswer;

export function ShortAnswer(props: ShortAnswerProps) {
  const fieldProps: FormField = {
    fieldType: "input",
    id: props.internal_id,
    name: `custom-question:${props.internal_id}`,
    type: "text",
    label: props.question,
    prompt: props.prompt,
  };

  return <FormFieldComponent {...fieldProps} />;
}
