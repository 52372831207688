import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { ajaxHeadersWithCSRFToken } from "@lib/utils";
import { api } from "@lib/client";
type DoubleOptinFormProps = {
  encodedActivityId: string;
  consentId: string;
  children: React.ReactNode;
};

export function DoubleOptinForm({
  encodedActivityId,
  consentId,
  children,
}: DoubleOptinFormProps) {
  const methods = useForm();
  const { mutateAsync } = useMutation({
    mutationFn: async () => {
      const res = await api.consent.$post(
        {
          json: {
            encodedActivityId,
            consentId,
          },
        },
        {
          headers: ajaxHeadersWithCSRFToken(),
        },
      );

      return res.json();
    },
  });
  const onSubmit = async (_: unknown) => {
    const res = await mutateAsync();
    if (res.success && res.redirect) {
      window.location.href = res.redirect;
    }
  };

  return (
    <main>
      <FormProvider {...methods}>
        <form
          className="grid gap-8"
          onSubmit={(e) => void methods.handleSubmit(onSubmit)(e)}
        >
          {children}
        </form>
      </FormProvider>
    </main>
  );
}
