import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { api } from "@lib/client";
import { logger } from "@lib/logger";
import { type OrderDetails } from "@lib/review";
import type { Ticket } from "@lib/ticket";
import { ajaxHeadersWithCSRFToken } from "@lib/utils";
import { type ReviewValidationResponse } from "@server/routes/api/validate";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { type FieldValues, FormProvider, useForm } from "react-hook-form";
import { validate } from "uuid";

type ReviewFormProps = {
  encodedActivityId: string;
  ticket: Ticket | undefined;
  children: React.ReactNode;
  blueprint: {
    id: string | null;
  } | null;
  currentStep: string;
  steps: string[];
  orderDetails?: OrderDetails;
};

const saveAuthenticity = ["3.4", "3.5", "3.8", "3.9"];

export function ReviewForm({
  encodedActivityId,
  ticket,
  children,
  steps,
  blueprint,
  currentStep,
  orderDetails,
}: ReviewFormProps) {
  const methods = useForm();
  const { register } = methods;
  const [fingerprint, setFingerprint] = useState<string | null>(null);
  const blackBoxRef = useRef<HTMLInputElement | null>(null);

  const queryParams = new URLSearchParams(window.location.search);
  let product = queryParams.get("product");

  if (product) {
    product = product.split("?")[0];
  }

  useEffect(() => {
    const loadFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const visitorId = result.visitorId;

      setFingerprint(visitorId);
      methods.setValue("fingerprint", visitorId); // Set the fingerprint in the form state
    };

    loadFingerprint().catch((error) => {
      logger.error(error, "Failed to load fingerprint");
    });
  }, [methods]);

  const { mutateAsync } = useMutation({
    mutationFn: async (formData: FieldValues) => {
      const blackBox = (blackBoxRef && blackBoxRef.current?.value) ?? "";

      const res = await api.validate.review.$post(
        {
          json: {
            encodedActivityId,
            ticket,
            formData: { ...formData, blackBox },
            steps: steps,
            currentStep: currentStep,
            orderDetails: orderDetails,
            product: product ? validate(product) : undefined,
          },
        },
        {
          headers: ajaxHeadersWithCSRFToken(),
        },
      );
      return res.json();
    },
    onError: (error) => {
      logger.error(error, "Error handling the mutation");
    },
    onSuccess: (data: ReviewValidationResponse) => {
      if (!data.success && "errors" in data) {
        Object.entries(data.errors ?? {}).forEach(([key, value]) => {
          methods.setError(key, { message: value });
        });
        return;
      }

      if ("redirect" in data) {
        window.location.href = data.redirect as string;
        return;
      }

      if (data.success) {
        if (currentStep === "0") {
          const { fingerprint } = methods.getValues() as {
            fingerprint: string;
          };

          methods.reset({ fingerprint });
        }
      }
    },
  });

  async function onSubmit(formData: FieldValues) {
    try {
      await mutateAsync(formData);
    } catch (error) {
      logger.error(error, "Error handling review submission");
    }
  }

  return (
    <main suppressHydrationWarning>
      <FormProvider {...methods}>
        <form onSubmit={(e) => void methods.handleSubmit(onSubmit)(e)}>
          <input
            ref={blackBoxRef}
            type="hidden"
            name="blackBox"
            id="ioBlackBox"
          />
          {blueprint?.id && saveAuthenticity.includes(blueprint?.id) && (
            <>
              <input
                className="sr-only"
                {...register("fingerprint")}
                id="fingerprint"
                value={fingerprint ?? ""}
                readOnly
              />
            </>
          )}
          {children}
        </form>
      </FormProvider>
    </main>
  );
}
