"use client";

import { cn } from "@lib/utils";
import React from "react";
import type { BlockProps } from "../blocks/block";
import { useHighlightStore } from "@lib/preview";

type HighlightContainerProps = {
  children: React.ReactElement<BlockProps>[] | React.ReactElement<BlockProps>;
};

const formatBlock = (block: string) => block.split("_").join(" ");

export function HighlightContainer({ children }: HighlightContainerProps) {
  const highlightedIndex = useHighlightStore((state) => state.highlightedIndex);

  return React.Children.map(children, (child, index) => {
    return (
      <div
        key={`${child.props.block.type}-${index}`}
        className={cn(
          "relative",
          highlightedIndex === index &&
            "before:pointer-events-none before:absolute before:inset-0 before:border-2 before:border-indigo-600 before:opacity-100 before:content-[''] after:absolute after:left-[2px] after:top-[2px] after:bg-indigo-600 after:px-3 after:py-2 after:font-sans after:text-xs after:text-white after:opacity-70 after:content-[attr(data-block)]",
        )}
        data-block={formatBlock(child.props.block.type)}
      >
        {child}
      </div>
    );
  });
}
