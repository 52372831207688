import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogEmptyContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useEffect, useState } from "react";
import { useTranslations } from "@/components/context/translations";
import { useCookies } from "react-cookie";

type CookieNoticeProps = {
  activityId: string;
};

export function CookieNotice({ activityId }: CookieNoticeProps) {
  const cookieName = `sopost-purpose_${activityId}`;
  const [isMounted, setIsMounted] = useState(false);
  const [analytics, setAnalytics] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [view, setView] = useState<"notice" | "settings">("notice");
  const [, setCookie] = useCookies([cookieName]);
  const t = useTranslations();

  const preference =
    analytics && marketing
      ? "analytical_and_marketing"
      : analytics
        ? "analytical"
        : marketing
          ? "marketing"
          : "rejected";

  // Required to prevent server/client mismatch
  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <Dialog open>
      <DialogEmptyContent>
        {view === "notice" && (
          <>
            <DialogHeader>
              <DialogTitle>{t("Manage cookies")}</DialogTitle>
              <DialogDescription>
                {t(
                  "We use cookies on our site to enhance your experience, improve our site, and show you relevant content. Third parties (including advertising partners) may place cookies, including for ads tailored to your interests. View our cookie policy.",
                )}
              </DialogDescription>
            </DialogHeader>

            <div className="grid gap-2">
              <Button
                onClick={() => {
                  setCookie(cookieName, "analytical_and_marketing");
                }}
              >
                {t("Accept optional cookies")}
              </Button>
              <Button
                onClick={() => {
                  setCookie(cookieName, "rejected");
                }}
              >
                {t("Reject optional cookies")}
              </Button>
              <Button
                variant="link"
                className="mt-2"
                onClick={() => setView("settings")}
              >
                {t("Manage cookie settings")}
              </Button>
            </div>
          </>
        )}

        {view === "settings" && (
          <>
            <DialogHeader>
              <DialogTitle>{t("Cookie settings")}</DialogTitle>
            </DialogHeader>
            <div className="mt-6 grid gap-8">
              <div className="grid gap-4 text-sm">
                <div className="flex items-center justify-between">
                  <Label>{t("Necessary cookies")}</Label>
                  <Badge>{t("Required")}</Badge>
                </div>
                <p className="text-xs">
                  {t(
                    "Necessary cookies enable core functionality to allow you to order your sample.",
                  )}
                </p>
              </div>
              <div className="grid gap-4">
                <div className="flex items-center justify-between">
                  <Label htmlFor="analytical">{t("Analytical")}</Label>
                  <Switch
                    id="analytical"
                    checked={analytics}
                    onCheckedChange={() => setAnalytics((a) => !a)}
                  />
                </div>
                <p className="text-xs">
                  {t(
                    "We'd like to set optional analytical cookies to collect statistics related to our users. These statistics enable us to continuously improve the sampling experience.",
                  )}
                </p>
              </div>
              <div className="grid gap-4">
                <div className="flex items-center justify-between">
                  <Label htmlFor="marketing">{t("Marketing")}</Label>
                  <Switch
                    id="marketing"
                    checked={marketing}
                    onCheckedChange={() => setMarketing((m) => !m)}
                  />
                </div>
                <p className="text-xs">
                  {t(
                    "We'd like to set optional marketing cookies to show you personalised advertising based on your engagement when ordering your sample.",
                  )}
                </p>
              </div>
              <Button
                className="mt-2"
                onClick={() => {
                  setCookie(cookieName, preference);
                }}
              >
                {t("Save and close")}
              </Button>
            </div>
          </>
        )}
      </DialogEmptyContent>
    </Dialog>
  );
}
