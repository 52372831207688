/* eslint-disable react/jsx-no-literals */
import "./instrument";

import { ErrorBoundary } from "@sentry/react";
import type { Activity } from "@lib/activity";
import type { GenericLandingPage } from "@lib/config/types";
import type { Dictionary } from "@lib/i18n";
import type { Flow } from "@lib/path.server";
import type { Ticket } from "@lib/ticket";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./globals.css";
import { TranslationsProvider } from "@/components/context/translations";
import { CookiesProvider } from "react-cookie";
import { ReactQueryProvider } from "@/components/context/react-query";
import React from "react";
import { type OrderDetails } from "@lib/review";

declare const activity: Activity;
declare const config: GenericLandingPage;
declare const dictionary: Dictionary;
declare const ticket: Ticket | undefined;
declare const flow: Flow;
declare const encodedActivityId: string;
declare const shortOrderId: string;
declare const orderDetails: OrderDetails;
declare const currentStep: string;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ErrorBoundary fallback={<p>An error has occurred</p>}>
      <CookiesProvider
        defaultSetOptions={{
          path: "/",
          secure: true,
          sameSite: "lax",
        }}
      >
        <TranslationsProvider dictionary={dictionary}>
          <ReactQueryProvider>
            <App
              activity={activity}
              config={config}
              ticket={ticket}
              flow={flow}
              encodedActivityId={encodedActivityId}
              shortOrderId={shortOrderId}
              orderDetails={orderDetails}
              currentStep={currentStep}
            />
          </ReactQueryProvider>
        </TranslationsProvider>
      </CookiesProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);
