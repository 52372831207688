import {
  type LandingPageCustomQuestions,
  type MultipleChoice,
  type CustomQuestionsConfig,
  type GenericLandingPage,
  type CustomQuestionsV2,
  type TypeExtract,
  type GenericReview,
} from "./config/types";
import type { Values } from "@lib/utils";
import { z } from "zod";
import type { Entries } from "type-fest";
import { findAllByKey } from "./config/utils";
import { type Ticket } from "./ticket";
import type { FlowType } from "../app/components/blocks/blocks";

export const CustomQuestionSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.enum(["short_answer", "date_input", "star_rating"]),
    answer: z.string(),
    question: z.string(),
    external_id: z.string(),
    internal_id: z.string(),
  }),
  z.object({
    type: z.literal("multiple_choice"),
    answer: z
      .array(
        z.object({
          answer: z.string(),
          external_id: z.string(),
          internal_id: z.string(),
        }),
      )
      .or(
        z.object({
          answer: z.string(),
          external_id: z.string(),
          internal_id: z.string(),
        }),
      ),
    question: z.string(),
    external_id: z.string(),
    internal_id: z.string(),
    question_qualified: z.boolean().optional(),
  }),
]);

export type CustomQuestion = z.infer<typeof CustomQuestionSchema>;

export function extractCustomQuestions(
  customQuestions: LandingPageCustomQuestions,
) {
  return customQuestions?.custom_questions?.questions ?? [];
}

export function extractMultipleChoiceSelectionAnswerAmount(
  config: MultipleChoice,
) {
  return config.enable_multiple_answer_selection ===
    "disable_multiple_choice_selection"
    ? config.enable_multiple_answer_selection
    : config.enable_multiple_answer_selection.enable_multiple_choice_selection
        .answer_amount;
}

export function multipleChoiceAllowsOneAnswer(config: MultipleChoice) {
  const multipleChoiceAnswerAmount =
    extractMultipleChoiceSelectionAnswerAmount(config);

  if (!multipleChoiceAnswerAmount) {
    throw new Error(
      `Could not determine the answer amount to validate for the question with id ${config.question.internal_id}`,
    );
  }

  /**
   * Return true if
   * config = "disable_multiple_choice_selection" or
   * allows unlimited choices or
   * allows a range starting from 1 or
   * exact number is 1 ( which should be a radio but we'll allow for bad campaign setup )
   */
  return (
    multipleChoiceAnswerAmount === "disable_multiple_choice_selection" ||
    multipleChoiceAnswerAmount === "unlimited" ||
    ("range" in multipleChoiceAnswerAmount &&
      multipleChoiceAnswerAmount.range.from === 1) ||
    ("exact_number" in multipleChoiceAnswerAmount &&
      multipleChoiceAnswerAmount.exact_number.number === 1)
  );
}

export function getMultipleChoiceValidationSchema(
  form: Values,
  config: MultipleChoice,
  flowType: FlowType = "claim",
) {
  const valueInForm = form[`custom-question:${config.question.internal_id}`];
  const isRequired = config.question_required;
  const multipleChoiceAnswerAmount =
    extractMultipleChoiceSelectionAnswerAmount(config);

  if (!multipleChoiceAnswerAmount) {
    throw new Error(
      `Could not determine the answer amount for validate for the question with id: ${config.question.internal_id} `,
    );
  }

  const customErrorObject = { message: config.question.error_message };
  const regex = answerRegex(config, flowType);

  // multiple choices is disabled
  if (multipleChoiceAnswerAmount === "disable_multiple_choice_selection") {
    return isRequired
      ? z
          .string({
            invalid_type_error: config.question.error_message,
            required_error: config.question.error_message,
          })
          .regex(regex, customErrorObject)
          .min(1, customErrorObject)
      : z
          .string({
            invalid_type_error: config.question.error_message,
            required_error: config.question.error_message,
          })
          .regex(regex, customErrorObject)
          .optional();
  }

  // a single answer is submitted and the field config allows for a minimum of one answer
  if (
    typeof valueInForm === "string" &&
    multipleChoiceAllowsOneAnswer(config)
  ) {
    return isRequired
      ? z
          .string({
            invalid_type_error: config.question.error_message,
          })
          .regex(regex, customErrorObject)
          .min(1, customErrorObject)
      : z
          .string({
            invalid_type_error: config.question.error_message,
            required_error: config.question.error_message,
          })
          .regex(regex, customErrorObject)
          .optional();
  }

  // multiple choices - unlimited
  if (multipleChoiceAnswerAmount === "unlimited") {
    return isRequired
      ? z
          .string({
            invalid_type_error: config.question.error_message,
          })
          .regex(regex, customErrorObject)
          .array()
          .nonempty(customErrorObject)
      : z
          .string({
            invalid_type_error: config.question.error_message,
          })
          .regex(regex, customErrorObject)
          .array()
          .optional();
  }

  // multiple choices - range
  if ("range" in multipleChoiceAnswerAmount) {
    return isRequired
      ? z
          .string({
            invalid_type_error: config.question.error_message,
          })
          .regex(regex, customErrorObject)
          .array()
          .min(multipleChoiceAnswerAmount.range.from, customErrorObject)
          .max(multipleChoiceAnswerAmount.range.to, customErrorObject)
          .nonempty(customErrorObject)
      : z
          .string({
            invalid_type_error: config.question.error_message,
          })
          .regex(regex, customErrorObject)
          .array()
          .min(multipleChoiceAnswerAmount.range.from, customErrorObject)
          .max(multipleChoiceAnswerAmount.range.to, customErrorObject)
          .optional();
  }

  // multiple choices - exact amount
  if ("exact_number" in multipleChoiceAnswerAmount) {
    return isRequired
      ? z
          .string({
            invalid_type_error: config.question.error_message,
          })
          .regex(regex, customErrorObject)
          .array()
          .length(
            multipleChoiceAnswerAmount.exact_number.number,
            customErrorObject,
          )
      : z
          .string({
            invalid_type_error: config.question.error_message,
          })
          .regex(regex, customErrorObject)
          .array()
          .length(
            multipleChoiceAnswerAmount.exact_number.number,
            customErrorObject,
          )
          .optional();
  }

  throw new Error(
    `Could not determine the answer amount for validate for the question with id: ${config.question.internal_id} `,
  );
}

export function getMutlipleChoiceQuestionAnswerConfigs(
  answer: FormDataEntryValue | FormDataEntryValue[],
  questionConfig: MultipleChoice,
) {
  if (
    questionConfig.enable_multiple_answer_selection ===
    "disable_multiple_choice_selection"
  ) {
    const config = findAnswerConfig(
      answer as FormDataEntryValue,
      questionConfig,
    );

    if (config) return config;
  }

  if (typeof questionConfig.enable_multiple_answer_selection === "object") {
    // multiple answer provided
    if (Array.isArray(answer)) {
      return answer.map((answer: FormDataEntryValue) =>
        findAnswerConfig(answer, questionConfig),
      );
      // single answer provided
    } else if (typeof answer === "string") {
      const config = findAnswerConfig(answer, questionConfig);
      if (config) return [config];
    }
  }
}

export function getValidAnswers(
  config: MultipleChoice,
  flowType: FlowType = "claim",
) {
  if (config.other_option !== "exclude") return null;

  return flowType === "review"
    ? config.answers.map((answerConfig) => answerConfig.internal_id)
    : config.answers.map((answerConfig) => answerConfig.answer);
}

function escapeRegExp(text: string | null) {
  return text?.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function answerRegex(
  config: MultipleChoice,
  flowType: FlowType = "claim",
) {
  const validAnswers = getValidAnswers(config, flowType);
  if (!validAnswers) return new RegExp(".*");

  // Escape each answer to treat special characters literally
  const escapedAnswers = validAnswers.map(escapeRegExp);
  return new RegExp(`^(${escapedAnswers.join("|")})$`);
}

export function findAnswerConfig(
  answer: FormDataEntryValue,
  questionConfig: MultipleChoice,
) {
  // search answer configs for a match to the submitted answer
  const config = questionConfig.answers.find(
    (answerConfig: Record<string, string | boolean>) =>
      answerConfig.answer === answer,
  );

  // return config if its found
  if (config) return config;

  // if other option is enabled and an answer config has not been found - return it with answer
  if (questionConfig.other_option !== "exclude") {
    return {
      answer: answer ?? "",
      external_id: questionConfig.other_option.include.external_id,
      internal_id: questionConfig.other_option.include.internal_id,
    };
  }

  // if no answer is submitted - return an empty config as per capture-v2
  if (!answer) {
    return {
      answer: "",
      external_id: null,
      internal_id: null,
    };
  }

  // if we have a submitted answer that is not empty, the other option is off and we can't find a config then throw an error
  throw new Error(
    `Unable to find an answer config for questions with id: ${questionConfig.question.internal_id}`,
  );
}

export function formatAnswer(
  answer: ReturnType<typeof getMutlipleChoiceQuestionAnswerConfigs>,
) {
  if (!answer) return {};

  if (Array.isArray(answer)) {
    // May seem redundant but we want to remove the `qualifying_answer` key
    return answer.map((answer) => ({
      answer: answer.answer,
      external_id: answer.external_id,
      internal_id: answer.internal_id,
    }));
  }

  return {
    answer: answer.answer,
    external_id: answer.external_id,
    internal_id: answer.internal_id,
  };
}

export function hasQualifyingQuestions(
  customQuestionConfigs: CustomQuestionsConfig[],
) {
  return customQuestionConfigs.some((customQuestionConfig) => {
    const [[type, config]] = Object.entries(customQuestionConfig) as Entries<
      typeof customQuestionConfig
    >;
    if (
      type === "multiple_choice" &&
      "qualifying_question" in config.question
    ) {
      return config.question.qualifying_question;
    }
  });
}

export function parseCustomQuestions(
  customQuestions: Record<string, string | FormDataEntryValue[]>[],
  pageConfig: GenericLandingPage | GenericReview,
) {
  const landingPageCustomQuestionConfigs =
    findAllByKey<LandingPageCustomQuestions>(pageConfig, [
      "custom_questions",
      "custom_questions_v2",
    ]);

  const customQuestionConfigs = landingPageCustomQuestionConfigs.flatMap(
    extractCustomQuestions,
  );

  const customQuestionItems: (CustomQuestion | null)[] =
    customQuestionConfigs.map((customQuestionConfig) => {
      const [[type, config]] = Object.entries(customQuestionConfig) as Entries<
        typeof customQuestionConfig
      >;

      if (
        type === "short_answer" ||
        type === "date_input" ||
        type === "star_rating"
      ) {
        const submitted = customQuestions.find(
          ({ questionId }) => config.internal_id === questionId,
        ) as Record<string, string>;

        // If the rating is optional and unanswered we want to omit the entry
        if (type === "star_rating" && !submitted?.answer) return null;

        return {
          type,
          answer: submitted?.answer ?? "",
          question: config.question,
          external_id: config.external_id,
          internal_id: config.internal_id,
        } as CustomQuestion;
      }

      if (type === "multiple_choice") {
        const submitted = customQuestions.find(
          ({ questionId }) => config.question.internal_id === questionId,
        ) as Record<string, string>;

        const answer = getMutlipleChoiceQuestionAnswerConfigs(
          submitted?.answer ?? "",
          config as unknown as MultipleChoice,
        );

        const qualified = Array.isArray(answer)
          ? answer.some((a) => "qualifying_answer" in a && a.qualifying_answer)
          : answer != null &&
            "qualifying_answer" in answer &&
            Boolean(answer.qualifying_answer);

        return {
          type,
          answer: formatAnswer(answer),
          question: config.question.question_title,
          external_id: config.question.external_id,
          internal_id: config.question.internal_id,
          ...("qualifying_question" in config.question
            ? config.question.qualifying_question
              ? { question_qualified: qualified }
              : {}
            : {}),
        } as CustomQuestion;
      }

      throw new Error(
        `Recieved an unexpected question config for question with type: ${type as string}`,
      );
    });

  return {
    customQuestions: customQuestionItems.filter(Boolean),
    hasQualifyingQuestions: hasQualifyingQuestions(customQuestionConfigs),
  };
}

export function filterAlreadyAnsweredQuestions(
  questions: TypeExtract<
    CustomQuestionsV2["custom_questions"]["questions"][number]
  >[],
  ticketQuestions: Ticket["custom_questions"],
) {
  if (!ticketQuestions || ticketQuestions.length === 0) return questions;
  return questions.filter((q) => {
    return !ticketQuestions.some((tq) => {
      const id =
        q.type === "multiple_choice"
          ? q.values.question.internal_id
          : q.values.internal_id;
      return tq.internal_id === id;
    });
  });
}
