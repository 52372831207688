import { Block } from "@/components/blocks/block";
import type { Activity, ActivityFlowConsents } from "@lib/activity";
import {
  type DoubleOptinPage,
  extractBlocks,
  type GenericLandingPage,
  type GenericReview,
  type Block as BlockType,
} from "@lib/config/types";
import { type Ticket } from "@lib/ticket";

export type FlowType =
  | "claim"
  | "double_optin"
  | "community"
  | "review"
  | "delayed"
  | "unsubscribe";

type BlocksProps = {
  activity: Activity;
  blocks: BlockType[];
  config: GenericLandingPage | DoubleOptinPage | GenericReview;
  ticket?: Ticket;
  flowConsents?: ActivityFlowConsents;
  flowType?: FlowType;
};

export function Blocks({ blocks, flowConsents, ...props }: BlocksProps) {
  return extractBlocks(blocks).map((block, index) => (
    <Block
      key={`${block.type}-${index}`}
      {...props}
      block={block}
      flowConsents={flowConsents}
      flowType={props?.flowType ?? "claim"}
    />
  ));
}
