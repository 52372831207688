import { FormError } from "@/components/blocks/form/error";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import type { CheckableInputField, CheckableOption } from "@lib/config/form";
import type {
  MultipleChoiceMultipleSelectionOption,
  MultipleChoiceOtherOption,
} from "@lib/config/types";
import { fieldGridClasses } from "@lib/utils";
import { type FormEvent } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CheckableOther } from "./other";

type FormCheckboxProps = CheckableInputField;

function getFieldDisabled(
  limit: number,
  value: string,
  checkedOptions: Array<string>,
) {
  // if checked answers is less than limit - false
  if (checkedOptions.length < limit) return false;

  // if option.value is in checked answers - false
  if (checkedOptions.includes(value)) return false;

  return true;
}

function fieldIsDisabled(
  option: CheckableOption,
  mutliChoiceConfig: MultipleChoiceMultipleSelectionOption,
  checkedOptions: Array<string>,
) {
  if (mutliChoiceConfig === "disable_multiple_choice_selection") {
    return false;
  }

  const amount =
    mutliChoiceConfig?.enable_multiple_choice_selection.answer_amount;

  if (!amount || amount === "unlimited") return false;

  if ("range" in amount) {
    const rangeMax = amount.range.to;
    return getFieldDisabled(rangeMax, option.value, checkedOptions);
  }

  if ("exact_number" in amount) {
    const exact = amount.exact_number.number;
    return getFieldDisabled(exact, option.value, checkedOptions);
  }

  return false;
}

export function FormCheckbox(props: FormCheckboxProps) {
  const { setValue } = useFormContext();
  const checkedOptions = useWatch<Record<string, string[]>>({
    name: props.name,
    defaultValue: [],
  });
  const setCheckedOptions = (value: string[]) => setValue(props.name, value);

  function handleCheckedChange(e: FormEvent<HTMLFieldSetElement>) {
    const target = e.target as HTMLInputElement;
    // we don't want this to run for the other text input
    if (target.type !== "checkbox") return;

    const checked = target.checked;
    const value = target.value;

    checked
      ? setCheckedOptions([...checkedOptions, value])
      : setCheckedOptions(
          checkedOptions.filter((option: string) => option !== value),
        );
  }

  return (
    <fieldset className="mt-[1.125em]" onChange={handleCheckedChange}>
      <legend
        aria-describedby={`prompt-${props.name}`}
        className="mb-[1.125em] leading-[1.33]"
        style={{
          fontSize: "var(--title-font-size, var(--title-font-size))",
          fontFamily: "var(--title-font-name, var(--font-family))",
          fontStyle: "var(--title-font-style, var(--font-style))",
          fontWeight: "var(--title-font-weight, var(--font-weight))",
          color: "var(--title-color)",
        }}
        dangerouslySetInnerHTML={{
          __html: props.legend ?? "",
        }}
      />

      <div
        className="block [&:not(:empty)]:mb-[1.5em]"
        dangerouslySetInnerHTML={{
          __html: props.prompt ?? "",
        }}
        id={`prompt-${props.name}`}
        style={{
          fontSize: "var(--prompt-font-size)",
          fontFamily: "var(--prompt-font-name, var(--font-family))",
          fontStyle: "var(--prompt-font-style, var(--font-style))",
          fontWeight: "var(--prompt-font-weight, var(--font-weight))",
          color: "var(--prompt-font-color)",
        }}
      ></div>
      <div className={fieldGridClasses(props.layout)}>
        {props.options.map((option) => {
          return (
            <div
              key={`checkbox-${props.name}-${option.value}`}
              className="grid grid-cols-[auto_1fr] items-center gap-[0.75em] px-[0.75em] py-[0.5em]"
              style={{
                color: "var(--input-color)",
                backgroundColor: "var(--input-background)",
                borderTopLeftRadius: "var(--field-border-top-left-radius)",
                borderTopRightRadius: "var(--field-border-top-right-radius)",
                borderBottomRightRadius:
                  "var(--field-border-bottom-right-radius)",
                borderBottomLeftRadius:
                  "var(--field-border-bottom-left-radius)",
                borderColor: "var(--input-border-color)",
                borderWidth: "var(--field-border-size)",
                boxShadow: checkedOptions.includes(option.value)
                  ? "0 0 0 2px var(--input-border-color, var(--black))"
                  : "",
                filter: fieldIsDisabled(
                  option,
                  props.multipleAnswerSelection as MultipleChoiceMultipleSelectionOption,
                  checkedOptions,
                )
                  ? "grayscale(1)"
                  : "grayscale(0)",
                opacity: fieldIsDisabled(
                  option,
                  props.multipleAnswerSelection as MultipleChoiceMultipleSelectionOption,
                  checkedOptions,
                )
                  ? ".35"
                  : "1",
              }}
            >
              <Checkbox
                {...option}
                name={props.name}
                id={`checkbox-${option.id}`}
                disabled={fieldIsDisabled(
                  option,
                  props.multipleAnswerSelection as MultipleChoiceMultipleSelectionOption,
                  checkedOptions,
                )}
              />
              <Label
                className="grid cursor-pointer items-center"
                htmlFor={`checkbox-${option.id}`}
                style={{
                  fontFamily: "var(--label-font-name, var(--font-family))",
                  fontStyle: "var(--label-font-style, var(--font-style))",
                  fontWeight: "var(--label-font-weight, var(--font-weight))",
                  color: "var(--label-color)",
                  minBlockSize: "calc(var(--input-block-size) - 1em)",
                }}
                dangerouslySetInnerHTML={{
                  __html: option.label ?? "",
                }}
              />
            </div>
          );
        })}
        <CheckableOther
          name={props.name}
          type={props.type}
          option={props.other as MultipleChoiceOtherOption}
          multipleAnswerSelection={props.multipleAnswerSelection}
          checkedOptions={checkedOptions}
          setCheckedOptions={setCheckedOptions}
          fieldIsDisabled={fieldIsDisabled}
        />
      </div>
      <FormError name={props.name} />
    </fieldset>
  );
}
