import type { DateInput as DateInputProps } from "@lib/config/types";
import type { FormField } from "@lib/config/form";
import { FormField as FormFieldComponent } from "@/components/blocks/form/field";

export function DateInput(props: DateInputProps) {
  const fieldProps: FormField = {
    fieldType: "input",
    id: props.internal_id,
    name: `custom-question:${props.internal_id}`,
    type: "date",
    label: props.question,
    prompt: props.prompt,
    placeholder: props.question,
  };

  return <FormFieldComponent {...fieldProps} />;
}
