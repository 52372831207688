import { type CSSProperties } from "react";
import type { CustomQuestionsV2 } from "@lib/config/types";
import { extractBlocks } from "@lib/config/types";
import {
  textBlockWidth,
  type FontFamily,
  resolveFontFamilyVariables,
  alignStyle,
} from "@lib/utils";
import { Question } from "@/components/blocks/questions/question";
import type { Ticket } from "@lib/ticket";
import { filterAlreadyAnsweredQuestions } from "@lib/custom-questions";
import type { FlowType } from "./blocks";

type CustomQuestionsProps = CustomQuestionsV2 & {
  ticket?: Ticket;
  flowType?: FlowType;
};

type CustomQuestionStyles = CSSProperties & {
  "--title-font-name": string;
  "--title-font-weight": string;
  "--title-font-style": string;
  "--title-color": string;
  "--title-font-size": string;
  "--label-font-name": string;
  "--label-font-weight": string;
  "--label-font-style": string;
  "--label-color": string;
  "--label-font-size": string;
  "--prompt-font-name": string;
  "--prompt-font-weight": string;
  "--prompt-font-style": string;
  "--prompt-font-color": string;
  "--prompt-font-size": string;
  "--input-font-name": string;
  "--input-font-weight": string;
  "--input-font-style": string;
  "--input-color": string;
  "--input-background": string;
  "--input-border-color": string;
  "--placeholder-color": string;
  "--field-border-size": string;
  "--field-border-top-left-radius": string;
  "--field-border-top-right-radius": string;
  "--field-border-bottom-right-radius": string;
  "--field-border-bottom-left-radius": string;
};

export function CustomQuestions({
  custom_questions,
  ticket,
  flowType,
}: CustomQuestionsProps) {
  const questions = filterAlreadyAnsweredQuestions(
    extractBlocks(custom_questions.questions),
    ticket?.custom_questions,
  );

  const labelFontFamily = custom_questions.label_style.font as FontFamily;
  const promptFontFamily = custom_questions.prompt.font as FontFamily;
  const inputFontFamily = custom_questions.input_style.font as FontFamily;
  const titleFontFamily = custom_questions.title.font as FontFamily;

  const isPill =
    custom_questions.input_style.border.radius === 9999.0 ||
    custom_questions.input_style.border.radius === 99999.0;
  return (
    <div
      data-block-type="Custom Questions"
      className="mt-[1.125em] grid gap-8"
      style={
        {
          maxWidth: textBlockWidth(custom_questions.width),
          ...alignStyle(custom_questions),
          ...resolveFontFamilyVariables(
            "--title",
            titleFontFamily,
            custom_questions.prompt.font_properties,
          ),
          "--title-font-size": `${custom_questions.title.font_size}px`,
          "--title-color": custom_questions.title.color,
          ...resolveFontFamilyVariables(
            "--label",
            labelFontFamily,
            custom_questions.label_style.font_properties,
          ),
          "--label-color": custom_questions.label_style.color,
          ...resolveFontFamilyVariables(
            "--prompt",
            promptFontFamily,
            custom_questions.prompt.font_properties,
          ),
          "--prompt-font-color": custom_questions.prompt.color,
          "--prompt-font-size": `${custom_questions.prompt.font_size}px`,
          ...resolveFontFamilyVariables(
            "--input",
            inputFontFamily,
            custom_questions.input_style.font_properties,
          ),
          "--input-color": custom_questions.input_style.color,
          "--input-background": custom_questions.input_style.background_color,
          "--input-border-color": custom_questions.input_style.border.color,
          "--placeholder-color": custom_questions.input_style.placeholder_color,
          "--field-border-size": `${custom_questions.input_style.border.width}px`,
          "--field-border-top-left-radius": `${isPill ? "9999em" : `min(${custom_questions.input_style.border.radius}%, 1.5625rem)`}`,
          "--field-border-top-right-radius": `${isPill ? "9999em" : `min(${custom_questions.input_style.border.radius}%, 1.5625rem)`}`,
          "--field-border-bottom-right-radius": `${isPill ? "9999em" : `min(${custom_questions.input_style.border.radius}%, 1.5625rem)`}`,
          "--field-border-bottom-left-radius": `${isPill ? "9999em" : `min(${custom_questions.input_style.border.radius}%, 1.5625rem)`}`,
        } as CustomQuestionStyles
      }
    >
      {questions.map((question, index) => (
        <Question
          key={`custom-question-${question.type}-${index}`}
          flowType={flowType}
          {...question}
        />
      ))}
    </div>
  );
}
