import { Checkbox } from "@/components/ui/checkbox";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import type { ActivityFlowConsents } from "@lib/activity";
import type { ConsentsBlock } from "@lib/config/types";
import type { Ticket } from "@lib/ticket";
import {
  color,
  font,
  fontStyle,
  fontWeight,
  hsla,
  justify,
  textBlockWidth,
} from "@lib/utils";
import type { CSSProperties } from "react";
import { useFormContext } from "react-hook-form";
import type { CheckedState } from "@radix-ui/react-checkbox";

type ConsentsProps = ConsentsBlock & {
  ticket?: Ticket;
  flowConsents?: ActivityFlowConsents;
};

type ConsentsStyles = CSSProperties & {
  "--anchor-color": string;
  "--checkbox-color": string;
};

export function Consents({ consents, ticket, flowConsents }: ConsentsProps) {
  if (!flowConsents || flowConsents.length === 0) return null;

  return (
    <div
      data-block-type="Consents"
      className="flex w-full [&_a]:text-[var(--anchor-color)] [&_a]:underline"
      style={
        {
          justifyContent: justify(consents),
          fontFamily: font(consents.typography.font),
          fontStyle: fontStyle(consents.typography.font_properties),
          fontWeight: fontWeight(consents.typography.font_properties),
          color: color(consents.typography.colour),
          fontSize: consents.typography.size,
          "--anchor-color":
            consents.typography.hyperlink_colour === "global_hyperlink_colour"
              ? "var(--anchor-colour)"
              : hsla(consents.typography.hyperlink_colour.colour),
          "--checkbox-color": hsla(consents.checkbox_colour),
          "--input-background": "#ffffff",
        } as ConsentsStyles
      }
    >
      <div
        className="grid gap-8"
        style={{ width: textBlockWidth(consents.width) }}
      >
        {flowConsents
          .filter(
            (consent) =>
              !ticket?.consents?.includes(consent.id) ||
              consent.type !== "MARKETING_OPTIN",
          )
          .map((consent) => (
            <Consent key={consent.id} consent={consent} />
          ))}
      </div>
    </div>
  );
}

type ConsentProps = {
  consent: ActivityFlowConsents[number];
};

function Consent({ consent }: ConsentProps) {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={`consent:${consent.id}`}
      render={({ field }) => (
        <FormItem key={field.name} className="grid gap-3">
          <div className="flex space-x-4">
            <FormControl>
              <Checkbox
                id={field.name}
                name={field.name}
                checked={field.value as CheckedState | undefined}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <div className="grid gap-2 leading-tight peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              {consent.label && (
                <label
                  htmlFor={`consent:${consent.id}`}
                  className="flex min-h-[1.5em] items-center font-medium"
                  dangerouslySetInnerHTML={{
                    __html: consent.label ?? "",
                  }}
                />
              )}
              {consent.disclaimer && (
                <div
                  className="text-xs"
                  dangerouslySetInnerHTML={{
                    __html: consent.disclaimer ?? "",
                  }}
                />
              )}
            </div>
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
