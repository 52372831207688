export type { Config } from "./utils.server";

// Recurse through deeply nested object to find all values by key
// Takes ~1ms for a typical landing page
export function findAllByKey<T>(
  obj: Record<string, unknown>,
  keys: string[],
): T[] {
  if (!obj) return [];
  return Object.entries(obj).reduce(
    (acc, [key, value]) =>
      keys.includes(key)
        ? acc.concat(value as T)
        : typeof value === "object"
          ? acc.concat(findAllByKey(value as Record<string, unknown>, keys))
          : acc,
    [] as T[],
  );
}
