import { ImagePlaceholder } from "@/components/preview/image-placeholder";
import type { ImageBlockV2 } from "@lib/config/types";
import { alignStyle } from "@lib/utils";

type ImageProps = ImageBlockV2;

export function Image(props: ImageProps) {
  const { image } = props;
  if (!image.image) return <ImagePlaceholder />;

  const { hyperlink } = image;

  return (
    <div data-block-type="Image" className="mx-auto">
      {hyperlink === "" ? (
        <ImageInner {...props} />
      ) : (
        <a
          className="block"
          style={{
            maxWidth: `${image.width}%`,
            ...alignStyle(image),
          }}
          href={hyperlink}
          rel="noopener noreferrer"
        >
          <ImageInner {...props} />
        </a>
      )}
    </div>
  );
}

function ImageInner({ image }: ImageProps) {
  return (
    <img
      className="h-auto w-full"
      src={image.image}
      alt={image.alt_text}
      width={image.natural_width}
      height={image.natural_height}
      style={{
        ...alignStyle(image),
        width: image.hyperlink === "" ? `${image.width}%` : "100%",
      }}
    />
  );
}
