export function ImagePlaceholder() {
  return (
    <svg viewBox="0 0 390 180" fill="none" className="max-w-[640px]">
      <rect x="0.5" y="0.5" width="389" height="179" fill="#D9D9D9" />
      <path
        d="M173.062 43.375C170.268 43.375 168 45.6426 168 48.4375V85.5625C168 88.3574 170.268 90.625 173.062 90.625H216.938C219.732 90.625 222 88.3574 222 85.5625V48.4375C222 45.6426 219.732 43.375 216.938 43.375H173.062ZM173.062 46.75H184.242C184.59 46.75 184.875 47.0348 184.875 47.3828V49.4922C184.875 49.8402 184.59 50.125 184.242 50.125H172.008C171.66 50.125 171.375 49.8402 171.375 49.4922V48.4375C171.375 47.5094 172.134 46.75 173.062 46.75ZM217.992 56.875H172.008C171.66 56.875 171.375 56.5902 171.375 56.2422V52.4453C171.375 52.0973 171.66 51.8125 172.008 51.8125H186.562L189.748 47.0348C189.864 46.8555 190.064 46.75 190.275 46.75H216.938C217.866 46.75 218.625 47.5094 218.625 48.4375V56.2422C218.625 56.5902 218.34 56.875 217.992 56.875ZM195 84.7188C188.018 84.7188 182.344 79.0445 182.344 72.0625C182.344 65.0805 188.018 59.4062 195 59.4062C201.982 59.4062 207.656 65.0805 207.656 72.0625C207.656 79.0445 201.982 84.7188 195 84.7188ZM195 62.7812C189.885 62.7812 185.719 66.9473 185.719 72.0625C185.719 77.1777 189.885 81.3438 195 81.3438C200.115 81.3438 204.281 77.1777 204.281 72.0625C204.281 66.9473 200.115 62.7812 195 62.7812ZM189.938 73.75C189.009 73.75 188.25 72.9906 188.25 72.0625C188.25 68.3395 191.277 65.3125 195 65.3125C195.928 65.3125 196.688 66.0719 196.688 67C196.688 67.9281 195.928 68.6875 195 68.6875C193.144 68.6875 191.625 70.2062 191.625 72.0625C191.625 72.9906 190.866 73.75 189.938 73.75Z"
        fill="#ffffff"
      />
      <path
        d="M151.44 135H153.699V129.999L158.146 122.317H155.668L152.645 127.767H152.495L149.472 122.317H146.993L151.44 129.999V135Z"
        fill="#ffffff"
      />
      <path
        d="M162.567 135.193C163.528 135.193 164.352 134.994 165.037 134.596C165.729 134.191 166.259 133.617 166.628 132.873C167.003 132.129 167.19 131.241 167.19 130.21V130.192C167.19 129.161 167.003 128.273 166.628 127.529C166.253 126.785 165.72 126.214 165.028 125.815C164.343 125.417 163.522 125.218 162.567 125.218C161.618 125.218 160.798 125.42 160.106 125.824C159.421 126.223 158.891 126.794 158.516 127.538C158.141 128.282 157.953 129.167 157.953 130.192V130.21C157.953 131.241 158.138 132.129 158.507 132.873C158.882 133.617 159.412 134.191 160.098 134.596C160.789 134.994 161.612 135.193 162.567 135.193ZM162.567 133.418C162.063 133.418 161.633 133.292 161.275 133.04C160.924 132.788 160.654 132.425 160.467 131.95C160.279 131.47 160.186 130.89 160.186 130.21V130.192C160.186 129.519 160.279 128.944 160.467 128.47C160.66 127.989 160.933 127.623 161.284 127.371C161.636 127.119 162.063 126.993 162.567 126.993C163.071 126.993 163.499 127.119 163.851 127.371C164.208 127.623 164.48 127.989 164.668 128.47C164.855 128.944 164.949 129.519 164.949 130.192V130.21C164.949 130.884 164.855 131.461 164.668 131.941C164.48 132.416 164.211 132.782 163.859 133.04C163.508 133.292 163.077 133.418 162.567 133.418Z"
        fill="#ffffff"
      />
      <path
        d="M172.543 135.193C173.24 135.193 173.826 135.047 174.301 134.754C174.775 134.461 175.127 134.057 175.355 133.541H175.496V135H177.685V125.411H175.496V131.001C175.496 131.476 175.414 131.889 175.25 132.24C175.086 132.592 174.843 132.864 174.521 133.058C174.198 133.251 173.803 133.348 173.334 133.348C172.672 133.348 172.194 133.16 171.901 132.785C171.608 132.41 171.462 131.854 171.462 131.115V125.411H169.273V131.616C169.273 132.372 169.396 133.02 169.643 133.559C169.889 134.092 170.255 134.499 170.741 134.78C171.228 135.056 171.828 135.193 172.543 135.193Z"
        fill="#ffffff"
      />
      <path
        d="M180.33 135H182.51V129.428C182.51 128.965 182.609 128.566 182.809 128.232C183.008 127.898 183.286 127.641 183.644 127.459C184.007 127.277 184.432 127.187 184.918 127.187C185.105 127.187 185.287 127.198 185.463 127.222C185.645 127.239 185.823 127.269 185.999 127.31V125.35C185.888 125.32 185.753 125.297 185.595 125.279C185.442 125.262 185.284 125.253 185.12 125.253C184.493 125.253 183.966 125.396 183.538 125.684C183.116 125.965 182.823 126.366 182.659 126.888H182.51V125.411H180.33V135Z"
        fill="#ffffff"
      />
      <path
        d="M192.521 135H194.7V125.411H192.521V135ZM193.619 123.812C193.977 123.812 194.281 123.686 194.533 123.434C194.785 123.182 194.911 122.88 194.911 122.528C194.911 122.171 194.785 121.866 194.533 121.614C194.281 121.362 193.977 121.236 193.619 121.236C193.262 121.236 192.954 121.362 192.696 121.614C192.444 121.866 192.318 122.171 192.318 122.528C192.318 122.88 192.444 123.182 192.696 123.434C192.954 123.686 193.262 123.812 193.619 123.812Z"
        fill="#ffffff"
      />
      <path
        d="M197.363 135H199.543V129.155C199.543 128.763 199.622 128.408 199.78 128.092C199.944 127.77 200.167 127.518 200.448 127.336C200.735 127.154 201.058 127.063 201.415 127.063C201.954 127.063 202.376 127.216 202.681 127.521C202.991 127.825 203.146 128.244 203.146 128.777V135H205.326V129.015C205.326 128.64 205.402 128.306 205.555 128.013C205.713 127.72 205.933 127.488 206.214 127.318C206.495 127.148 206.826 127.063 207.207 127.063C207.787 127.063 208.221 127.222 208.508 127.538C208.801 127.849 208.947 128.314 208.947 128.936V135H211.127V128.399C211.127 127.737 211.004 127.169 210.758 126.694C210.512 126.22 210.157 125.856 209.694 125.604C209.237 125.347 208.687 125.218 208.042 125.218C207.597 125.218 207.181 125.291 206.794 125.438C206.413 125.578 206.079 125.78 205.792 126.044C205.505 126.308 205.285 126.615 205.133 126.967H204.983C204.849 126.604 204.655 126.293 204.403 126.035C204.151 125.771 203.85 125.569 203.498 125.429C203.152 125.288 202.763 125.218 202.329 125.218C201.696 125.218 201.148 125.364 200.686 125.657C200.229 125.95 199.897 126.363 199.692 126.896H199.543V125.411H197.363V135Z"
        fill="#ffffff"
      />
      <path
        d="M216.304 135.158C216.726 135.158 217.115 135.1 217.473 134.982C217.83 134.865 218.146 134.695 218.422 134.473C218.703 134.25 218.932 133.983 219.107 133.673H219.257V135H221.419V128.443C221.419 127.77 221.27 127.192 220.971 126.712C220.672 126.231 220.232 125.862 219.652 125.604C219.078 125.347 218.381 125.218 217.561 125.218C216.799 125.218 216.128 125.332 215.548 125.561C214.968 125.783 214.502 126.097 214.15 126.501C213.805 126.905 213.594 127.38 213.518 127.925L213.509 127.995H215.574L215.583 127.96C215.694 127.644 215.908 127.397 216.225 127.222C216.541 127.046 216.951 126.958 217.455 126.958C218.047 126.958 218.495 127.087 218.8 127.345C219.104 127.603 219.257 127.972 219.257 128.452V131.476C219.257 131.856 219.157 132.199 218.958 132.504C218.759 132.803 218.486 133.04 218.141 133.216C217.795 133.392 217.402 133.479 216.963 133.479C216.477 133.479 216.078 133.365 215.768 133.137C215.457 132.908 215.302 132.589 215.302 132.179V132.161C215.302 131.769 215.448 131.461 215.741 131.238C216.034 131.016 216.485 130.887 217.095 130.852L220.338 130.641V129.199L216.787 129.41C215.621 129.48 214.722 129.762 214.089 130.254C213.462 130.74 213.148 131.414 213.148 132.275V132.293C213.148 132.873 213.283 133.38 213.553 133.813C213.828 134.241 214.203 134.572 214.678 134.807C215.152 135.041 215.694 135.158 216.304 135.158Z"
        fill="#ffffff"
      />
      <path
        d="M228.143 138.375C229.08 138.375 229.889 138.226 230.568 137.927C231.254 137.634 231.781 137.215 232.15 136.67C232.525 136.131 232.713 135.495 232.713 134.763V125.411H230.524V126.984H230.41C230.234 126.627 230.003 126.319 229.716 126.062C229.435 125.804 229.106 125.604 228.731 125.464C228.356 125.323 227.946 125.253 227.501 125.253C226.681 125.253 225.972 125.452 225.374 125.851C224.782 126.243 224.325 126.794 224.003 127.503C223.681 128.206 223.52 129.021 223.52 129.946V129.964C223.52 130.872 223.678 131.672 223.994 132.363C224.316 133.055 224.773 133.597 225.365 133.989C225.957 134.376 226.657 134.569 227.466 134.569C227.905 134.569 228.312 134.511 228.688 134.394C229.062 134.271 229.394 134.089 229.681 133.849C229.968 133.608 230.205 133.315 230.393 132.97H230.533V134.798C230.533 135.407 230.325 135.879 229.909 136.213C229.499 136.553 228.922 136.723 228.178 136.723C227.568 136.723 227.085 136.623 226.728 136.424C226.37 136.23 226.15 135.981 226.068 135.677L226.06 135.65H223.88L223.871 135.677C223.947 136.21 224.161 136.679 224.513 137.083C224.864 137.493 225.345 137.81 225.954 138.032C226.569 138.261 227.299 138.375 228.143 138.375ZM228.143 132.829C227.633 132.829 227.202 132.709 226.851 132.469C226.499 132.229 226.229 131.892 226.042 131.458C225.854 131.024 225.761 130.526 225.761 129.964V129.946C225.761 129.378 225.854 128.877 226.042 128.443C226.229 128.01 226.499 127.673 226.851 127.433C227.202 127.187 227.633 127.063 228.143 127.063C228.646 127.063 229.077 127.187 229.435 127.433C229.798 127.673 230.076 128.01 230.27 128.443C230.469 128.877 230.568 129.378 230.568 129.946V129.964C230.568 130.532 230.469 131.033 230.27 131.467C230.076 131.895 229.798 132.229 229.435 132.469C229.077 132.709 228.646 132.829 228.143 132.829Z"
        fill="#ffffff"
      />
      <path
        d="M239.454 135.193C240.104 135.193 240.676 135.111 241.168 134.947C241.66 134.777 242.076 134.558 242.416 134.288C242.762 134.013 243.034 133.717 243.233 133.4C243.438 133.078 243.579 132.762 243.655 132.451L243.673 132.363H241.599L241.572 132.425C241.508 132.577 241.385 132.735 241.203 132.899C241.027 133.058 240.799 133.195 240.518 133.312C240.236 133.424 239.899 133.479 239.507 133.479C239.003 133.479 238.566 133.368 238.197 133.146C237.834 132.923 237.553 132.601 237.354 132.179C237.154 131.757 237.055 131.253 237.055 130.667V129.788C237.055 129.173 237.154 128.654 237.354 128.232C237.559 127.805 237.834 127.482 238.18 127.266C238.531 127.043 238.933 126.932 239.384 126.932C239.829 126.932 240.222 127.037 240.562 127.248C240.907 127.459 241.177 127.775 241.37 128.197C241.563 128.619 241.66 129.149 241.66 129.788V130.658L242.697 129.287H235.974V130.755H243.77V130.034C243.77 129.067 243.591 128.224 243.233 127.503C242.876 126.782 242.366 126.223 241.704 125.824C241.048 125.42 240.266 125.218 239.357 125.218C238.449 125.218 237.658 125.426 236.984 125.842C236.311 126.258 235.789 126.841 235.42 127.591C235.057 128.335 234.875 129.211 234.875 130.219V130.228C234.875 131.247 235.06 132.129 235.429 132.873C235.798 133.611 236.322 134.183 237.002 134.587C237.688 134.991 238.505 135.193 239.454 135.193Z"
        fill="#ffffff"
      />
    </svg>
  );
}
