import { Blocks } from "@/components/blocks/blocks";
import { Tracking } from "@/components/tracking/tracking";
import type { Activity, ActivityFlowConsents } from "@lib/activity";
import type { DoubleOptinPage, GenericLandingPage } from "@lib/config/types";
import {
  applyStageBranding,
  resolvePage,
  type LandingPageChoice,
} from "@lib/landing";
import { type Ticket } from "@lib/ticket";

type LandingProps = {
  activity: Activity;
  config: GenericLandingPage | DoubleOptinPage;
  page: LandingPageChoice;
  ticket?: Ticket;
  flowConsents?: ActivityFlowConsents;
};

export function Landing(props: LandingProps) {
  const blocks = resolvePage(props.config, props.page);
  return (
    <div
      className="min-h-screen grid justify-items-center items-start content-start"
      style={applyStageBranding(props.config.branding)}
    >
      <Blocks blocks={blocks} flowConsents={props.flowConsents} {...props} />
      {"tracking_codes" in props.config.settings && (
        <Tracking
          tracking={props.config.settings.tracking_codes}
          activity={props.activity}
        />
      )}
    </div>
  );
}
