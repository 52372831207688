import { FormCheckbox } from "@/components/blocks/form/checkbox";
import { FormInput } from "@/components/blocks/form/input";
import { FormRadio } from "@/components/blocks/form/radio";
import { FormSelect } from "@/components/blocks/form/select";
import { FormTextarea } from "@/components/blocks/form/textarea";
import { StarRating } from "@/components/blocks/questions/star-rating";
import { Area } from "@/components/forms/uae/area";
import { District } from "@/components/forms/uae/district";
import type { Activity } from "@lib/activity";
import type {
  CheckableInputField,
  FormField,
  FormInputField,
  FormStarRatingField,
  FormTextareaField,
  OtherInputField,
} from "@lib/config/form";

type FormFieldProps = FormField & {
  activity?: Activity;
};

export function FormField(props: FormFieldProps) {
  return fieldFactory(props);
}

function fieldFactory(field: FormFieldProps) {
  const { activity, fieldType, ...fieldProps } = field;
  const lang = activity?.locale.split("_")[0];

  if (fieldProps.name === "district" && activity?.territory === "ARE") {
    return (
      <District
        language={lang as string}
        {...(fieldProps as OtherInputField)}
      />
    );
  }

  if (fieldProps.name === "town" && activity?.territory === "ARE") {
    return (
      <Area language={lang as string} {...(fieldProps as OtherInputField)} />
    );
  }

  switch (fieldType) {
    case "input":
      return <FormInput {...(fieldProps as FormInputField)} />;
    case "select":
      return <FormSelect {...(fieldProps as OtherInputField)} />;
    case "textarea":
      return <FormTextarea {...(fieldProps as FormTextareaField)} />;
    case "radio":
      return <FormRadio {...(fieldProps as CheckableInputField)} />;
    case "checkbox":
      return <FormCheckbox {...(fieldProps as CheckableInputField)} />;
    case "star_rating":
      return <StarRating {...(fieldProps as FormStarRatingField)} />;
  }
}
