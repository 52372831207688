import { Blocks } from "@/components/blocks/blocks";
import { Error } from "@/components/pages/error";
import type { Activity, ActivityFlowConsents } from "@lib/activity";
import type {
  DoubleOptinPage,
  GenericLandingPage,
  GenericReview,
  ReviewPageType,
} from "@lib/config/types";
import {
  type LandingPageChoice,
  applyStageBranding,
  resolvePage,
} from "@lib/landing";
import { usePreviewListeners } from "@lib/listeners";
import { usePreviewPageStore } from "@lib/preview";
import { FormProvider, useForm } from "react-hook-form";
import { resolveReviewPage } from "@lib/review";

type LandingProps = {
  activity: Activity;
  config: GenericLandingPage | DoubleOptinPage | GenericReview;
  encodedActivityId: string;
  type: string;
  flowConsents: ActivityFlowConsents;
};

function reviewPageList(config: GenericReview) {
  return config.pages.map((page, index) => {
    const [pageType] = Object.keys(page);
    return {
      label: `${pageType.replaceAll("_", " ") + (pageType.includes("per_product_page") ? ` ${index + 1}` : "")}`,
      value: pageType as ReviewPageType,
    };
  });
}

export function ShareablePreview(props: LandingProps) {
  const methods = useForm();
  const onSubmit = (_data: unknown) => null;
  const page = usePreviewPageStore((state) => state.page);

  const pageList =
    props.type === "review"
      ? reviewPageList(props.config as GenericReview)
      : props.type === "double_optin"
        ? optinPageList
        : claimPageList;

  usePreviewListeners({
    pageList,
    type: props.type,
  });

  if (
    props.type === "claim" &&
    page &&
    typeof page !== "number" &&
    errorPages.includes(page)
  ) {
    return <Error {...props} page={page as LandingPageChoice} />;
  }

  const blocks =
    props.type === "review"
      ? resolveReviewPage(
          props.config as GenericReview,
          (page as string) || "0",
        )
      : resolvePage(
          props.config as GenericLandingPage,
          (page as LandingPageChoice) || "landing",
        );

  return (
    <main
      className="min-h-screen"
      style={applyStageBranding(props.config.branding)}
    >
      <FormProvider {...methods}>
        <form
          className="min-h-screen grid justify-items-center items-start content-start"
          onSubmit={(e) => void methods.handleSubmit(onSubmit)(e)}
        >
          <Blocks
            blocks={blocks}
            {...props}
            {...(props.type === "review" && { flowType: "review" })}
          />
        </form>
      </FormProvider>
    </main>
  );
}

const claimPageList = [
  {
    label: "Landing page",
    value: "landing",
  },
  {
    label: "Completion page",
    value: "completion",
  },
  {
    label: "Out of Stock error page",
    value: "out_of_stock",
  },
  {
    label: "Claimed error page",
    value: "claimed",
  },
  {
    label: "Closed error page",
    value: "closed",
  },
] satisfies {
  label: string;
  value: LandingPageChoice;
}[];

const errorPages = ["out_of_stock", "claimed", "closed"];

const optinPageList = [
  {
    label: "Landing page",
    value: "landing",
  },
  {
    label: "Completion page",
    value: "completion",
  },
] satisfies {
  label: string;
  value: LandingPageChoice;
}[];
