import type { ConfigEnv, ReviewPageType } from "./config/eval";
import { type LandingPageChoice } from "./landing";
import { create } from "zustand";

const today = new Date();

const day = today.getDate();
const month = today.toLocaleString("default", { month: "long" });
const year = today.getFullYear();

export const previewEnv: ConfigEnv = {
  first_name: "Jane",
  last_name: "Doe",
  full_name: "Jane Doe",
  product_name: "Product",
  product_ids: [],
  brand_name: "Cara Beauty",
  locale: "en_GB",
  unsubscribe_url: "/",
  review_url: "/",
  order_id: "SO1689NZKVGMQ",
  delayed_review_url: "/",
  date: `${year}-${month}-${day}`,
  u0: today.getTime(),
};

type HighlightState = {
  highlightedIndex: number | null;
  setHighlightedIndex: (index: number | null) => void;
};

export const useHighlightStore = create<HighlightState>()((set) => ({
  highlightedIndex: null,
  setHighlightedIndex: (index: number | null) =>
    set({ highlightedIndex: index }),
}));

type PreviewPageState = {
  page: LandingPageChoice | ReviewPageType | number | null;
  setPage: (page: LandingPageChoice | ReviewPageType | number) => void;
};

export const usePreviewPageStore = create<PreviewPageState>()((set) => ({
  page: null,
  setPage: (page) => set({ page }),
}));
