// alt text is spread into the img element so we can ignore this rule
"use client";

import type {
  DoubleOptinPage,
  GenericLandingPage,
  GenericReview,
  LogoBlock,
} from "@lib/config/types";
import { justify } from "@lib/utils";

type LogoProps = LogoBlock & {
  config: GenericLandingPage | DoubleOptinPage | GenericReview;
};

export function Logo(props: LogoProps) {
  const resolvedLogo = resolveLogo(props);
  if (!resolvedLogo) return null;

  const { width, ...logo } = resolvedLogo;

  return (
    <div
      data-block-type="Image"
      className="relative flex w-full"
      style={{
        justifyContent: justify(props.logo_bar),
      }}
    >
      <img className="w-full" style={{ maxWidth: width }} {...logo} />
    </div>
  );
}

type ImageArgs = {
  src: string;
  alt: string;
  width: number;
};

// This is a bit of a mess where branding has made it necessary for us to resolve
// the value that we actually want. In the future config should do this for us and
// we can just remove this function.
function resolveLogo({
  logo_bar: logoBar,
  config,
}: LogoProps): ImageArgs | null {
  const stageBranding = config.branding;
  if (
    logoBar.logo === "global_logo" ||
    logoBar.logo === "stage_branding_logo"
  ) {
    if (stageBranding?.logo === "campaign_branding") return null;
    if ("stage_branding" in stageBranding.logo) {
      const stageLogo = stageBranding.logo.stage_branding;
      return {
        src: stageLogo.image,
        alt: stageLogo.alt_text,
        width: stageLogo.logo_size,
      };
    }
    return {
      src: stageBranding.logo.logo_image,
      alt: "logo",
      width: stageBranding.logo.logo_size,
    };
  }

  const { custom_logo: logo } = logoBar.logo;

  const alt =
    "alt_text" in logoBar
      ? logoBar.alt_text
      : "alt_text" in logo
        ? logo.alt_text
        : "Brand logo";

  return {
    src: logo.logo_image,
    alt,
    width: logo.logo_size,
  };
}
