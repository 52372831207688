import type { ConfigPreviewMessage } from "../listeners";
import { previewEnv } from "../preview";
import { type GenericLandingPage } from "./types";
import { z } from "zod";
import { ajaxHeadersWithCSRFToken } from "@lib/utils";

export * from "./types";

export const ConfigEnvSchema = z.object({
  locale: z.string(),
  product_ids: z.array(z.string()),
  full_name: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  date: z.string(),
  u0: z.number(),
  brand_name: z.string(),
  product_name: z.string(),
  unsubscribe_url: z.string(),
  review_url: z.string(),
  delayed_review_url: z.string(),
  order_id: z.string(),
});

export type ConfigEnv = z.infer<typeof ConfigEnvSchema>;

export type EvalConfigResponse<T> = {
  id: string;
  type: string;
  revision: number;
  config: T;
};

export async function evalFragment({
  preview_config,
  view_path,
  env,
  type,
}: ConfigPreviewMessage) {
  const configType = type === "generic_review_v2.yaml" ? "/review" : "/landing";

  const res = await fetch(`/api/eval${configType}`, {
    method: "POST",
    headers: ajaxHeadersWithCSRFToken(),
    body: JSON.stringify({
      fragment: JSON.stringify(preview_config),
      env: { ...previewEnv, ...env },
    }),
  });

  if (!res.ok) {
    throw new Error(`Failed to evaluate fragment`);
  }

  const data = (await res.json()) as { config: string };
  const config = JSON.parse(data.config) as GenericLandingPage;

  return { config, viewPath: view_path, env };
}
