import type { Activity } from "./activity.server";
import type {
  ConfigEnv,
  DoubleOptinPage,
  GenericLandingPage,
} from "./config/eval";
import { blockWidth, type BrandingStyles, hsla } from "./utils";

export function buildEnv(activity: Activity): Partial<ConfigEnv> {
  return {
    locale: activity.locale,
    product_ids: activity.productGroups,
    brand_name: activity.campaign.brand,
  };
}

export function applyStageBranding(
  branding: GenericLandingPage["branding"] | DoubleOptinPage["branding"],
): BrandingStyles {
  return {
    "--block-width": blockWidth(branding.blocks.width),
    "--title-font": branding.fonts.title,
    "--body-font": branding.fonts.body,
    "--button-font": branding.fonts.button,
    "--accent-colour": hsla(branding.colours.accent),
    "--block-background": hsla(branding.colours.block_background),
    "--page-background":
      typeof branding.colours.page_background === "string"
        ? branding.colours.page_background
        : "image" in branding.colours.page_background
          ? `url(${branding.colours.page_background.image})`
          : hsla(branding.colours.page_background.colour),
    "--title-colour": hsla(branding.colours.title),
    "--body-colour": hsla(branding.colours.text),
    "--button-text-colour": hsla(branding.colours.button_text),
    "--anchor-colour": hsla(branding.colours.hyperlinks),
    color: "var(--body-colour)",
    background: "var(--page-background)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    fontFamily: "var(--body-font)",
  };
}

export type LandingErrorPage = "out_of_stock" | "closed" | "claimed";
export type LandingPageChoice = "landing" | "completion" | LandingErrorPage;

export function resolvePage(
  config: GenericLandingPage | DoubleOptinPage,
  page: LandingPageChoice,
) {
  if (page === "landing" || page === "completion") return config.pages[page];
  if ("error" in config.pages) return config.pages.error[page];
  throw new Error(`No error page for ${page}`);
}
