import { Blocks } from "@/components/blocks/blocks";
import type { Activity, ActivityFlowConsents } from "@lib/activity";
import type { GenericReview } from "@lib/config/types";
import { applyStageBranding } from "@lib/landing";
import { resolveReviewPage } from "@lib/review";
import { type Ticket } from "@lib/ticket";

type ReviewProps = {
  activity: Activity;
  config: GenericReview;
  page: string;
  ticket?: Ticket;
  flowConsents?: ActivityFlowConsents;
};

export function Review(props: ReviewProps) {
  const blocks = resolveReviewPage(props.config, props.page);

  return (
    <div
      className="min-h-screen grid justify-items-center items-start content-start"
      style={applyStageBranding(props.config.branding)}
    >
      <Blocks blocks={blocks} {...props} flowType="review" />
    </div>
  );
}
