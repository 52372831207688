"use client";

import { Button } from "@/components/ui/button";
import type { CallToActionBlock } from "@lib/config/types";
import {
  boxShadow,
  color,
  font,
  fontStyle,
  hsla,
  justify,
  pillRadius,
} from "@lib/utils";

type CallToActionProps = CallToActionBlock;

export function CallToAction({ call_to_action: cta }: CallToActionProps) {
  return (
    <div
      data-block-type="Call to action"
      className="flex w-full"
      style={{
        justifyContent: justify(cta),
      }}
    >
      <Button
        asChild
        style={{
          width: cta.shape.width === "full" ? "100%" : "auto",
          height: cta.shape.height,
          color: color(cta.typography.colour),
          backgroundColor: color(cta.background),
          borderRadius: pillRadius(cta.shape.corners),
          borderWidth: cta.shape.border.width,
          borderColor: hsla(cta.shape.border.colour),
          fontFamily: font(cta.typography.font),
          fontSize: cta.typography.size,
          fontStyle: fontStyle(cta.typography.font_properties),
          fontWeight: cta.typography.font_weight === "Bold" ? "bold" : "normal",
          letterSpacing: cta.typography.letter_spacing,
          boxShadow: boxShadow(cta.shadow),
        }}
      >
        <a href={cta.link.url} className="block">
          {cta.link.text}
        </a>
      </Button>
    </div>
  );
}
