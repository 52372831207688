import { ImagePlaceholder } from "@/components/preview/image-placeholder";
import type { ImageBlock } from "@lib/config/types";
import { borderRadius, justify } from "@lib/utils";
import { type CSSProperties } from "react";

type ImageProps = ImageBlock;

export function Image(props: ImageProps) {
  const { image } = props;
  if (!image.image) return <ImagePlaceholder />;

  const { hyperlink } = image;

  return (
    <div
      data-block-type="Image"
      className="flex w-full"
      style={{
        justifyContent: justify(image),
      }}
    >
      {hyperlink === "hyperlink_off" ? (
        <ImageInner {...props} />
      ) : (
        <a
          href={hyperlink.hyperlink_on.url}
          target={hyperlink.hyperlink_on.new_tab ? "_blank" : "_self"}
          rel="noopener noreferrer"
        >
          <ImageInner {...props} />
        </a>
      )}
    </div>
  );
}

function ImageInner({ image }: ImageProps) {
  const { custom_dimensions } = image.dimensions as {
    custom_dimensions: { width: number; height: number };
  };

  const width: CSSProperties =
    image.dimensions === "max_width"
      ? { maxWidth: "100%" }
      : { width: `${custom_dimensions?.width}px` };

  return (
    <img
      src={image.image}
      alt={image.alt_text}
      style={{
        objectFit: image.image_fit,
        mixBlendMode: image.blend_mode,
        opacity: image.opacity,
        borderRadius: borderRadius(image.corner_radius),
        height:
          image.dimensions === "max_width"
            ? "auto"
            : `${custom_dimensions?.height}px`,
        ...width,
      }}
    />
  );
}
