import { FormField, FormMessage } from "@/components/ui/form";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import type { CheckableInputField } from "@lib/config/form";
import type { MultipleChoiceOtherOption } from "@lib/config/types";
import { useFormContext } from "react-hook-form";
import { CheckableOther } from "./other";

type FormRadioProps = CheckableInputField;

function otherField(
  name: string,
  type: string,
  option: MultipleChoiceOtherOption | null,
  groupValue: string | null,
  setGroupValue: (value: string | null) => void,
) {
  if (!option) return;
  return (
    <CheckableOther
      name={name}
      type={type}
      option={option}
      groupValue={groupValue}
      setGroupValue={setGroupValue}
    />
  );
}

export function FormRadio(props: FormRadioProps) {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={props.name}
      render={({ field }) => (
        <fieldset className="mt-[1.125em]">
          <legend
            className="mb-[1.125em] leading-[1.33]"
            aria-describedby={`prompt-${field.name}`}
            style={{
              fontSize: "var(--title-font-size, var(--title-font-size))",
              fontFamily: "var(--title-font-name, var(--font-family))",
              fontStyle: "var(--title-font-style, var(--font-style))",
              fontWeight: "var(--title-font-weight, var(--font-weight))",
              color: "var(--title-color)",
            }}
            dangerouslySetInnerHTML={{
              __html: props.legend ?? "",
            }}
          ></legend>
          <div
            id={`prompt-${field.name}`}
            className="block [&:not(:empty)]:mb-[1.5rem]"
            dangerouslySetInnerHTML={{
              __html: props.prompt ?? "",
            }}
            style={{
              fontSize: "var(--prompt-font-size)",
              fontFamily: "var(--prompt-font-name, var(--font-family))",
              fontStyle: "var(--prompt-font-style, var(--font-style))",
              fontWeight: "var(--prompt-font-weight, var(--font-weight))",
              color: "var(--prompt-font-color)",
            }}
          ></div>
          <RadioGroup
            name={field.name}
            className={
              props.layout === "one_column" ? "grid-cols-1" : "grid-cols-2"
            }
            onValueChange={field.onChange}
          >
            {props.options.map((option) => {
              return (
                <div
                  className="grid grid-cols-[auto_1fr] items-center gap-[0.75em] px-[0.75em] py-[0.5em]"
                  key={`radio-${props.name}-${option.value}`}
                  style={{
                    color: "var(--input-color)",
                    backgroundColor: "var(--input-background)",
                    borderTopLeftRadius: "var(--field-border-top-left-radius)",
                    borderTopRightRadius:
                      "var(--field-border-top-right-radius)",
                    borderBottomRightRadius:
                      "var(--field-border-bottom-right-radius)",
                    borderBottomLeftRadius:
                      "var(--field-border-bottom-left-radius)",
                    borderColor: "var(--input-border-color)",
                    borderWidth: "var(--field-border-size)",
                    boxShadow:
                      field.value === option.value
                        ? "0 0 0 2px var(--input-border-color, var(--black))"
                        : "",
                  }}
                >
                  <RadioGroupItem
                    {...option}
                    id={`radio-${field.name}-${option.id}`}
                    aria-labelledby={`label-${field.name}-${option.id}`}
                  />
                  <Label
                    id={`label-${field.name}-${option.id}`}
                    className="grid cursor-pointer items-center"
                    htmlFor={`radio-${field.name}-${option.id}`}
                    style={{
                      fontFamily: "var(--label-font-name, var(--font-family))",
                      fontStyle: "var(--label-font-style, var(--font-style))",
                      fontWeight:
                        "var(--label-font-weight, var(--font-weight))",
                      color: "var(--label-color)",
                      minBlockSize: "calc(var(--input-block-size) - 1em)",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: option.label ?? "",
                    }}
                  ></Label>
                </div>
              );
            })}
            {otherField(
              props.name,
              props.type,
              props.other,
              field.value as string | null,
              field.onChange,
            )}
          </RadioGroup>
          <FormMessage />
        </fieldset>
      )}
    />
  );
}
