import type { PointerBlockV2 } from "@lib/config/types";
import {
  alignStyle,
  type FontFamily,
  justifyColumnStyle,
  resolveFontFamilyVariables,
  textBlockWidth,
} from "@lib/utils";
import { type CSSProperties } from "react";

type PointerProps = PointerBlockV2;

export const Pointer = ({ pointer }: PointerProps) => {
  const fontFamily = pointer.font as FontFamily;
  return (
    <div
      data-block-type="Pointer"
      style={{
        ...alignStyle(pointer),
        maxWidth: textBlockWidth(pointer.width),
        ...resolveFontFamilyVariables(
          "--pointer",
          fontFamily,
          pointer.font_properties,
        ),
      }}
    >
      <div
        className="flex flex-col gap-4"
        style={{ ...justifyColumnStyle(pointer) }}
      >
        <div
          className="font-[family-name:--pointer-font-name] font-[--pointer-font-weight]"
          style={{
            color: pointer.color,
            fontSize: pointer.font_size,
            letterSpacing: pointer.letter_spacing,
            textAlign: pointer.alignment,
          }}
          dangerouslySetInnerHTML={{
            __html: pointer.text ?? "",
          }}
        />
        <Chevron
          className="w-6"
          style={{
            fill: pointer.pointer_color,
          }}
        />
      </div>
    </div>
  );
};

function Chevron(props: { className?: string; style: CSSProperties }) {
  return (
    <svg
      width="34"
      height="14"
      viewBox="0 0 34 14"
      role="img"
      aria-label="Down arrow icon"
      {...props}
    >
      <path
        d="M529,1079 L529,1082.41197 L512.077,1092.93 L512.033584,1093 L511.999,1092.979 L511.966416,1093 L511.924,1092.932 L495,1082.41197 L495,1079 L512,1089.492 L529,1079 Z"
        transform="translate(-495 -1079)"
      />
    </svg>
  );
}
