import type { Activity } from "@lib/activity";
import type { Settings } from "@lib/config/types";
import { getCode, getPixelType } from "@lib/pixels";
import { GTM } from "./gtm";
import { Snap } from "./snap";
import { Meta } from "./meta";
import { TikTok } from "./tiktok";
import { useCookies } from "react-cookie";
import { CookieNotice } from "./cookie-notice";

type Tracking = Settings["tracking_codes"];

type TrackingProps = {
  tracking: Tracking;
  activity: Activity;
};

export function Tracking({ tracking, activity }: TrackingProps) {
  const pixelType = getPixelType(tracking);
  const code = getCode(tracking, pixelType);
  const [cookies] = useCookies([`sopost-purpose_${activity.id}`]);

  if (pixelType === "none") return null;

  const cookie = cookies[`sopost-purpose_${activity.id}`] as string;

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];`,
        }}
      />

      {pixelType === "google_tag_manager" && code?.pixel_id ? (
        <GTM activity={activity} code={code?.pixel_id} />
      ) : null}

      {pixelType === "snap_pixel" && code?.pixel_id ? (
        <Snap activity={activity} code={code?.pixel_id} />
      ) : null}

      {pixelType === "meta_pixel" && code?.pixel_id ? (
        <Meta activity={activity} code={code?.pixel_id} />
      ) : null}

      {pixelType === "tiktok_pixel" && code?.pixel_id ? (
        <TikTok activity={activity} code={code?.pixel_id} />
      ) : null}

      {!cookie && <CookieNotice activityId={activity.id} />}
    </>
  );
}
