import type { Activity } from "@lib/activity";
import { useCookies } from "react-cookie";

type GTMProps = {
  activity: Activity;
  code: string;
};

export function GTM({ activity, code }: GTMProps) {
  const [cookies] = useCookies([`sopost-purpose_${activity.id}`]);
  const cookie = cookies[`sopost-purpose_${activity.id}`] as
    | { value: string }
    | undefined;

  if (!cookie || cookie.value === "rejected") return null;

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];

            dataLayer.push({
                'activityCode': '${activity.code}',
                'activityName': '${activity.name}',
                'cookieConsentAnalytical': ${cookie?.value?.includes("analytical") || false},
                'cookieConsentMarketing': ${cookie?.value?.includes("marketing") || false},
            });
        `,
        }}
      />
      <script
        type="text/partytown"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${code}');
        `,
        }}
      />
    </>
  );
}
