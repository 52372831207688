import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useMutation } from "@tanstack/react-query";
import { api } from "@lib/client";
import { logger } from "@lib/logger";
import { useTranslations } from "@/components/context/translations";
import { ajaxHeadersWithCSRFToken } from "@lib/utils";

type UnsubscribeProps = {
  shortOrderId: string;
};

export const UnsubscribePage = ({ shortOrderId }: UnsubscribeProps) => {
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const [loading, setLoading] = useState(false);
  const t = useTranslations();

  const { mutateAsync } = useMutation({
    mutationFn: async (shortOrderId: string) => {
      try {
        const unsubscribeResponse = await api.unsubscribe.$post(
          {
            json: {
              formData: shortOrderId,
            },
          },
          {
            headers: ajaxHeadersWithCSRFToken(),
          },
        );

        const unsubscribeResult = await unsubscribeResponse.json();

        if (!unsubscribeResult.success) {
          throw new Error(unsubscribeResult.error || "Error unsubscribing");
        }

        return unsubscribeResult;
      } catch (error) {
        throw error; // Let the mutation handle any errors
      }
    },
    onError: (error) => {
      logger.error(error, "Error handling the mutation");
      setLoading(false); // Reset loading on error
    },
    onSuccess: (data) => {
      if (data.success) {
        setIsUnsubscribed(true); // Set unsubscribe status on success
      }
      setLoading(false); // Reset loading after success
    },
  });

  async function onSubmit() {
    try {
      setLoading(true); // Set loading when mutation starts
      await mutateAsync(shortOrderId);
    } catch (error) {
      logger.error(error, "Error handling unsubscribe");
      setLoading(false); // Reset loading on error
    }
  }

  return (
    <div className="text-center shadow-lg shadow-gray-300 border border-gray-100 rounded-lg w-[363px] mx-auto my-20">
      {isUnsubscribed ? (
        <div className="flex flex-col items-center justify-between p-6">
          <h1 className="text-2xl font-semibold pb-4 leading-[33.6px]">
            {t("Unsubscribed")}
          </h1>
          <p className="pb-4 text-base font-normal">
            {t(
              "You have successfully been unsubscribed from emails relating to this campaign.",
            )}
          </p>
          <svg
            width="54"
            height="54"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
              fill="#219C60"
            ></path>
          </svg>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-between p-6">
          <h1 className="text-2xl font-semibold pb-4 leading-[33.6px]">
            {t("Unsubscribe")}
          </h1>
          <p className="pb-4 text-base font-normal">
            {t(
              "Click the button below to unsubscribe from emails relating to this campaign.",
            )}
          </p>
          <Button
            className="bg-gray-100 text-black shadow-lg shadow-gray-300 border border-gray-300 w-[314px] h-[42px] text-base font-normal transition-all duration-300 hover:border-2 hover:bg-gray-100"
            onClick={() => {
              void onSubmit();
            }} // Use onSubmit function directly
            disabled={loading} // Disable button while loading
          >
            {loading ? t("Unsubscribing...") : t("Unsubscribe")}{" "}
            {/* Change button text based on loading */}
          </Button>
        </div>
      )}
    </div>
  );
};
