import { DoubleOptinForm } from "@/components/forms/double-optin";
import { Landing } from "@/components/pages/landing";
import type { Activity } from "@lib/activity";
import { type DoubleOptinPage } from "@lib/config/types";
import type { Config } from "@lib/config/utils";
import type { Flow } from "@lib/path.server";

export default function DoubleOptinPage({
  activity,
  encodedActivityId,
  config,
  flow,
}: {
  activity: Activity;
  config: Config;
  encodedActivityId: string;
  flow: Extract<Flow, { type: "double_optin" }>;
}) {
  return (
    <DoubleOptinForm
      encodedActivityId={encodedActivityId}
      consentId={flow.consentId!}
    >
      <Landing
        activity={activity}
        config={config as DoubleOptinPage}
        page="landing"
      />
    </DoubleOptinForm>
  );
}
