"use client";

import { Button } from "@/components/ui/button";
import type { SubmitButtonBlock } from "@lib/config/types";
import {
  boxShadow,
  color,
  font,
  fontStyle,
  fontWeight,
  hsla,
  justify,
  pillRadius,
  textTransform,
} from "@lib/utils";
// import { useFormStatus } from "react-dom";

type SubmitButtonProps = SubmitButtonBlock;

export function SubmitButton({ submit_button: submit }: SubmitButtonProps) {
  // const { pending } = useFormStatus();
  const pending = false;

  return (
    <div
      data-block-type="Submit Button"
      className="flex w-full"
      style={{
        justifyContent: justify(submit),
      }}
    >
      <Button
        type="submit"
        aria-disabled={pending}
        disabled={pending}
        className="px-8"
        style={{
          width: submit.shape.width === "full" ? "100%" : "auto",
          height: submit.shape.height,
          color: color(submit.typography.colour),
          backgroundColor: color(submit.background),
          borderRadius: pillRadius(submit.shape.corners),
          borderWidth: submit.shape.border.width,
          borderColor: hsla(submit.shape.border.colour),
          fontFamily: font(submit.typography.font),
          fontSize: submit.typography.size,
          fontStyle: fontStyle(submit.typography.font_properties),
          fontWeight: fontWeight(submit.typography.font_properties),
          letterSpacing: submit.typography.letter_spacing,
          textTransform: textTransform(submit.typography.casing),
          boxShadow: boxShadow(submit.shadow),
        }}
        dangerouslySetInnerHTML={{
          __html: submit.text ?? "",
        }}
      />
    </div>
  );
}
