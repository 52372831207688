import { type CSSProperties } from "react";
import type { CustomQuestionsBlock } from "@lib/config/types";
import { extractBlocks } from "@lib/config/types";
import {
  color,
  font,
  fontStyle,
  fontWeight,
  justify,
  textBlockWidth,
  hsla,
  fieldBorderRadius,
} from "@lib/utils";
import { Question } from "@/components/blocks/questions/question";
import { type Ticket } from "@lib/ticket";
import { filterAlreadyAnsweredQuestions } from "@lib/custom-questions";
import type { FlowType } from "../blocks/blocks";

type CustomQuestionsProps = CustomQuestionsBlock & {
  ticket?: Ticket;
  flowType?: FlowType;
};

type CustomQuestionStyles = CSSProperties & {
  "--input-color": string;
  "--input-background": string;
  "--input-border-color": string;
  "--label-color": string;
  "--placeholder-color": string;
  "--title-font-size": string;
  "--title-font-color": string;
  "--title-font-weight": string;
  "--prompt-font-color": string;
  "--prompt-font-size": string;
  "--field-border-size": string;
  "--field-border-top-left-radius": string;
  "--field-border-top-right-radius": string;
  "--field-border-bottom-right-radius": string;
  "--field-border-bottom-left-radius": string;
};

export function CustomQuestions({
  custom_questions,
  ticket,
  flowType,
}: CustomQuestionsProps) {
  const questions = filterAlreadyAnsweredQuestions(
    extractBlocks(custom_questions.questions),
    ticket?.custom_questions,
  );

  return (
    <div
      data-block-type="Custom Questions"
      className="mt-[1.125em] flex flex-col gap-8"
      style={
        {
          justifyContent: justify(custom_questions),
          width: textBlockWidth(custom_questions.width),
          "--font-family": font(custom_questions.typography.font),
          "--font-style": fontStyle(
            custom_questions.typography.font_properties,
          ),
          "--font-weight": fontWeight(
            custom_questions.typography.font_properties,
          ),
          "--title-font-color": color(custom_questions.title.title_colour),
          "--title-font-size": `${custom_questions.title.title_font_size}px`,
          "--title-font-weight": custom_questions.title.title_font_weight,
          "--prompt-font-color": color(custom_questions.prompt.colour),
          "--prompt-font-size": `${custom_questions.prompt.font_size}px`,
          "--input-color": color(custom_questions.colours.input_font_colour),
          "--input-background": hsla(
            custom_questions.colours.input_background_colour,
          ),
          "--input-border-color": color(
            custom_questions.colours.input_border_colour,
          ),
          "--placeholder-color": color(
            custom_questions.colours.placeholder_colour,
          ),
          "--label-color": color(custom_questions.colours.label_colour),

          "--field-border-size": `${custom_questions.field_border_size}px`,
          "--field-border-top-left-radius": fieldBorderRadius(
            custom_questions.field_corner_radius,
            "tl",
          ),
          "--field-border-top-right-radius": fieldBorderRadius(
            custom_questions.field_corner_radius,
            "tr",
          ),
          "--field-border-bottom-right-radius": fieldBorderRadius(
            custom_questions.field_corner_radius,
            "br",
          ),
          "--field-border-bottom-left-radius": fieldBorderRadius(
            custom_questions.field_corner_radius,
            "bl",
          ),
        } as CustomQuestionStyles
      }
    >
      {questions.map((question) => (
        <Question key={question.type} flowType={flowType} {...question} />
      ))}
    </div>
  );
}
