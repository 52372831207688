import type { LogoBlockV2 } from "@lib/config/types";
import { alignStyle } from "@lib/utils";
import { type CSSProperties } from "react";

type LogoProps = LogoBlockV2;

export function Logo(props: LogoProps) {
  const { logo_bar } = props;

  type LogoStyles = CSSProperties & {
    "--w": string;
    "--h": string;
  };

  return (
    <div
      data-block-type="Image"
      className="relative"
      style={
        {
          ...alignStyle(logo_bar),
          "--w": `${logo_bar.logo.width}px`,
          "--h": `${logo_bar.logo.height}px`,
          maxWidth: "var(--w)",
        } as LogoStyles
      }
    >
      <img
        src={logo_bar.logo.image}
        width={logo_bar.logo.natural_width}
        height={logo_bar.logo.natural_height}
        className={`aspect-[var(--w)_/_var(--h)] h-auto w-full max-w-[var(--w)]`}
        alt={logo_bar.logo.alt_text}
      />
    </div>
  );
}
