import type { Settings, UnionKey } from "./config/types";

type Tracking = Settings["tracking_codes"];
type PixelType = UnionKey<Exclude<Tracking["code"], "none">>;
export type Purpose =
  | "analytical"
  | "marketing"
  | "analytical_and_marketing"
  | "rejected";

export function getPixelType(tracking: Tracking): PixelType | "none" {
  if (tracking.code === "none") return "none";

  return Object.keys(tracking.code)[0] as PixelType;
}

export function getCode(tracking: Tracking, trackingType: PixelType | "none") {
  if (tracking.code === "none") return null;

  switch (trackingType) {
    case "google_tag_manager":
      return "google_tag_manager" in tracking.code
        ? tracking.code.google_tag_manager
        : null;
    case "meta_pixel":
      return "meta_pixel" in tracking.code ? tracking.code.meta_pixel : null;
    case "snap_pixel":
      return "snap_pixel" in tracking.code ? tracking.code.snap_pixel : null;
    case "tiktok_pixel":
      return "tiktok_pixel" in tracking.code
        ? tracking.code.tiktok_pixel
        : null;
    default:
      return null;
  }
}
